import axios from "axios"
import site from "../conf/site"

const WEBSITE_NAME = site.title //'Buna ziua, Bistrița'

let BASE =
    typeof window === "undefined"
        ? `${site.protocol}://${site.host}`
        : window.location.protocol + "//" + window.location.hostname
//if (window.location.port === "3000")
//    BASE = `${window.location.protocol}//${window.location.hostname}:3001`
//console.log(BASE, window.location)
/*if (typeof window === "undefined") {
    if (process.env.NODE_ENV === "development") BASE = "http://localhost:3001"
    else BASE = `${site.protocol}://${site.host}`
} else {
    BASE = window.location.protocol + "//" + window.location.host
    }*/
//if (process.env.NODE_ENV === "development") BASE = `http://localhost:${site.node_port}`
let ORIGIN = BASE

if (
    BASE === "http://localhost" ||
    BASE.startsWith("http://192.") ||
    BASE.startsWith("http://89.") ||
    BASE.startsWith("http://10.")
) {
    ORIGIN = BASE + (window.location.port ? ":" + window.location.port : "")
    BASE = `${site.protocol}://${site.host}`
}
//console.log(BASE, ORIGIN)
const API = "/api"
const auth0 = site.auth0
axios.defaults.baseURL = `${BASE}${API}/`
axios.defaults.timeout = 30000
axios.defaults.maxcontentLength = 52428890
//console.log("axios base:", `${BASE}${API}/`)
/*
const axiosInstance = axios.create({
    baseURL: `${BASE}/${API}/`,
    timeout: 150000,
    maxContentLength: 52428890,
})*/
const captcha = site.captcha
const GA_TRACKING_ID = site.ga_tracking_id

const palette = site.palette
const styles = site.styles
const LOCALDB = site.host

const menu = site.menu
const LANGUAGES = site.languages
const defaultImageStyle = "img"
export default {
    WEBSITE_NAME,
    ORIGIN,
    BASE,
    API,
    //axios: axiosInstance,
    auth0,
    GA_TRACKING_ID,
    LOCALDB,
    LANGUAGES,
    palette,
    captcha,
    styles,
    menu,
    defaultImageStyle,
    //monthNames
}
