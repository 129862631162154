import React from "react"
import { useCarouselState } from "./Carousel"

const Navigator = () => {
    const carousel = useCarouselState()

    return (
        <div navigator="">
            {Array(carousel.state.n).map((k, i) => (
                <div
                    key={i}
                    active={i === carousel.state.active ? "" : undefined}
                    onClick={carousel.go}
                    data-index={i}
                />
            ))}
        </div>
    )
}
export default Navigator
