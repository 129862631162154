import { get, set } from "./accessors"
import { getTypeInfo, resetInfo } from "./type_info"

const _nextFieldName = parent => {
    let i = 0
    if (!parent._e) return "f0"
    while (parent._e[`f${i}`]) i++
    return `f${i}`
}
const _getOrderAfterPush = (parent, after, field, parentIsEntity) => {
    if (!parent._o) return undefined

    if (parentIsEntity) {
        if (typeof parent._o === "string") return `${parent._o},${field}`
        if (after) {
            const o = (parent._o["content"] ?? "").split(",")
            const index = o.indexOf(after)
            if (index >= 0)
                return {
                    ...parent._o,
                    content: [o.slice(0, index), field, o.slice(index)].join(","),
                }
        }
        return {
            ...parent._o,
            content: `${parent._o["content"]},${field}`,
        }
    }
    if (after) {
        const o = parent._o.split(",")
        const index = o.indexOf(after)
        if (index >= 0) return [o.slice(0, index), field, o.slice(index)].join(",")
    }
    return `${parent._o},${field}`
}
const _pushField = (parent, after, type, parentIsEntity) => {
    const name = _nextFieldName(parent)

    return [
        {
            ...parent,
            _e: {
                ...(parent._e ?? {}),
                [name]: { name, type },
            },
            _o: _getOrderAfterPush(parent, after, name, parentIsEntity),
        },
        name,
    ]
}
const _entityAddGetParent = (entity, field) => {
    if (field == null || field === undefined) return [entity, null, null, true]

    const fieldInfo = getTypeInfo(field, entity)
    //console.log(fieldInfo)
    if (fieldInfo.isBlock || fieldInfo.extendable) {
        let o = get(entity, field)
        if (!o) {
            if (fieldInfo.type === "list") o = []
            else o = {}
        }
        return [o, field, null, false]
    } else {
        const path = field.split(".")
        if (path.length > 1) {
            const fieldName = path.pop()
            const parentField = path.join(".")
            return [get(entity, parentField), parentField, fieldName, false]
        } else return [entity, null, field, true]
    }
}

// :: Entity -> String -> String -> [Entity, String]
export const addField = (entity, existentField, fieldType) => {
    //console.log(entity, existentField, fieldType)
    const [o, parentField, after, parentIsEntity] = _entityAddGetParent(entity, existentField)
    //console.log(o, parentField, after, parentIsEntity)
    const [parent, fieldName] = _pushField(o, after, fieldType, parentIsEntity)
    //console.log(parent, fieldName)
    //if (parentIsEntity && !parentInfo.fieldDefs) parentInfo.fieldDefs = []
    //if (!parentInfo.fieldDefs) parentInfo.fieldDefs = []
    //parentInfo.fieldDefs.push({ name: fieldName, type: fieldType })
    const newEntity = resetInfo(parentIsEntity ? parent : set(entity, parentField, parent))
    //console.log(newEntity)
    return [newEntity, parentField ? `${parentField}.${fieldName}` : fieldName]
}
