import React, { useMemo, useRef } from "react"
import NProgress from "nprogress"
import { C, useAuthContext, useQuery, useWS, useSubscriptionProvider } from "lib"
import useCustomNode from "./useCustomNode"
import Layout from "components/layout/Layout"
import Admin from "../Admin"
import Shell from "../Shell"
import SEO from "./SEO"
import NodeScrollManager from "./NodeScrollManager"

const nodeNotFound = {
    _id: {},
    _custom: true,
    type: "error",
    title: "Pagină inexistentă",
    body: "Pagina nu a fost găsită.",
}

const pathField = C.LANGUAGES ? "path.p" : "path"
const buildNodeQuery = pathname => {
    const p = pathname === "/index.html" ? "/" : pathname.replace(/\.html$/, "")
    return {
        query: {
            $or: [{ [pathField]: p }, { alias: p }],
        },
    }
}
const queryConfig = { single: true }

const useLocation = (location, status) => {
    const { pathname, sanitized, search, language } = location
    const loc = useRef({ sanitized, pathname, search, language, first: true })
    const prevStatus = useRef(status)

    if (loc.current.sanitized === sanitized) loc.current.search = search
    else {
        if (status !== "loading") {
            loc.current = { pathname, sanitized, search, language, first: false }
        }
    }
    prevStatus.current = status
    return loc.current
}
let cleaner
const useStoreCleaner = (status, location) => {
    const state = React.useRef({ queue: [] })
    React.useEffect(() => {
        const cleanStore = async () => {
            if (!cleaner) cleaner = await import("lib/query/cleanStore")
            cleaner.cleanStore(older, state.current.queue)
        }
        if (status !== "loaded") return
        //if (state.current.currentLocation === location) return
        //state.current.currentLocation = location
        if (state.current.queue.filter(loc => loc === location).length > 0) return
        state.current.queue.push(location)
        if (state.current.queue.length < 3) return
        const older = state.current.queue[0]
        state.current.queue = state.current.queue.slice(1)
        if (older === location) return
        cleanStore()
    }, [location, status])
}

const useNodeRetriever = pathname => {
    const state = useRef({})
    //const custom = useRef()
    const customNode = useCustomNode(pathname)
    useMemo(() => {
        if (
            C.LANGUAGES &&
            state.current.node &&
            state.current.node.path.filter(path => path.p === pathname).length > 0
        )
            return

        //custom.current = useCustomNode(pathname)
        if (customNode) {
            state.current.query = null
        } else {
            state.current.query = buildNodeQuery(pathname)
        }
    }, [pathname, customNode])
    const [resultNode, status] = useQuery(state.current.query, queryConfig)
    //console.log(resultNode, status)
    /*
    const lastProps = React.useRef({})
    let changed = []
    if (lastProps.current["status"] !== status) {
        lastProps.current["status"] = status
        changed.push("status")
    }
    if (lastProps.current["queryConfig"] !== queryConfig) {
        lastProps.current["queryConfig"] = queryConfig
        changed.push("queryConfig")
    }
    if (lastProps.current["query"] !== state.current.query) {
        lastProps.current["query"] = state.current.query
        changed.push("query")
    }
    if (lastProps.current["resultNode"] !== resultNode) {
        lastProps.current["resultNode"] = resultNode
        changed.push("resultNode")
    }
    if (changed.length > 0)
        console.log(changed, status, resultNode, state.current.query, queryConfig)
        */
    //const prevNode = state.current.node
    //const currentNode =
    //console.log(status)
    if (customNode || status === "loaded") {
        state.current.node = customNode || resultNode
        //console.log(state.current.node)
        if (typeof window !== "undefined") {
            requestAnimationFrame(() => {
                NProgress.done()
            })
        }
        return [state.current.node, "loaded"]
    }

    return [state.current.node, status]
    /*if (customNode) {
        state.current.node = customNode
        return [customNode, "loaded"]
    }
    state.current.node = resultNode || customNode
    return [state.current.node, status]*/
}
const NodeManager = ({ location }) => {
    useWS()
    const { user, logout } = useAuthContext()
    const publish = useSubscriptionProvider()

    const [resultNode, status] = useNodeRetriever(location.sanitized)
    const currentLocation = useLocation(location, status)
    const node = status === "loaded" ? resultNode ?? nodeNotFound : resultNode
    useStoreCleaner(status, location.sanitized)
    React.useEffect(() => {
        publish("node", node)
    }, [publish, node])

    /*
    console.log(
        "render",
        location.pathname,
        location.search,
        status,
        node ? node.path : null,
        resultNode
    )*/
    //console.log(user, pathname)
    //console.log(currentLocation)
    if (
        user &&
        user.isAdmin &&
        currentLocation.search["mode"] &&
        currentLocation.search["mode"] === "design"
    ) {
        return (
            <Admin
                user={user}
                logout={logout}
                node={node}
                pathname={currentLocation.pathname}
                design
            />
        )
    }
    if (currentLocation.sanitized === "/shell") {
        return <Shell user={user} logout={logout} node={node} pathname={currentLocation.pathname} />
    }

    return (
        <>
            <SEO node={node} language={currentLocation.language} />
            <Layout
                location={currentLocation.sanitized}
                search={currentLocation.search}
                language={currentLocation.language}
                node={node}
                first={currentLocation.first}
            >
                <NodeScrollManager
                    location={currentLocation.sanitized}
                    search={currentLocation.search}
                    language={currentLocation.language}
                    node={node}
                    first={currentLocation.first}
                />
            </Layout>
            <Admin user={user} logout={logout} node={node} />
        </>
    )
}

export { buildNodeQuery }
export default React.memo(NodeManager)
