import React from "react"
import { FaIcon, LazyImage } from "lib"

const Icon = ({ info, value, ...props }) => {
    const { fieldInfo } = info
    if (!value) return null
    //console.log(info, value, props)
    switch (fieldInfo.type) {
        case "text":
            return <FaIcon icon={value} {...props} />
        case "img":
            return <LazyImage src={value} {...props} />
        default:
            return null
    }
}

export default Icon
