import { useContext, useState, useRef, useEffect } from "react"
import { NodeContext } from "./NodeContext"
/*import { push } from "../../lib/history"

const pushSearch = params => {
    if (!params || Object.keys(params) === 0) push(window.location.pathname)
    else {
        const search = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join("&")
        //console.log("pushSearch", params, `${window.location.pathname}?${search}`)
        push(`${window.location.pathname}?${search}`)
    }
}
*/
const useNode = subscribeTo => {
    const nodeContext = useContext(NodeContext)

    const [state, setState] = useState(() => {
        if (!subscribeTo) {
            const { subscribe, unsubscribe, ...state } = nodeContext
            return Object.values(state)
        }
        const keys = subscribeTo.split(",")
        if (keys.length === 1) return nodeContext[keys[0]]
        return keys.map(key => nodeContext[key])
    })

    const ref = useRef()
    if (!ref.current && subscribeTo) {
        ref.current = nodeContext.subscribe(subscribeTo, state => {
            //console.log(state)
            setState(state)
        })
    }
    useEffect(
        () => () => {
            if (ref.current) nodeContext.unsubscribe(ref.current)
        },
        [nodeContext]
    )
    return state
}
export default useNode
