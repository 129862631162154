import React from "react"
import { FaIcon, Field, useSubscriptionProvider } from "lib"

const labels = {
    ro: "VERSIUNE",
    en: "VERSION",
}
const styleSelector = { "--index": 0 }
const Selector = ({ value, selected, onChange, language }) => {
    const [open, setOpen] = React.useState(false)
    const handleClick = React.useCallback(
        e => {
            onChange(parseInt(e.target.dataset.index, 10))
            setOpen(false)
        },
        [onChange]
    )
    const handleToggle = React.useCallback(() => {
        setOpen(open => !open)
    }, [])
    //console.log(value)
    if (value.length === 0 || !value[0].label) return null
    const one = value.length === 1
    const emptyLabel = one && (value[selected].label ?? "").trim() === ""
    if (emptyLabel) return null
    return (
        <div className="variant" style={styleSelector}>
            <div className="section-label">{labels[language]}</div>
            <div className={`selector ${open ? "open" : ""}`}>
                <div className="current" onClick={handleToggle}>
                    <div>{value[selected].label}</div>
                    {!one && (
                        <div>
                            <FaIcon icon="faCaretDown" />
                        </div>
                    )}
                </div>
                <div className="values">
                    {value.map((item, i) => (
                        <div
                            key={i}
                            className={`item ${selected === i ? "selected" : ""}`}
                            onClick={handleClick}
                            data-index={i}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const Variants = ({ info, value, children, ...props }) => {
    const [index, setIndex] = React.useState(0)
    const publish = useSubscriptionProvider()
    React.useEffect(() => {
        publish("product-variant", index)
    }, [index, publish])
    if (!value) return null

    //console.log(index)
    return (
        <div inner="" {...props}>
            <Selector value={value} selected={index} onChange={setIndex} language={info.language} />
            <Field key={index} info={info} field={index} index={1} />
        </div>
    )
}
export default React.memo(Variants)
//            <Table data={value[index].data} />
