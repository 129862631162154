import classes from "./classes"
import { types, getEntityTypes, getFieldTypes } from "./types"
import { is, getCollection, getTypeInfo, resetInfo, resetFieldInfo, getSettings } from "./type_info"
import { getDisplayInfo, getFieldDisplayInfo } from "./display_info"
import {
    getId,
    get,
    set,
    getLens,
    unset,
    getTypeField,
    getPath,
    getFieldTranslations,
} from "./accessors"
import { create, load, save, remove } from "./entity"
import { addField } from "./addField"
import { addMetaField, removeMetaField } from "./addMetaField"
import { fold } from "./fold"

export default {
    classes,
    types,
    getEntityTypes,
    getFieldTypes,

    is,
    getCollection,
    getTypeInfo,
    resetInfo,
    resetFieldInfo,
    getSettings,

    set,
    unset,
    get,
    getLens,
    getTypeField,

    getId,
    getPath,
    getFieldTranslations,

    create,
    load,
    save,
    remove,

    getDisplayInfo,
    getFieldDisplayInfo,

    addField,
    addMetaField,
    removeMetaField,
    fold,
}
