import { useQuery } from "lib"
const menuQuery = {
    collection: "menu",
    query: {},
}
const config = { single: true }
export const useMenu = () => {
    const [menu] = useQuery(menuQuery, config)
    return menu
}
