import React from "react"
import Entity from "../../../entity"

const FormFieldSubmit = ({ domRef, fieldValue, dispatch, info, value, ...props }) => {
    const { language, entity, fieldInfo } = info
    const defaultLanguage = entity._lang
    const handleSubmit = React.useCallback(
        e => {
            e.preventDefault()
            dispatch({
                type: "submit",
            })
        },
        [dispatch]
    )
    const label = React.useMemo(
        () => Entity.get(value, "label", { language, defaultLanguage, entityInfo: fieldInfo }),
        [value, language, defaultLanguage, fieldInfo]
    )

    return (
        <div ref={domRef} {...props}>
            <input type="button" className="button" onClick={handleSubmit} value={label} />
        </div>
    )
}
export default FormFieldSubmit
