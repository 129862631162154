import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
//import { polyfillLoader } from "polyfill-io-feature-detection"
import { checkFeatures, loadPolyfill, getRoot } from "./init"
import React from "react"
import ReactDOM from "react-dom"
import * as serviceWorker from "registerServiceWorker"
import { LanguageManager, Auth } from "lib" //, Query
import Location from "lib/app/Location"
import { HelmetProvider } from "react-helmet-async"
import { initSW } from "lib/sw/initSW"
Bugsnag.start({
    apiKey: "be93fcf687f9a54b38e689535b4530a8",
    plugins: [new BugsnagPluginReact(React)],

    beforeSend: function (report) {
        const params = new URLSearchParams(window.location.search)
        const fbclidCheck = params.get("fbclid")
        if (
            fbclidCheck &&
            report.errorClass === "TypeError" &&
            report.errorMessage === "Illegal invocation"
        ) {
            report.ignore()
        }
    },
})
const main = () => {
    //console.log("main")
    const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)
    const [root, childrenLength] = getRoot()
    //console.log("children:", childrenLength, module.hot)
    const render = childrenLength === 0 || module.hot ? ReactDOM.render : ReactDOM.hydrate
    initSW()
    render(
        <ErrorBoundary>
            <HelmetProvider>
                <LanguageManager>
                    <Auth>
                        <Location />
                    </Auth>
                </LanguageManager>
            </HelmetProvider>
        </ErrorBoundary>,
        root,
        () => {
            window.isHydrating = false
        }
    )
}
// This function load polyfills only if needed. By default it uses polyfill.io
/*polyfillLoader({
    features: "ResizeObserver,IntersectionObserver",
    onCompleted: main,
})*/
const features = checkFeatures()
if (features.length > 0) {
    loadPolyfill(features, main)
} else main()

serviceWorker.register()
/*{
    onUpdate: async registration => {
        // We want to run this code only if we detect a new service worker is
        // waiting to be activated.
        // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
        //window.location.reload()
        if (registration && registration.waiting) {
            //console.log("update")
            //registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            //await registration.unregister()
            // Once the service worker is unregistered, we can reload the page to let
            // the browser download a fresh copy of our app (invalidating the cache)
            //window.location.reload()
        }
    },
})
*/
