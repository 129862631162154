import React from "react"
import { C, Entity, Link } from "lib"
//import { useMainMenu } from "../layout/useMainMenu"

const distrib = {
    ro: { title: "Produse distribuite", p: "/ro/produse/distribuite" },
    en: { title: "Distributed", p: "/en/products/distributed" },
}
const manufactured = {
    ro: { title: "Produse fabricate", p: "/ro/produse/fabricate" },
    en: { title: "Manufactured", p: "/en/products/manufactured" },
}
const renderProduct = (product, entityInfo, language) => {
    //console.log(product)
    const defaultLanguage = product._lang ?? C.LANGUAGES[0]
    const links = [
        product.manufactured ? manufactured[language] : distrib[language],
        language === defaultLanguage
            ? {
                  title: product.cat?.title,
                  p: product.cat?.path?.filter(p => p.lang === language)?.[0]?.p,
              }
            : {
                  title: product.cat?._i18n?.[language]?.title,
                  p: product.cat?.path?.filter(p => p.lang === language)?.[0]?.p,
              },
        {
            title: Entity.get(product, "title", { entityInfo, defaultLanguage, language }),
            p: Entity.getPath(product, language),
        },
    ]
    return (
        <>
            {links.map((link, i) => (
                <Link key={i} to={link.p}>
                    {link.title}
                </Link>
            ))}
        </>
    )
}
const renderCategory = (category, entityInfo, language) => {
    //console.log(product)
    const defaultLanguage = category._lang ?? C.LANGUAGES[0]
    const links = [
        category.manufactured ? manufactured[language] : distrib[language],
        {
            title: Entity.get(category, "title", { entityInfo, defaultLanguage, language }),
            p: Entity.getPath(category, language),
        },
    ]
    return (
        <>
            {links.map((link, i) => (
                <Link key={i} to={link.p}>
                    {link.title}
                </Link>
            ))}
        </>
    )
}

const Breadcrumbs = ({ info, index }) => {
    const { language, entity, entityInfo } = info
    if (entity.type === "product") return renderProduct(entity, entityInfo, language)
    if (entity.type === "category") return renderCategory(entity, entityInfo, language)
    return null
}

export default Breadcrumbs
