import LinkRenderer from "./LinkRenderer"
import Block from "./Block"
import Banner from "./Banner"
import Variants from "./Variants"
import Contact from "./Contact"
import ContactForm from "./ContactForm"
//import Gltf from "./Gltf"
import Body from "./Body"
import ImgRenderer from "./ImgRenderer"
import DataTable from "./DataTable"
import Catalog from "./Catalog"
import CategoryLink from "./CategoryLink"
import Carousel from "./Carousel"
import Button from "./Button"
//import ProductsCarousel from "./ProductsCarousel"
import ProductImage from "./ProductImage"
import CategoryProducts from "./CategoryProducts"
import Breadcrumbs from "./Breadcrumbs"
import Similar from "./Similar"
//import Track from "./Track"
import Description from "./Description"
import ImageCards from "./ImageCards"
import ImgTrack from "./ImgTrack"
import ContactMap from "./ContactMap"
import Aheader from "./Header"
import Promo from "./Promo"

const fieldRenderer = {
    ImgRenderer,
    ImgTrack,
    Body,
    LinkRenderer,
    Block,
    Description,
    Breadcrumbs,
    Catalog,
    Button,
    //Track,
    Carousel,
    Contact,
    ContactMap,

    CategoryProducts,
    CategoryLink,
    ProductImage,
    Variants,
    Similar,
    Banner,
    ImageCards,
    //ProductsCarousel,

    ContactForm,
    //Gltf,
    DataTable,
    Aheader,
    Promo,
}
export default fieldRenderer
