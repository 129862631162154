import React, { Suspense, useRef, useContext, useCallback } from "react"
import { AuthContext } from "../auth"
import { useUnmounted, useSubscriptionProvider } from "../hooks"
import { useIntersection } from "../intersection"
import { useLanguageContext } from "../lang"
import Entity from "../entity"
import Field from "./Field"
import { renderLayout } from "./Region"
const FieldManager = React.lazy(() =>
    import("admin").then(module => ({ default: module.FieldManager }))
)

const EntityView = props => {
    const {
        info,
        language,
        entity,
        entityInfo,
        visibility,
        dynamic,
        noadmin,
        data,
        tag,
        display,
        className,
        style,
        children,
        ...rest
    } = props
    const languageContext = useLanguageContext()
    //console.log(languageContext, entity)
    const domRef = useRef()
    const unmounted = useUnmounted()
    const visible = useRef()
    const { user } = useContext(AuthContext)
    const setFieldAdmin = useSubscriptionProvider("fieldAdmin")
    const entityDisplay = display || (info ? info.display : undefined)
    const localEntityInfo = entityInfo ?? Entity.getTypeInfo(entity)

    const newInfo = {
        entity,
        entityInfo: localEntityInfo,
        user,
        display: entityDisplay,
        displayInfo: Entity.getDisplayInfo(entity, localEntityInfo, entityDisplay),
        language: language ?? info?.language ?? languageContext?.language,
        noadmin,
        data,
    }
    //console.log(props, newInfo)

    const parents = useRef()
    const lineageId = useRef()
    const setupLineage = () => {
        parents.current = [
            ...(info && info.parents && info.parents.current ? info.parents.current : []),
            { domRef: domRef, ...props },
        ]
        lineageId.current = ""
        parents.current.forEach(item => {
            const { entity, field } = item
            lineageId.current += `${entity ? Entity.getId(entity) : 0} - ${field}`
        })
    }
    const mouseOver = useRef(false)
    const onMouseOver = useCallback(() => {
        if (noadmin || !user) return
        if (mouseOver.current) return
        //console.log("mouse over", parents.current)
        mouseOver.current = true
        setFieldAdmin({ mouse: "enter", fieldLine: parents.current })
    }, [user, noadmin, setFieldAdmin])

    const onMouseLeave = useCallback(() => {
        if (noadmin || !user) return
        //console.log("mouse leave", parents.current)
        mouseOver.current = false
        setFieldAdmin({ mouse: "leave", fieldLine: parents.current })
    }, [user, noadmin, setFieldAdmin])

    const onIntersect = useCallback(
        entry => {
            if (unmounted.current || visible.current || !entry.isIntersecting) return
            visible.current = true
            domRef.current.classList.add("visible")
        },
        [unmounted]
    )
    useIntersection(entity && visibility ? domRef : null, onIntersect)

    if (!entity) return null
    const wrapperProps = { style }
    if (user) {
        /* && !parents.current)*/
        setupLineage()
        newInfo.parents = parents
        wrapperProps.onMouseOver = onMouseOver
        wrapperProps.onMouseLeave = onMouseLeave
    }
    const classes = `entity entity-type-${entity.type} ${className ?? ""} display-${
        display ?? "default"
    }`

    const Tag = tag || "div"

    if (!children) {
        //console.log(o)
        //const displayInfo = Entity.getDisplayInfo(entity, newInfo.entityInfo, entityDisplay)

        const o = newInfo.entityInfo?.fields?.filter(f => newInfo.displayInfo?.[f] !== false)
        //console.log(displayInfo, o, entity)
        return (
            <Tag {...wrapperProps} ref={domRef} className={classes} {...rest}>
                {newInfo.displayInfo._layout
                    ? renderLayout(newInfo, newInfo.displayInfo._layout, o)
                    : o?.map((f, i) => <Field key={i} field={f} info={newInfo} />)}
                {newInfo?.entityInfo?.display?.[entityDisplay]?._footer?.(entity)}
                {user && dynamic && (
                    <Suspense fallback={null}>
                        <FieldManager user={user} entity={entity} />
                    </Suspense>
                )}
            </Tag>
        )
    }
    return (
        <Tag ref={domRef} className={classes} {...wrapperProps} {...rest}>
            {children(newInfo)}
        </Tag>
    )
}
export default EntityView
