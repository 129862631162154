import React from "react"
import { LazyImage } from "lib"

const ImageCards = ({ domRef, info, value, style, ...props }) => {
    const [images, cardsStyle] = React.useMemo(() => {
        const images = [...(value ?? [])].reverse()
        const cardsStyle = { ...(style ?? {}), "--n-cards": images?.length ?? 1 }
        return [images, cardsStyle]
    }, [value, style])
    return (
        <div ref={domRef} image-cards="" style={cardsStyle} {...props}>
            {images.map((img, i) => (
                <LazyImage key={i} bg src={img} imageStyle="cards" style={{ "--index": i }} />
            ))}
        </div>
    )
}
export default ImageCards
