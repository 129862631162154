import React from "react"
import { AuthContext } from "../auth"
import FaIcon from "./FaIcon"

const AuthStat = ({ user }) => {
    React.useEffect(() => {
        if (user) {
            document.body.removeAttribute("not-logged-in")
            document.body.setAttribute("logged-in", "")
        } else {
            document.body.removeAttribute("logged-in")
            document.body.setAttribute("not-logged-in", "")
        }
    }, [user])
    return null
}

const AuthButton = ({ className }) => (
    <div className={`auth ${className ?? ""}`}>
        <AuthContext.Consumer>
            {({ user, login, logout }) =>
                user ? (
                    <div className="auth-button logout" onClick={logout}>
                        <AuthStat user={user} />
                        <FaIcon icon="action/lock_open" size={24} />
                    </div>
                ) : (
                    <div className="auth-button login" onClick={login}>
                        <AuthStat user={user} />
                        <FaIcon icon="action/lock" size={24} />
                    </div>
                )
            }
        </AuthContext.Consumer>
    </div>
)

export default AuthButton
