import React from "react"

const DEFAULT_DELAY = 0.1
const Typography = ({ tag, className, text, initialDelay, delay, args }) => {
    const Tag = tag || "div"
    const classes = `typography ${className || ""}`
    const initDelay = typeof initialDelay === "undefined" ? 0 : initialDelay
    let n = 0
    const itemDelay = typeof delay === "undefined" ? DEFAULT_DELAY : delay
    if (typeof text === "undefined") return null
    return (
        <Tag className={classes} {...args}>
            {text.split(" ").map((word, i) => (
                <span key={i} className={`word word${i}`}>
                    {word.split("").map((letter, j) => {
                        const style = { "--let-delay": `${initDelay + itemDelay * n}s` }
                        n += 1
                        return (
                            <span key={j} className={`let let${j}`} style={style}>
                                {letter}
                            </span>
                        )
                    })}
                </span>
            ))}
        </Tag>
    )
}
export default React.memo(Typography)
