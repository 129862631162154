import { getTypeInfo, getSettings } from "./type_info"
import { get } from "./accessors"

/**
   Entity.fold(entity,
              (acc, value, fieldName, fieldInfo, path) => acc,
              initialAcc)
*/

const foldone = (acc, parent, parentPath, parentInfo, cb, field) => {
    const path = [...parentPath, field]
    const fieldInfo = getTypeInfo(field, parent, parentInfo)
    const value = get(parent, field, { entityInfo: parentInfo })
    if (value === null || value === undefined) return acc
    return foldrec(cb(acc, value, field, fieldInfo, path.join(".")), value, path, fieldInfo, cb)
}
const foldrec = (acc, parent, parentPath, parentInfo, cb) => {
    //console.log(acc, parent, parentPath, parentInfo)
    let ret =
        (parentInfo?.type === "list"
            ? parent?.reduce(
                  (acc, value, i) => foldone(acc, parent, parentPath, parentInfo, cb, `${i}`),
                  acc
              )
            : parentInfo?.fields?.reduce(
                  (acc, field) => foldone(acc, parent, parentPath, parentInfo, cb, field),
                  acc
              )) ?? acc
    ret =
        parentInfo?.metaFields?.reduce(
            (acc, field) => foldone(acc, parent, parentPath, parentInfo, cb, `meta.${field}`),
            ret
        ) ?? ret
    return (
        getSettings(parentInfo)?.reduce(
            (acc, field) => foldone(acc, parent, parentPath, parentInfo, cb, field),
            ret
        ) ?? ret
    )
}
export const fold = (entity, cb, initial) => foldrec(initial, entity, [], getTypeInfo(entity), cb)
