import React from "react"
import { Entity, Link, FaIcon } from "lib"

const renderText = text =>
    text.split("\n").map((item, i) =>
        i > 0 ? (
            <React.Fragment key={i}>
                <br />
                {item}
            </React.Fragment>
        ) : (
            <React.Fragment key={i}>{item}</React.Fragment>
        )
    )

const renderValue = (value, children) => {
    let val = `${value}`
    if (!value || val.trim() === "") return null
    const toks = val.split("®")
    if (toks.length === 1)
        return (
            <>
                {renderText(val)}
                {children}
            </>
        )
    return toks.map((text, i) => {
        if (i === 0)
            return (
                <>
                    {renderText(text)}
                    {children}
                </>
            )
        return (
            <React.Fragment key={i}>
                <sup>®</sup>
                {renderText(text)}
            </React.Fragment>
        )
    })
}
const renderIcon = props => {
    if (!props) return null
    if (typeof props === "string") return <FaIcon icon={props} />
    if (!props.icon) return null
    return <FaIcon {...props} />
}
const Text = ({
    info,
    value,
    pre,
    post,
    link,
    linkTo,
    iconBefore,
    iconAfter,
    message,
    children,
}) => {
    //console.log("Text renderer", info, value, children)
    //if (!value || typeof value !== "string" || value.trim() === "") return null
    //console.log(info)
    if (link) {
        return (
            <Link
                to={Entity.getPath(info.entity, info.language)}
                fieldInfo={info?.fieldInfo}
                message={message}
            >
                {pre}
                {renderIcon({
                    icon: iconBefore || info.fieldInfo.iconBefore || iconBefore,
                    className: "before",
                })}
                {renderValue(value, children)}
                {renderIcon({
                    icon: iconAfter || info.fieldInfo.iconAfter || iconAfter,
                    className: "after",
                })}
                {post}
            </Link>
        )
    }
    const lt = linkTo
    if (lt)
        return (
            <Link to={lt} fieldInfo={info?.fieldInfo} message={message}>
                {pre}
                {renderIcon({
                    icon: iconBefore || info.fieldInfo.iconBefore || iconBefore,
                    className: "before",
                })}
                {renderValue(value, children)}
                {renderIcon({
                    icon: iconAfter || info.fieldInfo.iconAfter || iconAfter,
                    className: "after",
                })}
                {post}
            </Link>
        )
    return (
        <>
            {pre}
            {renderIcon({
                icon: iconBefore || info.fieldInfo.iconBefore || iconBefore,
                className: "before",
            })}
            {renderValue(value, children)}
            {renderIcon({
                icon: iconAfter || info.fieldInfo.iconAfter || iconAfter,
                className: "after",
            })}
            {post}
        </>
    )
}
export default Text
