import React from "react"
import { Transition, TransitionGroup } from "react-transition-group"
import { C, Entity, History, publish } from "lib"
import { NodeContext, useNodeContextProvider } from "./NodeContext"
import { nodeLayout } from "components"

const NodeInner = props => {
    const { nodeRef, location, node, status, first, language } = props

    const state = useNodeContextProvider(props)
    //const ref = React.useRef()
    const prevState = React.useRef()

    React.useLayoutEffect(() => {
        if (prevState.current !== status && status === "entered") {
            publish("title", node?.title ?? "")
        }

        if (status === "entering") {
            requestAnimationFrame(() => {
                const oldNode = document.querySelector('[node-status="exiting"]')
                if (!first && oldNode) {
                    const oldPage = History.getPrevPage()
                    const oldScroll = window.scrollY

                    if (oldPage) oldPage.scroll = oldScroll

                    let sameNode = false
                    if (C.LANGUAGES) {
                        if (
                            oldPage &&
                            node &&
                            node.path &&
                            node.path.filter(path => path.p === oldPage.pathname).length > 0
                        ) {
                            // same node; don't scroll
                            sameNode = true
                        }
                    }

                    const newPage = History.getLocation() //window.history.state
                    const newScroll = sameNode
                        ? oldScroll
                        : newPage && newPage.scroll
                        ? newPage.scroll
                        : 0

                    if (nodeRef.current) {
                        nodeRef.current.style.position = "relative"
                        nodeRef.current.style.transform = "none"
                    }
                    oldNode.style.position = "absolute"
                    oldNode.style.top = 0
                    oldNode.style.left = 0
                    oldNode.style.width = "100%"
                    oldNode.style.transform = `translate(0, ${newScroll - oldScroll}px)`

                    if (nodeRef.current) {
                        nodeRef.current.style.position = "relative"
                        nodeRef.current.style.transform = "none"
                    }
                    window.scrollTo(0, newScroll)
                } else {
                    if (nodeRef.current) {
                        nodeRef.current.style.position = "relative"
                        nodeRef.current.style.transform = "none"
                    }
                }
            })
        }

        prevState.current = status
    }, [nodeRef, node, status, first])

    const args = React.useMemo(() => {
        const args = {
            node: getPathLast(location),
            "node-type": node?.type,
            "node-status": status,
        }
        if (first) args["node-first"] = ""
        else args["node-not-first"] = ""
        if (node?._c?._class) args.className = node?._c?._class
        if (node?._c?._cssid) args["id"] = node._c._cssid

        return args
    }, [node, status, location, first])

    const NodeLayout = React.useMemo(() => {
        //console.log(node, location)
        return nodeLayout(node, location)
    }, [node, location])
    if (!node) return null
    //console.log(args)
    return (
        <article ref={nodeRef} {...args}>
            <NodeContext.Provider value={state}>
                <NodeLayout entity={node} language={language} />
            </NodeContext.Provider>
        </article>
    )
}

const getKey = (node, language) => {
    const items = []
    if (C?.LANGUAGES?.length > 0) items.push(language ?? C.LANGUAGES[0])
    items.push(Entity.getId(node))
    return items.join(":")
}
const getPathLast = location => (location === "/" ? "index" : location.split("/").pop())

const NodeScrollManager = props => {
    const nodeRefs = React.useRef({})
    const { location, search, language, node, first } = props
    const [key, nodeRef] = React.useMemo(() => {
        const key = getKey(node, language)
        if (!nodeRefs.current[key]) nodeRefs.current[key] = React.createRef()
        return [key, nodeRefs.current[key]]
    }, [node, language])
    /*
    if (node)
        console.log(
            Entity.fold(
                node,
                (acc, value, fieldName, fieldInfo, path) => {
                    console.log(path)
                    if (fieldInfo.type !== "ref" || !value?.ref) return acc
                    console.log(value, fieldName, fieldInfo, path)
                    return [...acc, { ref: value.ref, type: fieldInfo.ref, cache: fieldInfo.cache }]
                },
                []
            )
        )
        */
    return (
        <TransitionGroup main-inner="" appear={true} enter={true}>
            <Transition key={key} nodeRef={nodeRef} unmountOnExit={true} timeout={500}>
                {status => (
                    <NodeInner
                        status={status}
                        nodeRef={nodeRef}
                        location={location}
                        search={search}
                        language={language}
                        node={node}
                        first={first}
                    />
                )}
            </Transition>
        </TransitionGroup>
    )
}

export default NodeScrollManager
