import React from "react"
import { useCarouselState } from "./Carousel"
//import Track from "./Track"
//import { FaIcon } from "lib"

const Thumbs = ({ children }) => {
    const carousel = useCarouselState()

    const handleClick = React.useCallback(
        e => {
            const index = parseInt(e.currentTarget.dataset.index)
            carousel.goto(index)
        },
        [carousel]
    )

    return (
        <div thumbs="">
            {React.Children.map(children, (child, i) => (
                <div
                    key={i}
                    className={`item ${i === carousel.state.active ? "active" : ""}`}
                    data-index={i}
                    onClick={handleClick}
                >
                    {child}
                </div>
            ))}
        </div>
    )
}
/*
const isDisabled = (carousel, direction) => {
    const visible = carousel.state.visible ?? 1
    if (visible === 1) return undefined
    if (carousel.items?.length <= visible) return ""
    if (direction === -1) {
        if (carousel.state.current === 0) return ""
        return undefined
    }

    if (carousel.state.current < carousel.items?.length - visible) return undefined
    return ""
}
const Thumbs = ({ direction, children }) => {
    const carousel = useCarousel()
    //widget="thumbs" items={carousel.items}
    return (
        <Carousel>
            {thumbsCarousel => (
                <>
                    {thumbsCarousel.state.visible < thumbsCarousel.items?.length && (
                        <div
                            arrow="left"
                            onClick={thumbsCarousel.prev}
                            data-disabled={isDisabled(thumbsCarousel, -1)}
                        >
                            <FaIcon icon="filled/arrow_right_alt" />
                        </div>
                    )}
                    <Track
                        widget="thumbs-track"
                        active={carousel.state.current}
                        direction={direction}
                        onItemClick={carousel.goto}
                    >
                        {(item, i) => children(item, i)}
                    </Track>
                    {thumbsCarousel.state.visible < thumbsCarousel.items?.length && (
                        <div
                            arrow="right"
                            onClick={thumbsCarousel.next}
                            data-disabled={isDisabled(thumbsCarousel, 1)}
                        >
                            <FaIcon icon="filled/arrow_right_alt" />
                        </div>
                    )}
                </>
            )}
        </Carousel>
    )
}*/
export default Thumbs
