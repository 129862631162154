import React from "react"
import { FaIcon, Field } from "lib"

const Contact = ({ domRef, info, value, children, ...props }) => {
    if (!value) return null
    //console.log(value)
    const classes = value.main ? "header main" : "header"
    return (
        <div ref={domRef} {...props}>
            <div className={classes}>
                <div className="icon">
                    <FaIcon icon="faMapMarkerAlt" />
                </div>
                <Field info={info} field="label" index={1} />
            </div>
            {value.img && <Field info={info} field="img" index={2} />}
            <Field info={info} field="content" index={3} />
        </div>
    )
}
export default Contact
