import React from "react"
import Field from "../Field"

const List = ({ info, value }) => {
    if (!value) return null
    return (
        <>
            {value.map((item, i) => (
                <Field key={i} info={info} field={`${i}`} />
            ))}
        </>
    )
}
export default List
