import React from "react"
import { Entity, Link } from "lib"

const CategoryLink = ({ domRef, info, value, ...props }) => {
    const [title, path] = React.useMemo(
        () => [
            Entity.get(value, "title", {
                entityInfo: Entity.types["category"],
                language: info.language,
                defaultLanguage: info.entity._lang,
            }),
            Entity.getPath(value, info.language),
        ],
        [info.entity, info.language, value]
    )
    if (!value) return null
    //console.log(info)
    return (
        <Link domRef={domRef} to={path} {...props}>
            {title}
        </Link>
    )
}
export default CategoryLink
