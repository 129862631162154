import { C, getImageUrl, Entity } from "lib"

export const ld = {
    homepage: {
        title: "Wintercom",
    },
    page: {
        "@type": "HomeAndConstructionBusiness",
    },
    article: {
        "@type": "HomeAndConstructionBusiness",
    },
    product: product => ({
        "@type": "Product",
        name: product.title,
        //description: "{description}",
        image: product.img?.length > 0 ? getImageUrl(product.img[0], "og") : undefined,
        url: C.BASE + Entity.getPath(product) + ".html",
    }),
}
