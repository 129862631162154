import React from "react"
import Hammer from "hammerjs"
//import { ResizeObserver as Polyfill } from "@juggle/resize-observer"
import { useResizeObserver, useUnmounted } from "lib"
import { useCarousel } from "./Carousel"
//const ResizeObserver = typeof window === "undefined" ? null : window.ResizeObserver || Polyfill

//const config = { childList: true }
const useTrack = (ref, carousel) => {
    const unmounted = useUnmounted()
    React.useEffect(() => {
        carousel.state.n = ref.current.children.length
        requestAnimationFrame(() => {
            if (unmounted.current) return
            carousel.state.ref.current.style.setProperty("--n-items", carousel.state.n)
            carousel.goto(carousel.state.initialActive ?? 0)
        })
        let lastItem
        carousel.onMove(() => {
            if (!ref.current) return
            if (lastItem) {
                lastItem.classList.remove("last")
            }
            if (carousel.state.last >= 0) {
                lastItem = ref.current.children.item(carousel.state.last)
                if (lastItem) {
                    lastItem.classList.remove("active")
                    lastItem.classList.add("last")
                }
            }
            const item = ref.current.children.item(carousel.state.active)
            if (!item) return
            item.classList.add("active")
            ref.current.classList.remove("next", "prev")
            ref.current.classList.add(carousel.state.dir)
            ref.current.style.setProperty("--active", carousel.state.active)
            carousel.trigger()
        })
    }, [carousel, ref, unmounted])

    const resizeCb = React.useCallback(
        entry => {
            const value = (entry.borderBoxSize ?? entry.contentBoxSize)?.[0]
            if (!value) return
            const { inlineSize, blockSize } = value
            requestAnimationFrame(() => {
                if (unmounted.current) return
                if (ref.current) {
                    ref.current.style.setProperty("--w", inlineSize)
                    ref.current.style.setProperty("--h", blockSize)
                }
            })
        },
        [ref, unmounted]
    )
    useResizeObserver(ref, resizeCb)
    /*React.useEffect(() => {
        const target = ref.current
        const observer = new ResizeObserver(resizeCb)
        observer.observe(target)
        return () => {
            observer.unobserve(target)
            if (observer) observer.disconnect()
        }
    }, [resizeCb, ref])*/

    /*React.useEffect(() => {
        const observer = new MutationObserver(cb)
        observer.observe(ref.current, config)
        return () => observer.disconnect()
    }, [cb])*/
    //return ref
}
const Track = ({ domRef, children, ...rest }) => {
    const trackRef = React.useRef()
    const ref = domRef ?? trackRef
    const carousel = useCarousel()
    const hammer = React.useRef()
    //console.log(domRef, trackRef)
    useTrack(ref, carousel)
    React.useEffect(() => {
        try {
            hammer.current = new Hammer(ref.current)
        } catch (e) {
            console.log(e)
        }
    }, [ref])
    React.useEffect(() => {
        try {
            hammer.current.on("swiperight", carousel.prev)
            hammer.current.on("swipeleft", carousel.next)
        } catch (e) {
            console.log(e)
        }
    }, [carousel.next, carousel.prev])
    return (
        <div ref={ref ?? trackRef} track="" {...rest}>
            {React.Children.map(children, (child, i) => (
                <div key={i} className="item">
                    {child}
                </div>
            ))}
        </div>
    )
}
/*
import Hammer from "hammerjs"
import { ResizeObserver as Polyfill } from "@juggle/resize-observer"

const ResizeObserver = typeof window === "undefined" ? null : window.ResizeObserver || Polyfill

const useVisible = (carousel, trackRef) => {
    const setVisible = React.useCallback(() => {
        if (!trackRef.current) return
        let visible =
            window.getComputedStyle(trackRef.current)?.getPropertyValue("--nvisible") ?? "1"

        if (visible === "") visible = 1
        else visible = parseInt(visible)
        if (carousel.state.visible !== visible) {
            carousel.setState(state => ({
                ...state,
                visible,
                type: visible > 1 ? "multi" : "single",
            }))
        }
        //console.log(visible, carousel)
    }, [trackRef, carousel])
    useScreen(setVisible)
    React.useEffect(setVisible, [])
}
const useResizeObserver = (carousel, direction, refs, setStyle, trackRef, refInner, refInner2) => {
    const unmounted = useUnmounted()
    const measures = React.useRef({})
    const { config, items, state } = carousel
    const { current, visible } = state
    const observer = React.useRef()
    const updates = React.useRef({})
    const updateStyle = React.useCallback(
        newStyle => {
            updates.current.styles = {
                ...(updates.current.styles ?? {}),
                ...newStyle,
            }
            //console.log(updates.current.styles)
            if (updates.current.raf) cancelAnimationFrame(updates.current.raf)
            updates.current.raf = requestAnimationFrame(() => {
                if (unmounted.current) return
                const newStyle = updates.current.styles
                updates.current.styles = {}
                //console.log("set style", newStyle)
                setStyle(style => ({ ...(style ?? {}), ...newStyle }))
            })
        },
        [setStyle, unmounted]
    )

    const cb = React.useCallback(
        entries => {
            for (const entry of entries) {
                const height = Math.ceil(entry?.borderBoxSize?.[0]?.blockSize ?? 0)
                if (height) updateStyle({ height })
            }
        },
        [updateStyle]
    )
    React.useEffect(() => {
        let ob
        if (config?.autoSize === true && typeof ResizeObserver !== "undefined") {
            ob = new ResizeObserver(cb)
            observer.current = ob
        }

        return () => {
            if (ob) ob.disconnect()
        }
    }, [config, cb])
    React.useEffect(() => {
        const target = refs?.[current]?.current
        //console.log(target)
        if (target && observer.current) observer.current.observe(target)
        return () => {
            if (target && observer.current) observer.current.unobserve(target)
        }
    }, [refs, current])
    const cbInner2 = React.useCallback(
        entries => {
            for (const entry of entries) {
                //console.log(entry)
                let width =
                    (Math.ceil(entry?.borderBoxSize?.[0]?.inlineSize ?? 0) / items.length) *
                    Math.min(visible, items.length)
                if (width === 0 || measures.current.inner2 === width) return
                measures.current.inner2 = width
                //console.log("inner2", width)
                //setStyle(style => ({ ...(style ?? {}), width }))
                updateStyle({ width })
            }
        },
        [items, updateStyle, visible]
    )
    React.useEffect(() => {
        let ob
        if (direction !== "vertical" && visible > 1 && refInner2.current) {
            //console.log(visible)
            ob = new ResizeObserver(cbInner2)
            ob.observe(refInner2.current)
        }
        return () => {
            if (ob) ob.disconnect()
        }
    }, [direction, visible, refInner2, cbInner2])
    const cbTrack = React.useCallback(
        entries => {
            for (const entry of entries) {
                //console.log(entry)
                let width = Math.ceil(entry?.borderBoxSize?.[0]?.inlineSize ?? 0)
                if (width === 0 || measures.current.track === width) return
                measures.current.track = width
                //console.log(width)
                //setStyle(style => ({ ...(style ?? {}), "--track-width": width }))
                updateStyle({ "--track-width": width })
            }
        },
        [updateStyle]
    )
    React.useEffect(() => {
        let ob
        if (direction !== "vertical" && visible > 1 && trackRef.current) {
            //console.log(visible)
            ob = new ResizeObserver(cbTrack)
            ob.observe(trackRef.current)
        }
        return () => {
            if (ob) ob.disconnect()
        }
    }, [direction, visible, trackRef, cbTrack])
}

const Track = ({ widget, direction, active, onItemClick, children }) => {
    const carousel = useCarousel()
    //const { current } = carousel.state
    const trackRef = React.useRef()
    const hammer = React.useRef()
    const refInner = React.useRef()
    const refInner2 = React.useRef()
    const [basicStyle, setStyle] = React.useState({})
    React.useEffect(() => {
        try {
            hammer.current = new Hammer(trackRef.current)
        } catch (e) {
            console.log(e)
        }
    }, [])
    React.useEffect(() => {
        try {
            hammer.current.on("swiperight", carousel.prev)
            hammer.current.on("swipeleft", carousel.next)
        } catch (e) {
            console.log(e)
        }
    }, [carousel.next, carousel.prev])
    useVisible(carousel, trackRef)
    const refs = React.useMemo(() => carousel.items?.map(item => React.createRef()), [
        carousel.items,
    ])
    useResizeObserver(carousel, direction, refs, setStyle, trackRef, refInner, refInner2)
    const style = React.useMemo(
        () => ({
            ...basicStyle,
            "--active":
                carousel.state.visible > 1 &&
                carousel.items?.length > carousel.state.visible &&
                carousel.state.current > carousel.items?.length - carousel.state.visible
                    ? carousel.items?.length - carousel.state.visible
                    : carousel.state.current,
            "--nitems": carousel?.items?.length ?? 1,
        }),
        [carousel, basicStyle]
    )
    const args = {}
    if (widget) args[widget] = ""
    else args.track = ""
    args["auto-size"] = carousel?.config?.autoSize ? "yes" : "no"
    args["track-direction"] = direction ?? "horizontal"
    const selected = active ?? carousel.state.current
    return (
        <div ref={trackRef} {...args}>
            <div inner="" ref={refInner} style={style}>
                <div inner2="" ref={refInner2}>
                    {carousel.state.visible > 1
                        ? carousel?.items?.map((item, i) => (
                              <div
                                  key={i}
                                  ref={refs[i]}
                                  className={`item ${selected === i ? "active" : ""}`}
                                  data-index={i}
                                  onClick={onItemClick}
                              >
                                  {children(item, i)}
                              </div>
                          ))
                        : carousel?.items?.map((item, i) => (
                              <div
                                  key={i}
                                  ref={refs[i]}
                                  data-index={i}
                                  className={`item ${
                                      i === carousel.state.current ? "active" : ""
                                  } ${i === carousel.state.last ? "last" : ""} ${
                                      carousel.state.dir
                                  }`}
                                  onClick={onItemClick}
                              >
                                  {children(item, i)}
                              </div>
                          ))}
                </div>
            </div>
        </div>
    )
}
*/
export default Track
