import React from "react"
import { useUnmounted, useQuery, Entity, EntityView, Field, Link } from "lib"
const searchPlaceholder = {
    ro: "Caută produs...",
    en: "Search product...",
}

const searchIndex = {}
const useCategories = (manufactured, search, language) => {
    const index = React.useRef()
    React.useEffect(() => {
        const getIndex = async () => {
            if (!searchIndex[language]) searchIndex[language] = await import("./search")
            const index = await searchIndex[language].getIndex(language)
            if (index) index.current = index
        }

        getIndex()
    }, [language])
    const [qCat, qProd] = React.useMemo(() => {
        const qCat = { type: "category" }
        const qProd = { type: "product" }
        if (manufactured) {
            qCat.manufactured = 1
            qProd.manufactured = 1
        } else {
            qCat.$or = [{ manufactured: 0 }, { manufactured: { $exists: 0 } }]
            qProd.$or = [{ manufactured: 0 }, { manufactured: { $exists: 0 } }]
        }
        const sort = { order: 1, title: 1 }
        return [
            { query: qCat, sort },
            { query: qProd, sort },
        ]
    }, [manufactured])

    const [categories] = useQuery(qCat)
    const [products] = useQuery(qProd)
    //if (typeof window !== "undefined") console.log("Catalog data", categories, products)
    const data = React.useMemo(
        () =>
            categories?.map(category => {
                const catId = Entity.getId(category)
                return {
                    category,
                    products: products
                        ?.filter(p => p.cat.ref === catId)
                        ?.map(p => ({
                            id: Entity.getId(p),
                            name: Entity.get(p, "title", { language }),
                            path: Entity.getPath(p, language),
                            p,
                        })),
                }
            }),
        [categories, products, language]
    )
    return React.useMemo(() => {
        if (!search || !index.current) return data
        //const searchString = search.length < 3 ? search : `${search}*`
        //const searchString = search

        const results = index.current.query(q => {
            for (const term of search.split()) {
                q.term(term, { editDistance: 1 })
                //q.term(term, { wildcard: lunr.Query.wildcard.TRAILING })
            }
        })
        /*const results2 = index.current.query(q => {
            for (const term of search.split()) {
                //q.term(term, { editDistance: 1 })
                q.term(term, { wildcard: lunr.Query.wildcard.TRAILING })
            }
        })
        const suggest = index.current.tokenSet.intersect(lunr.TokenSet.fromString(search)).toArray()
        const suggest2 = index.current.tokenSet
            .intersect(lunr.TokenSet.fromString(search + "*"))
            .toArray()
        console.log(
            search,
            results,
            results2,
            suggest,
            suggest2,
            lunr.TokenSet,
            index.current.tokenSet
        )*/
        if (results.length === 0) return data
        const ids = results.map(res => res.ref)
        /*data.map(({ category, products }) => ({
            category,
            products: products.filter(p => ids.includes(p.id)),
        }))
            .filter(cat => cat.products.length > 0)
            .forEach(cat => {
                cat.products.forEach(p => {
                    console.log(p.name)
                })
            })*/
        return data
            ?.map(({ category, products }) => ({
                category,
                products: products.filter(p => ids.includes(p.id)),
            }))
            .filter(cat => cat.products.length > 0)
        /*
        const reSearch = RegExp(`.*${search}`, "i")
        return data
            ?.map(category => {
                return {
                    category: category.category,
                    products: category.products?.filter(p => reSearch.test(p.name)),
                }
            })
            .filter(cat => cat.products.length > 0)
        */
        //return data
    }, [data, search])
}
//const categoriesProps = { className: "categories" }
//const productsProps = { className: "products" }
//const categoryKey = item => Entity.getId(item.category)
//const productKey = item => item.path
const Catalog = ({ info }) => {
    //const { user } = useAuthContext()
    const unmounted = useUnmounted()
    const [search, setSearch] = React.useState()
    const categories = useCategories(info.fieldInfo.manufactured, search, info.language)
    const changeRef = React.useRef()
    const handleChange = React.useCallback(
        e => {
            const value = e.target.value
            if (changeRef.current) clearTimeout(changeRef.current)
            changeRef.current = setTimeout(() => {
                if (unmounted.current) return
                setSearch(value)
            }, 50)
        },
        [unmounted]
    )
    //if (typeof window !== "undefined") console.log("Catalog", categories)
    return (
        <>
        <div className="search-wrap"><div className="inner">
            <input
                type="search"
                className="search"
                placeholder={searchPlaceholder[info.language]}
                onChange={handleChange}
            /></div></div>
            <div className="categories">
                {categories?.map((cat, i) => (
                    <EntityView
                        key={i}
                        info={info}
                        entity={cat.category}
                        display="cat"
                        className={cat.category._pub ? "active" : "disabled"}
                    >
                        {info => (
                            <>
                                <div className="group-header">
                                    <div inner="">
                                        <Field info={info} field="title" />
                                        <Field info={info} field="body" />
                                    </div>
                                </div>
                                <div className="products">
                                    {cat?.products?.map((p, j) => (
                                        <EntityView
                                            key={j}
                                            entity={p.p}
                                            display="product-teaser"
                                            className={p.p._pub ? "active" : "disabled"}
                                            tag={Link}
                                            to={p.path}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </EntityView>
                ))}
            </div>
        </>
    )
}
export default Catalog
