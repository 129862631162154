import React, { Suspense } from "react"

const ShellContent = React.lazy(() =>
    import("admin").then(module => ({ default: module.ShellContent }))
)

const Shell = ({ user, logout, node, pathname, design }) => {
    if (!user || !user.isAdmin) return null
    return (
        <>
            <Suspense fallback={null}>
                <ShellContent view="style" user={user} withAppBar />
            </Suspense>
        </>
    )
}
export default Shell
