import React from "react"
import Typography from "../lib/Typography"

const Banner = ({ value }) => {
    if (typeof value !== "string") return null
    return (
        <>
            {value?.split("\n")?.map((line, i) => (
                <Typography
                    key={i}
                    tag="div"
                    className={`line${i}`}
                    text={line}
                    initialDelay={i * 0.5}
                    delay={0.05}
                />
            ))}
        </>
    )
}

export default Banner
