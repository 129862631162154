import React from "react"
//import { LazyImage } from ".."
//import { renderVideo } from "../../lib/util"
//import RawDraft from "./RawDraft"
//import RawSlate from "./RawSlate"
import RawSlate2 from "./RawSlate2"
import FaIcon from "../FaIcon"
//import htmlRules from "admin/editor/html/html-serializer"
//import Html from "slate-html-serializer"
/*
const parseHTML = s => {
    const parsed = new DOMParser().parseFromString(s, "text/html")
    console.log(parsed)
}*/
/*
const stripTags = text => {
    
    let ret = text
    //let changed = false
    //let res = [...text.matchAll(/<([^\s]+)[^>]*>(.*)<\/\1>/g)]
    let res
    res = [...ret.matchAll(/<[^>]+>/g)]
    if (res.length > 0) {
        //changed = true
        res.forEach(m => {
            ret = ret.replace(m[0], "")
        })
    }
    res = [...ret.matchAll(/<\/[^>]+>/g)]
    if (res.length > 0) {
        //changed = true
        res.forEach(m => {
            ret = ret.replace(m[0], "")
        })
    }
    res = [...ret.matchAll(/<[^>]+\/>/g)]
    if (res.length > 0) {
        //changed = true
        res.forEach(m => {
            ret = ret.replace(m[0], "")
        })
    }
    return ret
    //if (!changed) return text
    //return stripTags(ret)
}*/
const getTrim = (text, trim) => {
    //const res = stripTags(text)
    const res = text.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, " ")
    return res.length > trim ? res.substring(0, trim) + "..." : res
}
const renderIcon = props => {
    if (!props) return null
    if (typeof props === "string") return <FaIcon icon={props} />
    if (!props.icon) return null
    //console.log(props)
    return <FaIcon {...props} />
}

const HTML = props => {
    const { info, value, iconBefore, iconAfter, trim } = props
    //console.log("render HTML", props)
    if (!value) return null
    if (typeof value === "string") {
        if (info.fieldInfo.trim || trim) {
            //const html = new Html({ rules: htmlRules }).deserialize(value)
            //console.log(value, html)
            //parseHTML(value)
            //return trimHTML(value, info.fieldInfo.trim)
            //return <RawSlate {...props} value={html.toJSON()} />
            return getTrim(value, info.fieldInfo.trim || trim)
        }
        return <div className="field-inner" dangerouslySetInnerHTML={{ __html: value }} />
    }
    //if (value.raw) return <RawDraft {...props} />
    //if (value.document) return <RawSlate {...props} />
    //console.log(info)
    if (Array.isArray(value))
        return (
            <>
                {renderIcon({
                    icon: iconBefore,
                    ...(info.fieldInfo.iconBefore ?? iconBefore ?? {}),
                    className: "before",
                })}
                <RawSlate2 {...props} />
                {renderIcon({
                    icon: iconAfter,
                    ...(info.fieldInfo.iconAfter ?? iconAfter ?? {}),
                    className: "after",
                })}
            </>
        )
    console.log("HTML renderer not found for value", value)
    return ""
}
//export default React.memo(HTML)
export default HTML
