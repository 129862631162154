import React from "react"
import { C, Link, LazyImage } from "lib"

const flags = C.LANGUAGES.reduce(
    (acc, lang) => ({
        ...acc,
        [lang]: { url: `/upload/${lang}.png`, w: 45, h: 26, mime: "image/png" },
    }),
    {}
)

const LanguageSelector = ({ node, language, onActivate, tag }) => {
    //const [hydrating, setHydrating] = React.useState(isHydrating())
    const handleActivate = React.useCallback(() => {
        if (onActivate) onActivate()
        return true
    }, [onActivate])
    /*React.useEffect(() => {
        if (!hydrating) return
        requestIdleCallback(() => {
            setHydrating(false)
        })
    }, [hydrating])*/
    const Tag = tag ?? "nav"
    return (
        <Tag className="languages">
            <ul>
                {C.LANGUAGES.map((lang, i) => {
                    const path = node?.path
                        ?.filter(item => item.lang === lang)
                        ?.map(item => item.p) ?? [`/${lang}.html`]

                    return (
                        <li key={i} className={lang === language ? "language active" : "language"}>
                            <Link to={path[0]} onActivate={handleActivate}>
                                <span>{lang}</span>
                                <LazyImage src={flags[lang]} imageStyle="flag" alt={lang} />
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </Tag>
    )
}
export default LanguageSelector
