import React from "react"
import { FaIcon, useUnmounted } from "lib"

const transpose = rows => {
    let rowsT = []
    rows.forEach((row, i) => {
        row.forEach((item, j) => {
            if (!rowsT[j]) rowsT[j] = []
            rowsT[j].push(item)
        })
    })
    return rowsT
}

/*const filterEmptyCols = lines => {
    let rows = []
    lines.forEach(line => {
        if (line.trim() === "") return
        if (re.test(line)) {
            return
        }
        rows.push(line.split(","))
    })
    let rowst = transpose(rows)
    rows = []
    rowst.forEach(row => {
        let empty = true
        for (let i = 0; i < row.length; i++) {
            if (row[i].trim() !== "") {
                empty = false
                break
            }
        }
        if (!empty) rows.push(row)
    })
    rowst = transpose(rows)
    rows = []
    let i = 0
    lines.forEach(line => {
        if (line.trim() === "") return
        if (re.test(line)) {
            rows.push(line)
            return
        }
        rows.push(rowst[i].join(","))
        i++
    })
    return rows
}*/

const reMoreTables = /^-+$/
const reMoreHeaderRows = /^\|H\|(.*)$/
const reTitle = /\|T\|(.*)$/
const reSpan = /\|(\d+)\|(.*)$/
const reRowSpan = /\|(\d+)>(.*)$/
const reUM = /([^(]+)\((.*)\)$/
const getHeaderUM = s => {
    let m = s.match(reUM)
    if (m)
        return [
            <div key={0} className="line0">
                {m[1]}
            </div>,
            <div key={1} className="line1 um">
                ({m[2]})
            </div>,
        ]

    return s
}
const defaultTitle = "Date tehnice"
const parseData = data => {
    //console.log(data)
    let tables = []
    let header = []
    let body = []
    if (!data) return [header, body]
    const lines = data.split("\n")
    let isHeader = true
    let col = []
    let title = defaultTitle
    let headerRow = []
    lines.forEach(l => {
        let line = l.trim()
        if (line === "") {
            if (isHeader) {
                if (headerRow.length > 0) {
                    header.push(headerRow)
                    headerRow = []
                    isHeader = false
                }
            } else {
                if (col.length > 0) {
                    body.push(col)
                    col = []
                }
            }
            return
        }
        let mt = line.match(reTitle)
        if (mt) {
            title = mt[1]
            return
        }

        if (reMoreTables.test(line)) {
            if (col.length > 0) body.push(col)
            body = transpose(body)
            tables.push([title, header, body])
            title = defaultTitle
            header = []
            headerRow = []
            body = []
            col = []
            isHeader = true
            return
        }

        if (reMoreHeaderRows.test(line)) {
            line = line.substr(3)
            isHeader = true
        }
        if (isHeader) {
            let m = line.match(reSpan)
            if (m) {
                headerRow.push([m[1], getHeaderUM(m[2])])
                return
            }
            m = line.match(reRowSpan)
            if (m) {
                headerRow.push([1, m[1], getHeaderUM(m[2])])
                return
            }
            headerRow.push([1, getHeaderUM(line)])

            return
        }
        if (line.trim() === ".") {
            col.push("")
            return
        }
        let m = line.match(reSpan)
        if (m) {
            col.push([m[1], m[2]])
            return
        }
        m = line.match(reRowSpan)
        if (m) {
            col.push([1, m[1], m[2]])
            return
        }
        col.push(line)
    })
    if (col.length > 0) body.push(col)
    //console.log(body)
    body = transpose(body)
    tables.push([title, header, body])
    return tables
}

const SingleTable = ({ table, index }) => {
    const ref = React.useRef()
    const [state, setState] = React.useState({ open: index === 0, height: 0 })
    const { open, height } = state
    const toggle = React.useCallback(() => {
        setState(state => ({ ...state, open: !state.open }))
    }, [])
    React.useEffect(() => {
        if (!ref.current) return
        setState(state => ({ ...state, height: ref.current.getBoundingClientRect().height }))
    }, [table])
    const style = { "--section-height": `${height}px` }
    const [title, header, body] = table
    return (
        <div className={`section ${open ? "open" : "closed"}`}>
            <h2 className="section-title" onClick={toggle}>
                {title} <FaIcon icon="faPlus" />
            </h2>
            <div className="section-content" style={style}>
                <div inner="" ref={ref}>
                    <table>
                        <thead>
                            {header?.map((headerRow, i) => (
                                <tr key={i}>
                                    {headerRow.map((item, k) =>
                                        Array.isArray(item) ? (
                                            item.length === 2 ? (
                                                <th key={k} colSpan={item[0]}>
                                                    <div className="th">{item[1]}</div>
                                                </th>
                                            ) : (
                                                <th key={k} colSpan={item[0]} rowSpan={item[1]}>
                                                    <div className="th">{item[2]}</div>
                                                </th>
                                            )
                                        ) : (
                                            <th key={k}>
                                                <div className="th">{item}</div>
                                            </th>
                                        )
                                    )}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {body?.map((row, i) => (
                                <tr key={i}>
                                    {row.map((td, j) =>
                                        Array.isArray(td) ? (
                                            td.length === 2 ? (
                                                <td key={j} colSpan={td[0]}>
                                                    {td[1]}
                                                </td>
                                            ) : (
                                                <td key={j} colSpan={td[0]} rowSpan={td[1]}>
                                                    {td[2]}
                                                </td>
                                            )
                                        ) : (
                                            <td key={j}>{td}</td>
                                        )
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
const useParentIndex = () => {
    const ref = React.useRef()
    const [index, setIndex] = React.useState(0)
    const unmounted = useUnmounted()
    React.useEffect(() => {
        const updateIndex = () => {
            if (unmounted.current || !ref.current) return
            const ind = Array.from(ref.current.parentNode.parentNode.children).indexOf(
                ref.current.parentNode
            )
            if (ind === index) return
            setIndex(ind)
        }
        requestAnimationFrame(updateIndex)
    }, [index, unmounted])
    return [ref, index]
}
const Table = ({ data }) => {
    const [ref, index] = useParentIndex()
    const tables = React.useMemo(() => parseData(data), [data])
    return (
        <div ref={ref} className="data">
            {tables?.map((table, j) => (
                <SingleTable table={table} key={j} index={index} />
            ))}
        </div>
    )
}

const DataTable = ({ domRef, info, value, children, ...props }) => {
    if (!value) return null
    //const index = info.style?.["--index"]
    //console.log(value, info, props)
    return <Table data={value} />
}
export default DataTable
