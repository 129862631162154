import React from "react"
import { useQuery, Entity, EntityView } from "lib"

const useData = value => {
    const q = React.useMemo(() => {
        if (!value) return null
        const refType = value?.refType
        if (!refType) return null
        const collection = Entity.types[refType]?.collection
        if (!collection) return null
        return {
            query: { _id: value?.ref },
            collection,
        }
    }, [value])
    const [data] = useQuery(q)
    //console.log(data)
    return data?.[0]
}

const Reference = ({ domRef, info, value, ...props }) => {
    const entity = useData(value)
    if (!value) return null
    //console.log(info, value)
    return <EntityView info={info} entity={entity} display="embedded" className="embedded" />
}
export default Reference
