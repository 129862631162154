import React from "react"
import { Link } from "lib"
import LanguageSelector from "./LanguageSelector"
import MobileMenuButton from "../menu/MobileMenuButton"
import MainMenu from "../menu/MainMenu"
import Logo from "./Logo"

const Header = ({ node, language, first, menu, location }) => (
    <header role="banner" id="header" key="header">
        <div inner="">
            <Link id="logo" to={`/${language}.html`}>
                <Logo type="header" />
            </Link>
            <div className="header-in">
                <MainMenu menu={menu} language={language} />
                <LanguageSelector node={node} language={language} />
                <MobileMenuButton />
            </div>
        </div>
    </header>
)

export default Header
