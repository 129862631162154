import React from "react"
import Header from "../header/Header"
import Footer from "./Footer"
import Bg from "./Bg"
import { AuthButton, useScroll } from "lib"
import { useMenu } from "../menu/useMenu"
import MobileMenu from "../menu/MobileMenu"

const scrollCutoff = 250
const useScrolled = () => {
    const rif = React.useRef()
    const state = React.useRef({ scroll: -1, direction: 0, tweens: [] })
    useScroll(scroll => {
        const currentScroll = state.current.scroll < scrollCutoff
        const newScroll = scroll < scrollCutoff
        const currentDirection = state.current.direction
        const direction = scroll >= state.current.scroll
        let tweens = []
        if (currentScroll !== newScroll) tweens.push("tween-scroll")
        if (currentDirection !== direction) tweens.push("tween-direction")
        state.current = { scroll, direction, tweens }
        //console.log(state.current, scroll, direction)
        if (currentScroll === newScroll && currentDirection === direction) return
        if (rif.current) return
        rif.current = requestAnimationFrame(() => {
            rif.current = 0
            const el = document.body //document.getElementById("root")
            el.classList.remove(
                "top",
                "not-top",
                "scroll-down",
                "scroll-up",
                "tween-scroll",
                "tween-direction"
            )
            el.classList.add(
                state.current.scroll < scrollCutoff ? "top" : "not-top",
                state.current.direction ? "scroll-down" : "scroll-up",
                ...state.current.tweens
            )
        })
    })
}
const Layout = ({ location, search, language, node, first, children }) => {
    useScrolled()
    const menu = useMenu()
    return (
        <>
            <Bg />
            <Header node={node} first={first} language={language} menu={menu} location={location} />
            <main>{children}</main>
            <Footer node={node} language={language} menu={menu} />
            <AuthButton />
            <div id="modal-container" />
            <MobileMenu menu={menu} node={node} language={language} />
        </>
    )
}

export default Layout
