import React from "react"
//import { FaIcon, Field, useSubscriptionProvider } from "lib"
import { Field, LazyImage } from "lib"

const Aheader = ({ domRef, info, value, children, ...props }) => {
    //console.log(info, value)
    if(!value) return null
    return (
        <LazyImage domRef={domRef} src={value.bg[0]} bg={true} imageStyle="img" {...props}>
            <div className="aheader-inner">
                <div className="aheader-content">
                    <div className="inner">
                        <div className="inner2">
                            <Field info={info} field="logo"  />
                            <Field info={info} field="content"  />
                        </div>
                    </div>
                </div>
                <Field info={info} field="img"  />
            </div>
        </LazyImage>
    )
}
export default Aheader
