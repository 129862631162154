import React from "react"
import RawSlate2 from "lib/ui/renderers/RawSlate2"
import { FaIcon, useUnmounted } from "lib"

const titles = {
    ro: "Descriere",
    en: "Description",
}
const useParentIndex = () => {
    const ref = React.useRef()
    const unmounted = useUnmounted()
    const [index, setIndex] = React.useState(0)
    React.useEffect(() => {
        const updateIndex = () => {
            if (unmounted.current || !ref.current) return
            const ind = Array.from(ref.current.parentNode.parentNode.children).indexOf(
                ref.current.parentNode
            )
            if (ind === index) return
            setIndex(ind)
        }
        requestAnimationFrame(updateIndex)
    }, [index, unmounted])
    return [ref, index]
}
const Description = ({ info, value, ...rest }) => {
    const [sectionRef, index] = useParentIndex()
    const ref = React.useRef()
    const [state, setState] = React.useState({ open: false, height: 0 }) //info.style["--index"] === 1
    const { open, height } = state
    React.useEffect(() => {
        setState(state => ({ ...state, open: index === 0 }))
    }, [index])
    const toggle = React.useCallback(() => {
        setState(state => ({ ...state, open: !state.open }))
    }, [])
    React.useEffect(() => {
        if (!ref.current) return
        setState(state => ({ ...state, height: ref.current.getBoundingClientRect().height }))
    }, [value])
    const val = value?.[0]?.type === "heading-two" ? value.slice(1) : value
    const title =
        (value?.[0]?.type === "heading-two" ? value?.[0]?.children?.[0]?.text : undefined) ||
        titles[info.language]
    const style = { "--section-height": `${height}px` }
    return (
        <div ref={sectionRef} className={`section ${open ? "open" : "closed"}`}>
            <h2 className="section-title" onClick={toggle}>
                {title} <FaIcon icon="faPlus" />
            </h2>
            <div className="section-content" style={style}>
                <div inner="" ref={ref}>
                    <RawSlate2 info={info} value={val} {...rest} />
                </div>
            </div>
        </div>
    )
}

export default Description
