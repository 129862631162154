import React from "react"
import { LazyImage, useSubscription, Carousel, Thumbs, Track, Arrows, Navigator } from "lib"
import { Lightbox } from "./ImgRenderer"
import Product3DModel from "../products/Product3DModel"

const isModel = img => img?.url?.match(/\.gltf$/i)

const getItems = (entity, name, variant, typeName) =>
    variant?.[
        Object.keys(variant?._e ?? {})?.filter(f => variant?._e?.[f]?.type === typeName)?.[0]
    ] ??
    entity?.[name] ??
    []
const useImage = info => {
    //const { info } = data
    const { entity } = info
    //const { name, typeName } = fieldInfo
    const [index] = useSubscription("product-variant")
    const variant = entity?.variants?.[index ?? 0] ?? entity?.variants?.[0]
    //const model = typeName === "image" ? Entity.get(entity, "gltf") : []
    //const scheme = typeName === "image" ? Entity.get(entity, "scheme") : []
    const model = getItems(entity, "gltf", variant, "gltf")
    const img = getItems(entity, "img", variant, "image")
    const scheme = getItems(entity, "scheme", variant, "scheme")
    //console.log(index, variant, typeName)
    return [...model, ...img, ...scheme]
}
const ProductImage = ({ domRef, info, value, lightbox, imageStyle, children, ...props }) => {
    const [{ open, index }, setState] = React.useState({ open: false, index: -1 })
    const img = useImage(info)
    //console.log(img)
    const handleClick = React.useCallback(e => {
        //console.log("click")
        const index = parseInt(e.currentTarget?.dataset?.index ?? "0", 10)
        setState({ open: true, index })
    }, [])
    const handleClose = React.useCallback(() => {
        setState({ open: false, index: -1 })
    }, [])
    if ((img?.length ?? 0) === 0) return null
    //const carouselConfig = info.fieldInfo?.carousel
    // carousel => items={img} config={carouselConfig}
    return (
        <>
            <Carousel domRef={domRef} {...props}>
                <Thumbs>
                    {img.map((img, i) => {
                        return isModel(img) ? (
                            <Product3DModel
                                key={i}
                                product={info.entity}
                                enabled={false}
                                speed={0.5}
                            />
                        ) : (
                            <LazyImage key={i} src={img} imageStyle="product-thumb" />
                        )
                    })}
                </Thumbs>
                <Track>
                    {img.map((img, i) => {
                        return isModel(img) ? (
                            <Product3DModel
                                key={i}
                                product={info.entity}
                                selected={true}
                                data-index={i}
                                onClick={handleClick}
                            />
                        ) : (
                            <LazyImage
                                key={i}
                                src={img}
                                imageStyle="product"
                                data-index={i}
                                onClick={handleClick}
                            />
                        )
                    })}
                </Track>
                <Arrows />
                <Navigator />
            </Carousel>
            <Lightbox
                info={info}
                open={open}
                className="lightbox-container product"
                //imgProps={imgProps}
                value={img}
                index={index}
                onClose={handleClose}
                noItemClick={true}
            />
        </>
    )
}
export default ProductImage
/*
  <ImgRenderer
  info={info}
  value={img}
  lightbox={lightbox}
  imageStyle={imageStyle}
  children={children}
  />
*/
