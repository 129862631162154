import { types, settings, initRec } from "./types"
import classes from "./classes"
import { pathFromField } from "./util"
//import { flatten } from "../util"
export const flatten = a => {
    if (!Array.isArray(a)) return []
    if (typeof a.flat !== "undefined") return a.flat()
    return a.reduce((acc, i) => acc.concat(Array.isArray(i) ? i : [i]), [])
}

export const $type = Symbol("type")
export const $fieldInfo = Symbol("fieldInfo")
export const $settingInfo = Symbol("settingInfo")
export const $metaInfo = Symbol("metaInfo")
const $itemInfo = Symbol("itemInfo")

export const getSettings = typeInfo =>
    [
        ...(typeInfo?.settings ?? []),
        ...flatten(typeInfo?.typeClasses?.map(tc => settings?.[tc] ?? [])),
    ].map(t => t.name)

const getFieldOrder = (o, typeInfo) => {
    if (!o || !o._o) return typeInfo.fields
    let fields = typeInfo.fields
    if (typeof o._o === "string") {
        let order = (o && o._o && o._o.trim() !== "" ? o._o.split(",") : []).filter(f =>
            fields.includes(f)
        )
        if (fields.length > 0) return [...new Set(order.concat(fields))]
        return order
    } else {
        const ret = {}
        for (const key of Object.keys(o._o)) {
            const currentFields = fields
            ret[key] = o._o[key].split(",").filter(f => currentFields.includes(f))
            fields = currentFields.filter(f => !ret[key].includes(f))
        }
        if (fields.length > 0) {
            if (!ret["content"]) ret["content"] = fields
            else ret["content"] = ret["content"].concat(fields)
        }
        return ret
    }
}

const buildItemTypeInfo = (field, parent, parentInfo, typeClass = "field") => {
    const item = parent?.[field]
    let typeInfo = item?._e ? parent?.[$fieldInfo]?.[field] : parent?.[$fieldInfo]?.[$itemInfo]
    if (!typeInfo) {
        const info =
            typeof parentInfo?.items === "string"
                ? types[parentInfo.items]
                : parentInfo?.items ?? {}
        const fieldDef = { ...info, ...(parentInfo?.itemInfo ?? {}) }
        if (!fieldDef.type || !types[fieldDef.type]) return null

        typeInfo = { ...fieldDef }
        if (!typeInfo.typeName) typeInfo.typeName = typeInfo.type
        if (!typeInfo.typeClasses || !typeInfo.typeClasses.includes(typeClass))
            typeInfo.typeClasses = [...(typeInfo.typeClasses || []), typeClass]

        typeInfo = initRec(typeInfo)
        typeInfo = {
            ...typeInfo,
            ...typeInfo.typeClasses.reduce((acc, tc) => ({ ...acc, ...classes[tc] }), {}),
            //fieldOrder: getFieldOrder(item, typeInfo),
        }
        if (typeInfo.type === "obj") {
            typeInfo.fields = [
                ...new Set([
                    ...typeInfo.fields.map(f => f.name),
                    ...Object.keys(parent?.[field]?._e ?? {}),
                ]),
            ]
            const fieldOrder = getFieldOrder(parent?.[field], typeInfo)
            if (fieldOrder !== typeInfo.fields) typeInfo.fields = fieldOrder
        }
        if (parent) {
            if (!parent[$fieldInfo]) parent[$fieldInfo] = {}
            if (item?._e) parent[$fieldInfo][field] = typeInfo
            else parent[$fieldInfo][$itemInfo] = typeInfo
        }
    }
    if (typeInfo.type === "ref" && typeInfo.cache) {
        typeInfo.fields = typeInfo.cache.split(",")
    }
    const metaFields = [
        ...new Set([
            ...(typeInfo.meta?.map(f => f.name) ?? []),
            ...Object.keys(typeInfo._meta ?? {}).filter(f => f !== "_e"),
        ]),
    ]
    if (metaFields.length > 0) typeInfo.metaFields = metaFields

    if (!parent?._e?.[field]) return typeInfo
    const { type, ref, cache, ...conf } = parent._e[field]
    typeInfo = { ...typeInfo, ...conf }
    if (parent) parent[$fieldInfo][field] = typeInfo
    return typeInfo
}
const buildRefFieldTypeInfo = (field, parent, parentInfo, typeClass = "field") => {
    const refTypeName = parentInfo?.ref ?? parent?.refType
    const refInfo = types[refTypeName]
    const cacheFields = parentInfo.cache ? parentInfo.cache.split(",") : []
    const fieldDefs = refInfo.fields?.filter(f => cacheFields.includes(f.name))
    let fieldDef = fieldDefs?.filter(f => f.name === field)?.[0]
    if (!fieldDef) return null
    if (fieldDef) fieldDef = { ...fieldDef, ...(parent?._e?.[field] ?? {}) }
    //else fieldDef = parent?._e?.[field]

    if (!fieldDef.type || !types[fieldDef.type]) return null
    let typeInfo = { ...fieldDef }
    if (!typeInfo.typeName) typeInfo.typeName = typeInfo.type
    if (!typeInfo.typeClasses) typeInfo.typeClasses = [typeClass]

    typeInfo = initRec(typeInfo)
    typeInfo = {
        ...typeInfo,
        ...typeInfo.typeClasses.reduce((acc, tc) => ({ ...acc, ...classes[tc] }), {}),
    }
    if (typeInfo.type === "obj") {
        typeInfo.fields = [
            ...new Set([
                ...typeInfo.fields.map(f => f.name),
                ...Object.keys(parent?.[field]?._e ?? {}),
            ]),
        ]
        const fieldOrder = getFieldOrder(parent?.[field], typeInfo)
        if (fieldOrder !== typeInfo.fields) typeInfo.fields = fieldOrder
    }
    if (typeInfo.type === "ref" && typeInfo.cache) {
        typeInfo.fields = typeInfo.cache.split(",")
    }
    const metaFields = [
        ...new Set([
            ...(typeInfo.meta?.map(f => f.name) ?? []),
            ...Object.keys(typeInfo._meta ?? {}).filter(f => f !== "_e"),
        ]),
    ]
    if (metaFields.length > 0) typeInfo.metaFields = metaFields

    if (parent) {
        if (!parent[$fieldInfo]) parent[$fieldInfo] = {}
        parent[$fieldInfo][field] = typeInfo
    }
    return typeInfo
}

const buildMetaFieldTypeInfo = (field, parent, parentInfo, typeClass = "meta") => {
    /*
    meta: f1.f2.meta.description
    {
        f1: {
            f2: ...
                _e: {
                    f2: {
                        meta: [{...}, {...}]
                        _meta: {
                            description: ...
                             _e: {
                                  description: {}
                              }
                              _o: ...
                        }
                    }
                }
            [$fieldInfo]: {
                f2: {}
            }
        }
        _e: {
            f1: {}
        }
        [$fieldInfo]: {
            f1: {}
        }
    }
    */

    const _e = parentInfo?._meta?._e ?? {}
    const defaultDefs = (parentInfo.meta ?? []).map(f => ({
        ...f,
        ...(_e[f.name] ?? {}),
    }))
    const defaultDefNames = defaultDefs.map(f => f.name)
    const fieldDefs = [
        ...defaultDefs,
        ...Object.keys(_e)
            .filter(f => !defaultDefNames.includes(f))
            .map(f => _e[f]),
    ]
    let fieldDef = fieldDefs?.filter(f => f.name === field)?.[0]

    if (!fieldDef || !fieldDef.type || !types[fieldDef.type]) return null
    let typeInfo = { ...fieldDef }
    if (!typeInfo.typeName) typeInfo.typeName = typeInfo.type
    if (!typeInfo.typeClasses) typeInfo.typeClasses = [typeClass]

    typeInfo = initRec(typeInfo)
    typeInfo = {
        ...typeInfo,
        ...typeInfo.typeClasses.reduce((acc, tc) => ({ ...acc, ...classes[tc] }), {}),
    }

    if (typeInfo.type === "obj") {
        typeInfo.fields = [
            ...new Set([
                ...typeInfo.fields.map(f => f.name),
                ...Object.keys(parentInfo?._meta?.[field]?._e ?? {}),
            ]),
        ]
        const fieldOrder = getFieldOrder(parentInfo?._meta?.[field], typeInfo)
        if (fieldOrder !== typeInfo.fields) typeInfo.fields = fieldOrder
    }
    if (typeInfo.type === "ref" && typeInfo.cache) {
        typeInfo.fields = typeInfo.cache.split(",")
    }
    const metaFields = [
        ...new Set([
            ...(typeInfo.meta?.map(f => f.name) ?? []),
            ...Object.keys(typeInfo._meta ?? {}).filter(f => f !== "_e"),
        ]),
    ]
    if (metaFields.length > 0) typeInfo.metaFields = metaFields

    if (!parentInfo[$metaInfo]) parentInfo[$metaInfo] = {}
    parentInfo[$metaInfo][field] = typeInfo
    return typeInfo
}
const buildFieldTypeInfo = (field, parent, parentInfo, typeClass = "field") => {
    const parentTypeInfo = parentInfo._type ? types[parentInfo._type] : types[parentInfo.typeName]
    //console.log(parentTypeInfo, parentInfo)
    const fieldDefs = parentTypeInfo.fields
    let fieldDef = fieldDefs?.filter(f => f.name === field)?.[0]
    if (fieldDef) fieldDef = { ...fieldDef, ...(parent?._e?.[field] ?? {}) }
    else fieldDef = parent?._e?.[field]
    //console.log(field, parentInfo, parentTypeInfo, fieldDef)
    if (!fieldDef || !fieldDef.type || !types[fieldDef.type]) return null
    let typeInfo = { ...fieldDef }

    if (!typeInfo.typeName) typeInfo.typeName = typeInfo.type
    if (!typeInfo.typeClasses) typeInfo.typeClasses = [typeClass]
    //console.log(field, fieldDef, { ...typeInfo })

    typeInfo = initRec(typeInfo)
    typeInfo = {
        ...typeInfo,
        ...typeInfo.typeClasses.reduce((acc, tc) => ({ ...acc, ...classes[tc] }), {}),
    }
    //console.log(typeInfo)
    if (typeInfo.type === "obj") {
        typeInfo.fields = [
            ...new Set([
                ...typeInfo.fields.map(f => f.name),
                ...Object.keys(parent?.[field]?._e ?? {}),
            ]),
        ]
        const fieldOrder = getFieldOrder(parent?.[field], typeInfo)
        if (fieldOrder !== typeInfo.fields) typeInfo.fields = fieldOrder
    }
    if (typeInfo.type === "ref" && typeInfo.cache) {
        typeInfo.fields = typeInfo.cache.split(",")
    }
    const metaFields = [
        ...new Set([
            ...(typeInfo.meta?.map(f => f.name) ?? []),
            ...Object.keys(typeInfo._meta ?? {}).filter(f => f !== "_e"),
        ]),
    ]
    if (metaFields.length > 0) typeInfo.metaFields = metaFields

    if (parent) {
        if (!parent[$fieldInfo]) parent[$fieldInfo] = {}
        parent[$fieldInfo][field] = typeInfo
    }
    //console.log({ ...typeInfo })
    return typeInfo
}

const buildSettingTypeInfo = (field, parent, parentInfo, typeClass = "setting") => {
    //console.log(field, parent, parentInfo)
    let fieldDef = [
        ...(parentInfo?.settings ?? []),
        ...flatten(parentInfo?.typeClasses?.map(tc => settings?.[tc] ?? [])),
    ]?.filter(f => f.name === field)?.[0]
    if (!fieldDef) return undefined
    //const settingValue = parentInfo[field]

    if (!fieldDef || !fieldDef.type || !types[fieldDef.type]) return null
    let typeInfo = { ...fieldDef, ...(parentInfo?._e?.[field] ?? {}) }

    if (!typeInfo.typeName) typeInfo.typeName = typeInfo.type
    if (!typeInfo.typeClasses) typeInfo.typeClasses = [typeClass]
    //console.log(field, fieldDef, { ...typeInfo })

    typeInfo = initRec(typeInfo)
    typeInfo = {
        ...typeInfo,
        ...typeInfo.typeClasses.reduce((acc, tc) => ({ ...acc, ...classes[tc] }), {}),
    }
    //console.log(typeInfo)
    if (typeInfo.type === "obj") {
        typeInfo.fields = [
            ...new Set([
                ...typeInfo.fields.map(f => f.name),
                ...Object.keys(parentInfo?.[field]?._e ?? {}),
            ]),
        ]
        const fieldOrder = getFieldOrder(parentInfo?.[field], typeInfo)
        if (fieldOrder !== typeInfo.fields) typeInfo.fields = fieldOrder
    }
    if (typeInfo.type === "ref" && typeInfo.cache) {
        typeInfo.fields = typeInfo.cache.split(",")
    }
    const metaFields = [
        ...new Set([
            ...(typeInfo.meta?.map(f => f.name) ?? []),
            ...Object.keys(typeInfo._meta ?? {}).filter(f => f !== "_e"),
        ]),
    ]
    if (metaFields.length > 0) typeInfo.metaFields = metaFields

    //if (parent) {
    if (!parentInfo[$settingInfo]) parentInfo[$settingInfo] = {}
    parentInfo[$settingInfo][field] = typeInfo
    //}
    return typeInfo
}

/*
parent: e, field, null

config: pathauto
field: f1.f2.text
setting: f1.f2[.settings].cssid
meta: f1.f2.meta.description
{
 f1: {
   f2: ...
   _e: {
       f2: {
           meta: [{...}, {...}]
           _meta: {
               description: ...
               _e: {
                  description: {}
               }
           }
           cssid: ...
           _e: {
               cssid: {
                   asetting: ....
               }
           }
       }
   }
   [$fieldInfo]: {
        f2: {}
   }
 }
 _e: {
    f1: {}
 }
 [$fieldInfo]: {
   f1: {}
 }
}
*/
const _typeInfoRecursive = (parent, parentInfo, path, pathIndex) => {
    //console.log(parent, parentInfo, path, pathIndex)
    const field = path[pathIndex]
    const fieldValue = parent ? parent[field] : null
    const lastItem = pathIndex === path.length - 1
    if (field === "meta") {
        if (!lastItem) {
            const field = path[pathIndex + 1]
            const fieldValue = parentInfo?._meta?.[field]
            const lastItem = pathIndex === path.length - 2
            let fieldInfo = parentInfo?.[$metaInfo]?.[field]
            if (!fieldInfo) fieldInfo = buildMetaFieldTypeInfo(field, parent, parentInfo)
            if (fieldInfo) {
                if (lastItem) return fieldInfo
                return _typeInfoRecursive(fieldValue, fieldInfo, path, pathIndex + 2)
            }
        }
        return null
    }
    /*if (parentInfo.meta) {
        if (Number.isInteger(field)) {
            if (!lastItem) {
                const metaField = path[pathIndex + 1]
                let fieldInfo =
                    parent && parent[field] && parent[field].meta && parent[field].meta[$fieldInfo]
                        ? parent[field].meta[$fieldInfo][metaField]
                        : null
                if (!fieldInfo)
                    fieldInfo = buildMetaFieldTypeInfo(field, metaField, parent, parentInfo)
                if (fieldInfo) {
                    if (pathIndex === path.length - 2) return fieldInfo
                    const metaValue = fieldValue ? fieldValue[metaField] : null
                    return _typeInfoRecursive(metaValue, fieldInfo, path, pathIndex + 2)
                }
            }
            return {
                ...types.obj,
                fields: parentInfo.fields,
                meta: parentInfo.meta,
                dummy: true,
                typeClasses: ["meta"],
                ...classes.meta,
            }
        } else {
            if (!parentInfo.dummy) {
                let fieldInfo =
                    parent && parent.meta && parent.meta[$fieldInfo]
                        ? parent.meta[$fieldInfo][field]
                        : null
                if (!fieldInfo)
                    fieldInfo = buildMetaFieldTypeInfo(undefined, field, parent, parentInfo)
                if (fieldInfo) {
                    if (lastItem) return fieldInfo
                    return _typeInfoRecursive(
                        parent && parent.meta ? parent.meta[field] : null,
                        fieldInfo,
                        path,
                        pathIndex + 1
                    )
                }
            }
        }
        } else {*/
    /*const fieldCb = (type, pathIndex) => {
        switch (type) {
            case "meta": {
                const metaField = path[pathIndex]
                const metaValue = parent?._meta?.[field]?.[metaField]
                const lastItem = pathIndex === path.length - 1
                let metaFieldInfo = parent?.[$metaInfo]?.[field]?.[metaField]
                if (!metaFieldInfo)
                    metaFieldInfo = buildMetaFieldTypeInfo(field, metaField, parent, parentInfo)
                if (metaFieldInfo) {
                    if (lastItem) return metaFieldInfo
                    return _typeInfoRecursive(
                        metaValue,
                        metaFieldInfo,
                        path,
                        pathIndex + 1,
                        fieldCb
                    )
                }
                return ""
            }
            default:
                return null
        }
    }*/
    switch (parentInfo.type) {
        case "obj": {
            let fieldInfo = parent?.[$fieldInfo]?.[field]
            if (!fieldInfo) fieldInfo = buildFieldTypeInfo(field, parent, parentInfo)
            if (fieldInfo) {
                if (lastItem) return fieldInfo
                return _typeInfoRecursive(fieldValue, fieldInfo, path, pathIndex + 1)
            }
            break
        }
        case "ref": {
            let fieldInfo = parent?.[$fieldInfo]?.[field]
            if (!fieldInfo) fieldInfo = buildRefFieldTypeInfo(field, parent, parentInfo)
            if (fieldInfo) {
                if (lastItem) return fieldInfo
                return _typeInfoRecursive(fieldValue, fieldInfo, path, pathIndex + 1)
            }
            break
        }
        case "list": {
            if (Number.isInteger(path[pathIndex])) {
                let itemInfo = parent && parent[$fieldInfo] ? parent[$fieldInfo][field] : null
                if (!itemInfo) itemInfo = buildItemTypeInfo(field, parent, parentInfo)
                if (itemInfo) {
                    if (lastItem) return itemInfo
                    return _typeInfoRecursive(fieldValue, itemInfo, path, pathIndex + 1)
                }
            }
            break
        }
        default:
            break
    }
    //}
    const settingInfo =
        parentInfo?.[$settingInfo]?.[field] ??
        buildSettingTypeInfo(path[pathIndex], parent, parentInfo)
    if (settingInfo) {
        //console.log(field, parent, parentInfo, settingInfo)
        if (lastItem) return settingInfo
        const settingValue = parentInfo[field]
        return _typeInfoRecursive(settingValue, settingInfo, path, pathIndex + 1)
    }
    return undefined
}

export const getTypeInfo = (object, parent = null, parentInfo = null) => {
    if (object === undefined || object === null) return null
    if (typeof object === "string" || typeof object === "number") {
        // f1.f2.cssid -> f1._e.f2.cssid
        let pInfo = parentInfo
        if (!pInfo) {
            // parent is entity
            if (!parent) return null
            pInfo = getTypeInfo(parent)
            if (!pInfo) return null
        }
        const fieldInfo = _typeInfoRecursive(parent, pInfo, pathFromField(object), 0)
        return fieldInfo
    }
    if (object[$type]) return object[$type]
    if (!object.type && !object._id) return null
    if (!types[object.type]) return null
    const typeInfo = types[object.type]
    const info = {
        ...typeInfo,
        ...(object._c || {}),
    }
    if (info.type === "obj") {
        info.fields = [
            ...new Set([...typeInfo.fields.map(f => f.name), ...Object.keys(object._e ?? {})]),
        ]

        const layout = getFieldOrder(object, info)
        if (layout !== info.fields) {
            if (Array.isArray(layout)) info.fields = layout
            else {
                info.layout = layout
                info.fields = Object.keys(layout).reduce((acc, key) => [...acc, ...layout[key]], [])
            }
        }
    }
    //console.log(typeInfo, object._c, info)
    object[$type] = info
    return info
}

export const resetInfo = e => {
    if (!e) return e
    return { ...e, [$type]: undefined, [$fieldInfo]: undefined }
}
export const resetFieldInfoRec = (e, path, index) => {
    if (e[$type]) delete e[$type]
    if (e[$fieldInfo]) delete e[$fieldInfo]
    if (index === path.length) {
        return e
    }
    if (Array.isArray(e)) {
        return [
            ...e.slice(0, path[index]),
            resetFieldInfoRec(e[path[index]], path, index + 1),
            ...e.slice(path[index] + 1),
        ]
    }

    return { ...e, [path[index]]: resetFieldInfoRec(e[path[index]], path, index + 1) }
}
export const resetFieldInfo = (e, field) => {
    if (!e) return e
    const path = pathFromField(field)
    return resetFieldInfoRec(e, path, 0)
}

export const getCollection = entity => {
    if (!entity || !entity.type || !types[entity.type]) return null
    return types[entity.type].collection
}
export const is = (entity, typeClass) => {
    if (!entity || !entity.type || !types[entity.type]) return null
    return types[entity.type].typeClasses.includes(typeClass)
}
