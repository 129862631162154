const entityFields = {
    homepage: [],

    error: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "body", type: "html", label: "Conținut" },
    ],
    page: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "body", type: "body", label: "Conținut" },
    ],
    article: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "date", type: "date", label: "Data" },
        { name: "body", type: "body", label: "Conținut", noteaser: true },
        { name: "header", type: "aheader", label: "Header" },
        { name: "promo", type: "promo", label: "Promo" },
        { name: "promote", type: "bool", label: "Promovat" },
    ],
    category: [
        { name: "breadcrumbs", type: "breadcrumbs" },
        { name: "title", type: "text", label: "Titlu" },
        { name: "img", type: "img", label: "Imagine", _t: false },
        { name: "body", type: "body", label: "Conținut" },
        { name: "path_info", type: "text", computed: true, label: "Pathinfo" },
        { name: "manufactured", type: "bool", label: "Fabricat", _t: false },
        { name: "order", type: "int", label: "Ordine", _t: false },

        {
            name: "category-products",
            type: "atom",
            label: "Lista Produse",
            renderer: "CategoryProducts",
        },
    ],
    product: [
        { name: "breadcrumbs", type: "breadcrumbs" },
        { name: "title", type: "text", label: "Nume" },
        { name: "version", type: "text", label: "Versiune" },
        {
            name: "cat",
            type: "ref",
            ref: "category",
            label: "Categorie",
            cache: "title,path,path_info",
            renderer: "CategoryLink",
            _nowrap: true,
            _t: false,
        },
        {
            name: "img",
            type: "image",
            label: "Imagini",
            _t: false,
            renderer: "ProductImage",
            carousel: {
                autoSize: false,
                fillParent: true,
                thumbs: true,
                thumbsDirection: "vertical",
                arrows: false,
                navigator: false,
                thumbStyle: "thumb",
            },
        },
        { name: "scheme", type: "scheme", label: "Schemă", _t: false, renderer: "ProductImage" },
        { name: "gltf", type: "model", label: "Model 3D" },
        { name: "std", type: "text", label: "Standarde", multiLine: true, rows: 3 },
        {
            name: "variants",
            type: "fv",
            label: "Variante",
            renderer: "Variants",
        },
        { name: "manufactured", type: "bool", label: "Fabricat", _t: false },

        { name: "order", type: "int", label: "Ordine", _t: false },

        { name: "similar", type: "atom", renderer: "Similar", _nowrap: true },
    ],

    menu: [
        { name: "label", type: "text", label: "Nume" },
        { name: "items", type: "menuItems", label: "Elemente" },
    ],
    footer: [{ name: "index", type: "int", label: "Pozitie" }],
    bg: [{ name: "mov", type: "file", label: "Fundal" }],
    ld: [
        { name: "title", type: "text", label: "Nume" },
        { name: "map", type: "ldMap", label: "Data" },
    ],
}
export { entityFields }
