import React from "react"
import { FaIcon, Field, useSubscriptionProvider } from "lib"

const Button = ({ info, value }) => {
    const openContactForm = useSubscriptionProvider()
    const handleClick = React.useCallback(() => {
        openContactForm(value.message)
    }, [value, openContactForm])

    return (
        <button onClick={handleClick}>
            {info.fieldInfo.iconBefore && <FaIcon {...info.fieldInfo.iconBefore} />}
            <Field info={info} field="label" />
            {info.fieldInfo.iconAfter && <FaIcon {...info.fieldInfo.iconAfter} />}
        </button>
    )
}
export default Button
