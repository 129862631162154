import { C, Entity } from "lib"

export const getNodeKey = (location, search, language, node, first) => {
    const items = []
    if (C.LANGUAGES && C.LANGUAGES.length > 0) items.push(language ?? C.LANGUAGES[0])
    items.push(Entity.getId(node))
    return items.join(":")
}

//export const nodeTransition = false
const customNodes = {
    /*"/": {
        pattern: /.* /,
        _id: {},
        _custom: true,
        title: "",
    },*/
}
export default customNodes
