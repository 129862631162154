//import axios from "axios"
import React from "react"
import lazySizes from "lazysizes"
import { isHydrating, isBrowser, isSSR, DPR, C, useScreen, requestIdleCallback, realPath } from "lib"
import { screen } from "../hooks/useScreen"
//import Video from "./renderers/Video"
//import { realPath } from "../util"
/*
if (W <= 400) return "xss"
if (W <= 600) return "xs"
if (W <= 900) return "sm"
if (W <= 1200) return "md"
if (W <= 1600) return "lg"
return "xl"

// default lg
*/
const getBrowser = () => {
    let agent = navigator.userAgent.toLowerCase().match(/(opera|chrome|safari|firefox|msie)/i)
    if (agent) return agent[1]
    if (navigator.userAgent.match(/Edge/i) || navigator.userAgent.match(/Trident.*rv[ :]*11./i))
        return "msie"
    return "other"
}
//console.log(browser)
const sizes = ["xss", "xs", "sm", "md", "lg", "xl"]
const webpNotSupported = ["safari", "msie"]
let supportsWebp = isSSR || !webpNotSupported.includes(getBrowser())
let initDone = false
// check_webp_feature:
//   'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
//   'callback(feature, result)' will be passed back the detection result (in an asynchronous way!)
const check_webp_feature = (feature, callback) => {
    const kTestImages = {
        lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
        lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
        alpha:
            "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
        animation:
            "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
    }
    const img = new Image()
    img.onload = () => {
        var result = img.width > 0 && img.height > 0
        //console.log("OK", result)
        callback(feature, result)
    }
    img.onerror = () => {
        //console.log("EROR")
        callback(feature, false)
    }
    img.src = "data:image/webp;base64," + kTestImages[feature]
}
/*
const checkWebp = async () => {
    if (!createImageBitmap) return false

    const webpData = "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA="
    const blob = await fetch(webpData).then(r => r.blob())
    supportsWebp = await createImageBitmap(blob).then(
        () => true,
        () => false
    )
    initDone = true
    setTimeout(() => {
        lazySizes.init()
    })
    }*/

// eslint-disable-next-line
let webpSettled = false
if (isBrowser) {
    check_webp_feature("lossless", (feature, result) => {
        supportsWebp = result
        webpSettled = true
    })
}
const setInitDone = () => {
    initDone = true
    //console.log("INIT DONE, calling LAZY")
    requestIdleCallback(() => {
        //console.log("LAZY")
        lazySizes.init()
    })
}
if (!isHydrating()) {
    //console.log("NOT HYDRATING")
    initDone = true
    lazySizes.init()
}
const O = {
    landscape: "l",
    portrait: "p",
}
const whiteImg = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
//`${C.BASE}/image${path}.webp`
//const image_extensions = ["jpg", "jpeg", "png"]
const transform_mimes = ["image/jpeg", "image/png"]
const getExtension = path => {
    if (!path) return null
    const dot = path.lastIndexOf(".")
    return path.substring(dot + 1).toLowerCase()
}
const isVideo = src => {
    if(!src) return false
    const ext = getExtension(src.url)
    return ext==='mp4'
}
export const imgPath = (imgurl, imageStyle, imgConfig, screenState) => {
    let aspect = (imgConfig?.w ?? 1) / (imgConfig?.h ?? 1)
    let config = { ...(imgConfig ?? {}), aspect }
    if (!imgurl) return [null, config]
    if (!transform_mimes.includes(config.mime)) return [`${C.BASE}${imgurl}`, config]
    let url = imgurl
    const ext = getExtension(imgurl)
    if (!ext) return [`${C.BASE}${imgurl}`, config]
    if ((!initDone || supportsWebp) && !config.keepFormat) {
        if (config.mime === "image/jpeg" || config.mime === "image/png") {
            const path = imgurl.substring(0, imgurl.lastIndexOf("."))
            url = `${path}.webp`
        }
    }
    const mq = screenState?.MQ ?? screen?.state?.MQ ?? "lg"
    let W = screenState?.W ?? screen?.state?.W ?? 1200
    if (W === 0) W = 1200
    const indexMq = sizes.indexOf(mq)
    const imgStyle = imageStyle ?? "img"
    let style
    for (const mq of sizes.slice(0, indexMq + 1))
        if (C.styles[imgStyle]?.[mq]) style = C.styles[imgStyle]?.[mq]
    if (!style && C.styles[imgStyle]) style = C.styles[imgStyle][Object.keys(C.styles[imgStyle])[0]]

    if (style) {
        //console.log(imageStyle, style, mq, W, url, supportsWebp)
        const unit = style.unit ?? "w"

        let w = style.w
        if (unit === "w") w = Math.ceil((w * W) / 500) * 5
        //console.log(w, dpr)
        if (style.wMax && w > style.wMax) w = style.wMax
        w = w * DPR

        let h = style.h ?? 0
        if (unit === "w") h = Math.ceil((h * W) / 500) * 5
        h = h * DPR

        const quality = style.q ?? 80

        if (h > 0) {
            if (style.fit)
                return [
                    `${C.BASE}/image/w_${w},h_${h},f_${style.fit};q_${quality}${url}`,
                    style.fit === "inside" || style.fit === "outside"
                        ? { ...config, w, h }
                        : { ...config, aspect: w / h, w, h },
                ]
            let wCalc = Math.min(w, config.w)
            let hCalc = wCalc / config.aspect
            if (hCalc > h) {
                hCalc = h
                wCalc = hCalc * config.aspect
            }
            return [
                `${C.BASE}/image/w_${w},h_${h};q_${quality}${url}`,
                { ...config, w: wCalc, h: hCalc },
            ]
        } else {
            let wCalc = Math.min(w, config.w)
            let hCalc = wCalc / config.aspect

            return [`${C.BASE}/image/w_${w};q_${quality}${url}`, { ...config, w: wCalc, h: hCalc }]
        }
    }
    if (url === imgurl) return [`${C.BASE}${url}`, config]
    return [`${C.BASE}/image${url}`, config]
}

const selectArt = (src, screen, svg) => {
    if (!src) return [null, {}]
    if (svg || src.mime === "image/svg+xml") return [src.url, { mime: "image/svg+xml" }]
    //console.log(src)
    if (!src.srcset) return [src.url, src]
    //console.log(src, screen)
    const srcset = src.srcset.filter(
        item =>
            (!item.mq || item.mq.length === 0 || item.mq.indexOf(screen.MQ) >= 0) &&
            (!item.o || item.o.length === 0 || item.o.indexOf(O[screen.O]) >= 0) &&
            (!item.r || item.r.length === 0 || item.r.indexOf(screen.RATIO) >= 0)
    )

    if (srcset.length === 0) return [src.url, src]
    return [srcset[0].src, srcset[0]]
}
export const useImage = (src, imageStyle, svg) => {
    const screen = useScreen()
    const [imgurl, imgConfig] = React.useMemo(() => selectArt(src, screen, svg), [src, screen, svg])

    //console.log(imgurl, imgConfig, screen)
    return React.useMemo(() => imgPath(imgurl, imageStyle, imgConfig, screen), [
        imgurl,
        imgConfig,
        imageStyle,
        screen,
    ])
}
export const useImageUrl = (url, imageStyle) => {
    return useImage({ url }, imageStyle)[0]
}
export const getImageUrl = (src, imageStyle) => {
    const [imgurl, imgConfig] = selectArt(src, screen)
    return imgPath(imgurl, imageStyle, imgConfig, screen)[0]
}

const Video = ({ domRef, value, ...props }) => {
    const videoRef = React.useRef()
    const [paused, setPaused] = React.useState(true)
    /*const [videoProps, rest] = React.useMemo(() => {
        const { videoProps, src, imageStyle, ...rest } = props
        const vprops = videoProps??{}
        Object.keys(vprops).forEach(p => {
            if (vprops[p] === true || vprops[p] === 1) vprops[p] = true
            if (vprops[p] === false || vprops[p] === 0) delete vprops[p]
        })
        //const poster = info.fieldInfo?.poster?.[0]?.url
        //if (poster) vprops["poster"] = realPath(poster)
        return [vprops, rest]
    }, [props])*/
    const togglePlay = React.useCallback(() => {
        if (videoRef.current.paused || videoRef.current.ended) {
            videoRef.current.play()
            //videoRef.current.parentNode.parentNode.parentNode.parentNode.style.zIndex = 100

        } else {
            videoRef.current.pause()
            //videoRef.current.parentNode.parentNode.parentNode.parentNode.style.zIndex = 0
        }
        setPaused(paused => !paused)
    }, [])
    if (!value) return null
    return (
        <div ref={domRef} type-video="">
            <div inner="">
                <video ref={videoRef} playsInline={true} loop={true} muted={true} onClick={togglePlay}>
                    <source src={realPath(value.url)} type="video/mp4" />
                </video>
                {paused && (
                    <img
                        src="/img/sb-play-6.svg"
                        alt="play"
                        className="video-play"
                        onClick={togglePlay}
                    />
                )}
            </div>
        </div>
    )
}


const LazyImage = (props, ref) => {
    const {
        domRef,
        src,
        imageStyle,
        svg,
        style,
        bg,
        alt,
        proportional,
        noth,
        data,
        preload,
        children,
        ...rest
    } = props
    //console.log(props)
    const lazyRef = React.useRef()
    const imgalt = alt ?? ""
    const hydrating = isHydrating()
    if (!initDone && !hydrating) setInitDone()
    //console.log(url)
    const [url, config] = useImage(src, imageStyle, svg)
    //console.log(src, imageStyle, url, config, isBrowser, hydrating)
    const [OuterTag, outerProps, InnerTag, innerProps] = React.useMemo(
        () =>
            bg
                ? [
                      "div",
                      {
                          ref: ref ?? domRef ?? lazyRef,
                          style: {
                              ...(style ?? {}),
                              //backgroundImage: `url(${url})`,
                              "--aspect": config.aspect,
                          },
                          "data-img": url,
                          "data-bg": "bg",
                          ...rest,
                          className: rest.className ? `${rest.className} lazyload` : "lazyload",
                      },
                      null,
                      null,
                  ]
                : [
                      "div",
                      {
                          ref: ref ?? domRef ?? lazyRef,
                          style: {
                              ...(style ?? {}),
                              "--aspect": config.aspect,
                              ...(isBrowser && !hydrating && config.w && config.h
                                  ? { "--w-img": config.w, "--h-img": config.h }
                                  : {}),
                          },
                          ...rest,
                      },
                      "img",
                      {
                          src: whiteImg,
                          "data-img": url,
                          className: "lazyload",
                          ...(isBrowser && !hydrating && config.w && config.h
                              ? { width: config.w, height: config.h }
                              : {}),
                      },
                  ],
        [bg, ref, domRef, url, config, style, rest, hydrating]
    )
    //console.log(isHydrating(), url)
    if (!url) return null
    if(isVideo(src)) return <Video {...props} value={src}/>
    return bg ? (
        <OuterTag key={url} {...outerProps}>
            {children}
        </OuterTag>
    ) : (
        <OuterTag key={url} {...outerProps}>
            <InnerTag {...innerProps} alt={imgalt} />
        </OuterTag>
    )
}

export default React.forwardRef(LazyImage)
