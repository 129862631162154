import { useEffect, useRef } from "react"
import { intersectionObserve, intersectionUnobserve } from "./intersection"

const useIntersection = (ref, cb) => {
    const callback = useRef()
    callback.current = cb

    useEffect(() => {
        const handler = data => callback?.current(data)
        const elem = ref?.current
        if (!elem) return
        intersectionObserve(elem, handler)
        return () => {
            intersectionUnobserve(elem)
        }
    }, [ref])
}
export default useIntersection
