import React from "react"
//import { FaIcon, Field, useSubscriptionProvider } from "lib"
import {Link,  Field, LazyImage } from "lib"

const get_path = info => {
    let path = info.entity.path[0].p
    for(const i in info.entity.path) {
        if (info.entity.path[i].lang === info.language) path = info.entity.path[i].p
    }
    return path
}
const Promo = ({ domRef, info, value, children, ...props }) => {
    //console.log(info, value)
    if(!value) return null
    const path = get_path(info)
    return (
        <LazyImage domRef={domRef} src={value.bg[0]} bg={true} imageStyle="img" {...props}>
            <div className="promo-inner">
                <div className="promo-content">
                    <div className="inner">
                        <div className="inner2">
                            <Field info={info} field="content"  />
                            <Link to={path} className="promo-cta"><Field info={info} field="label"  /></Link>
                        </div>
                    </div>
                </div>
                <Field info={info} field="img"  />
            </div>
        </LazyImage>
    )
}
export default Promo
