import React from "react"
import { useCarousel, Carousel, Arrows, Navigator, Track, useHotkey, LazyImage, Modal } from "lib"
import Product3DModel from "../products/Product3DModel"
import Container from "./Container"

//const lightboxConfig = { flexible: true }
const LightboxCarouselNavigator = () => {
    const carousel = useCarousel()
    useHotkey("ArrowRight", carousel.next)
    useHotkey("ArrowLeft", carousel.prev)

    return null
}

const isModel = img => img?.url?.match(/\.gltf$/i)
const Img = ({ info, img, imageStyle, ...props }) =>
    isModel(img) ? (
        <Product3DModel product={info.entity} selected={true} {...props} />
    ) : (
        <LazyImage src={img} imageStyle={imageStyle} {...props} />
    )

export const Lightbox = ({
    info,
    open,
    className,
    imgProps,
    value,
    index,
    onClose,
    noItemClick,
}) => {
    //const { W, H } = useScreen()
    if (!value) return null

    return (
        <Modal
            open={open}
            className={className ?? "lightbox-container"}
            onClose={onClose}
            showCloseButton={true}
        >
            {value.length === 1 ? (
                <Img img={value[0]} info={info} imagestyle="img" />
            ) : (
                <Carousel initialActive={index} className="lightbox-carousel">
                    <LightboxCarouselNavigator />
                    <Track>
                        {value.map((img, i) => (
                            <Img key={i} img={img} info={info} imageStyle="img" />
                        ))}
                    </Track>
                    <Arrows />
                </Carousel>
            )}
        </Modal>
    )
}
export const ImgRenderer = data => {
    const [{ open, index }, setState] = React.useState({ open: false, index: -1 })
    const {
        domRef,
        info,
        value,
        wrap,
        carousel,
        carouselProps,
        bg,
        lightbox,
        proportional,
        children,
        ...props
    } = data
    const isLightbox = lightbox ?? info?.fieldInfo?.lightbox
    const { fieldInfo } = info
    //console.log(fieldInfo)
    const carouselInfo = carousel ?? fieldInfo._carousel
    const imgProps = {}
    imgProps.imageStyle = props.imageStyle ?? fieldInfo._style ?? fieldInfo.imageStyle ?? "img"
    const lightboxImgProps = { ...imgProps, imageStyle: "img" }
    const handleClick = React.useCallback(
        e => {
            //console.log("click")
            if (!isLightbox) return
            const index = parseInt(e.currentTarget?.dataset?.index ?? "0", 10)
            setState({ open: true, index })
        },
        [isLightbox]
    )
    const handleClose = React.useCallback(() => {
        setState({ open: false, index: -1 })
    }, [])
    if (!value) return null

    if (carouselInfo) {
        return (
            <Container tag={Carousel} info={info} domRef={domRef} {...props}>
                <Track>
                    {value.map((img, i) => (
                        <Img
                            key={i}
                            img={img}
                            data-index={i}
                            onClick={handleClick}
                            info={info}
                            {...imgProps}
                        />
                    ))}
                </Track>
                {carouselInfo.arrows !== false && <Arrows />}
                {carouselInfo.navigator !== false && <Navigator />}
                <Lightbox
                    info={info}
                    open={open}
                    imgProps={lightboxImgProps}
                    value={value}
                    index={index}
                    onClose={handleClose}
                />
            </Container>
        )
    }
    if (value.length === 1)
        return (
            <Img
                domRef={domRef}
                img={value[0]}
                data-index={0}
                info={info}
                {...imgProps}
                {...props}
            />
        )
    return (
        <div ref={domRef} {...props}>
            {value.map((img, i) => (
                <Img key={i} img={img} data-index={i} info={info} {...imgProps} />
            ))}
        </div>
    )
}
/*
export const ImgRenderer = data => {
    const [{ open, index }, setState] = React.useState({ open: false, index: -1 })
    const {
        domRef,
        info,
        className,
        value,
        wrap,
        style,
        carousel,
        carouselProps,
        bg,
        lightbox,
        proportional,
        children,
        ...props
    } = data
    const isLightbox = lightbox ?? info?.fieldInfo?.lightbox
    const { fieldInfo } = info
    const carouselConfig = carousel ?? fieldInfo.carousel
    const imgProps = {}
    imgProps.imageStyle = props.imageStyle ?? fieldInfo._style ?? fieldInfo.imageStyle ?? "img"
    if (fieldInfo.bg) imgProps.bg = fieldInfo.bg
    if (fieldInfo.proportional) imgProps.proportional = fieldInfo.proportional
    if (bg) imgProps.bg = bg
    if (proportional) imgProps.proportional = proportional
    const lightboxImgProps = { ...imgProps, imageStyle: "orig" }
    const handleClick = React.useCallback(
        e => {
            //console.log("click")
            if (!isLightbox) return
            const index = parseInt(e.currentTarget?.dataset?.index ?? "0", 10)
            setState({ open: true, index })
        },
        [isLightbox]
    )
    const handleClose = React.useCallback(() => {
        setState({ open: false, index: -1 })
    }, [])
    if (!value) return null
    return (
        <>
            {!carouselConfig || value.length === 1 ? (
                value.map((img, i) => {
                    return isModel(img) ? (
                        <Product3DModel
                            key={i}
                            product={info.entity}
                            selected={true}
                            data-index={i}
                            className={`${className ?? ""} ${isLightbox ? "lightbox" : ""}`}
                            style={{ "--item-index": i, "--n-items": value.length }}
                            onClick={handleClick}
                        />
                    ) : (
                        <LazyImage
                            domRef={domRef}
                            key={i}
                            src={img}
                            data-index={i}
                            className={`${className ?? ""} ${isLightbox ? "lightbox" : ""}`}
                            style={{ ...style, "--item-index": i, "--n-items": value.length }}
                            onClick={handleClick}
                            {...imgProps}
                            {...props}
                        />
                    )
                })
            ) : (
                <Carousel className="carousel" items={value} config={carouselConfig}>
                    <Track>
                        {(img, i) => {
                            return isModel(img) ? (
                                <Product3DModel
                                    key={i}
                                    product={info.entity}
                                    selected={true}
                                    data-index={i}
                                    onClick={handleClick}
                                />
                            ) : (
                                <LazyImage
                                    key={i}
                                    src={img}
                                    data-index={i}
                                    onClick={handleClick}
                                    {...imgProps}
                                    {...props}
                                />
                            )
                        }}
                    </Track>
                    {carouselConfig.arrows !== false && <Arrows />}
                    {carouselConfig.navigator !== false && <Navigator />}
                    {carouselConfig.thumbs !== false && (
                        <Thumbs direction={carouselConfig.thumbsDirection}>
                            {(img, i) => {
                                return isModel(img) ? (
                                    <Product3DModel
                                        key={i}
                                        product={info.entity}
                                        selected={true}
                                        thumb={true}
                                    />
                                ) : (
                                    <LazyImage
                                        key={i}
                                        src={img}
                                        {...imgProps}
                                        {...props}
                                        imageStyle={carouselConfig?.thumbStyle ?? "thumb"}
                                    />
                                )
                            }}
                        </Thumbs>
                    )}
                </Carousel>
            )}

            <Lightbox
                info={info}
                open={open}
                imgProps={lightboxImgProps}
                value={value}
                index={index}
                onClose={handleClose}
            />
        </>
    )
}
*/
/*
const ImgRenderer = data => {
    if (data?.lightbox ?? data?.info?.fieldInfo?.lightbox) {
        return <ImgLightboxRenderer {...data} />
    }
    return <Img {...data} />
}*/
export default ImgRenderer
