import React from "react"
import { Entity, useQuery, EntityView, Link } from "lib"

//const productsProps = { className: "products" }
const useProducts = info => {
    const { entity } = info
    const id = Entity.getId(entity)
    const query = React.useMemo(
        () => ({
            query: { type: "product", "cat.ref": id },
            sort: { order: 1, title: 1 },
        }),
        [id]
    )
    const [products] = useQuery(query)

    return products
}
const CategoryProducts = ({ info }) => {
    const products = useProducts(info)
    return (
        <div className="products">
            {products?.map((p, j) => (
                <EntityView
                    key={j}
                    info={info}
                    entity={p}
                    display="product-teaser"
                    className={p._pub ? "active" : "disabled"}
                    tag={Link}
                    to={Entity.getPath(p, info.language)}
                />
            ))}
        </div>
    )
}

export default CategoryProducts
