import React from "react"
import { Entity, LazyImage } from "lib"
/*
const O = {
    landscape: "l",
    portrait: "p",
}*/
const BlockRenderer = props => {
    const { domRef, info, value, id, className, style, ...other } = props
    const fieldInfo = info.fieldInfo
    //const screen = useScreen()
    //const bgRef = useRef()
    //const blockInnerRef = useRef()
    const blockInnerStyle = {}
    /*const blockInnerStyle = React.useMemo(() => {
        if (
            fieldInfo.p &&
            fieldInfo.bg &&
            fieldInfo.bg.length > 0 &&
            fieldInfo.bg[0].w > 0 &&
            fieldInfo.bg[0].h > 0
        ) {
            let paddingBottom = (fieldInfo.bg[0].h / fieldInfo.bg[0].w) * 100 + "%"

            if (fieldInfo.bg[0].srcset) {
                const srcset = fieldInfo.bg[0].srcset.filter(
                    item =>
                        (!item.mq || item.mq.length === 0 || item.mq.indexOf(screen.MQ) >= 0) &&
                        (!item.o || item.o.length === 0 || item.o.indexOf(O[screen.O]) >= 0) &&
                        (!item.r || item.r.length === 0 || item.r.indexOf(screen.RATIO) >= 0)
                )
                if (srcset.length > 0 && srcset[0].h > 0 && srcset[0].w > 0) {
                    paddingBottom = (srcset[0].h / srcset[0].w) * 100 + "%"
                }
            }

            return {
                position: "relative",
                height: 0,
                paddingBottom,
            }
        }
        return {}
    }, [fieldInfo, screen])*/

    //if (!value) return <div ref={domRef} />

    let classes = []
    if (className) classes.push(className)

    let blockType = []
    if (
        fieldInfo.p &&
        fieldInfo.bg &&
        fieldInfo.bg.length > 0 &&
        fieldInfo.bg[0].w > 0 &&
        fieldInfo.bg[0].h > 0
    ) {
        //classes.push("block-proportional")
        blockType.push("proportional")
    }
    if (fieldInfo.f) {
        //classes.push("block-fullfixed")
        blockType.push("fullfixed")
    }
    if (info.fieldInfo._o) {
        //classes.push("horizontal")
        blockType.push("horizontal")
        classes.push(`cols-${fieldInfo.fields.length}`)
    }
    const blockStyle = { ...style }
    //if (fieldInfo.bgColor) blockStyle.background = fieldInfo.bgColor
    if (fieldInfo.fields.length === 0) {
        blockStyle.minHeight = "100px"
        classes.push("empty")
    }

    const blockAttributes = {}
    if (blockType.length > 0) blockAttributes["block-type"] = blockType.join(" ")
    if (classes.length > 0) blockAttributes.className = classes.join(" ")

    let Tag = "div"
    if (fieldInfo.bg && fieldInfo.bg.length > 0) {
        Tag = LazyImage
        const bgInfo = Entity.getTypeInfo("bg", value, fieldInfo)
        blockAttributes.domRef = domRef
        blockAttributes.src = fieldInfo.bg[0]
        blockAttributes.bg = true
        blockAttributes.imageStyle = bgInfo?._style ?? "img"
    } else {
        blockAttributes.ref = domRef
    }
    //console.log(blockAttributes)
    //if (blockType.length)
    if (fieldInfo._inner2)
        return (
            <Tag id={id} style={blockStyle} {...other} {...blockAttributes}>
                <div style={blockInnerStyle} block-inner="">
                    <div block-content="">{props.children}</div>
                </div>
            </Tag>
        )
    if (fieldInfo._inner)
        return (
            <Tag id={id} style={blockStyle} {...other} {...blockAttributes}>
                <div style={blockInnerStyle} block-inner="">
                    {props.children}
                </div>
            </Tag>
        )
    return (
        <Tag id={id} style={{ ...blockStyle, ...blockInnerStyle }} {...other} {...blockAttributes}>
            {props.children}
        </Tag>
    )
}
export default BlockRenderer
