import React from "react"
import { FaIcon } from "lib"
import { useCarousel } from "./Carousel"

const isDisabled = (carousel, direction) => {
    if (carousel.state.type === "single") return undefined
    const visible = carousel.state.visible ?? 1
    if (visible === 1) return undefined
    if (carousel.items?.length <= visible) return ""
    if (direction === -1) {
        if (carousel.state.current === 0) return ""
        return undefined
    }

    if (carousel.state.current < carousel.items?.length - visible) return undefined
    return ""
}

const Arrows = () => {
    const carousel = useCarousel()

    if (carousel.items?.length <= carousel.state.visible) return null
    return (
        <div arrows="">
            <div arrow="left" onClick={carousel.prev} data-disabled={isDisabled(carousel, -1)}>
                <FaIcon icon="filled/arrow_right_alt" />
            </div>

            <div arrow="right" onClick={carousel.next} data-disabled={isDisabled(carousel, 1)}>
                <FaIcon icon="filled/arrow_right_alt" />
            </div>
        </div>
    )
}
export default Arrows
