import React from "react"
//import FooterMenu from "../menu/FooterMenu"
import Logo from "../header/Logo"
import { useQuery, EntityView } from "lib"
const qConfig = { single: true }
const qFooter = {
    collection: "site",
    query: { type: "footer" },
    sort: { index: 1 },
}

const Footer = () => {
    const [footer] = useQuery(qFooter, qConfig)

    return (
        <footer id="footer">
            <div className="footer-in">
                <div className="zone">
                    <div className="zone-inner">
                        <div className="footer-logo">
                            <Logo type="footer" />
                        </div>
                    </div>
                </div>
                <EntityView entity={footer} dynamic />

                <div className="copyright">
                    Copyright © 1995-{new Date().getFullYear()} Wintercom
                </div>
            </div>
        </footer>
    )
}
export default Footer
