const imgMeta = [{ name: "caption", type: "html", label: "Credit" }]
const isListEmpty = value => !value || value.length === 0
const isBodyEmptyRec = value => {
    for (const item of value) {
        if (item.text && item.text !== "") return false
        if (item.children && !isBodyEmptyRec(item.children)) return false
    }
    return true
}
const isBodyEmpty = value => !value || !Array.isArray(value) || isBodyEmptyRec(value)
//(value.length === 1 && value[0]?.children[0]?.text === "")
const fieldTypes = {
    img: {
        icon: "filled/photo",
        isEmpty: isListEmpty,
        meta: imgMeta,
        _t: false,
        settings: [{ name: "lightbox", type: "bool", label: "Lightbox" }],
        renderer: "ImgRenderer",
        _nowrap: true,
    },
    text: {
        label: "Text simplu",
        isEmpty: value => !value || (typeof value === "string" && value.trim() === ""),
    },
    html: {
        label: "Text",
        dynamic: true,
    },
    body: {
        label: "Conținut",
        type: "html",
        _nowrap: true,
        fields: [],
        meta: [{ name: "teaser", type: "html", label: "Teaser" }],
        settings: [
            //{ name: "teaser", type: "html", label: "Teaser" },
            { name: "noteaser", type: "bool", label: "Deschis permanent" },
        ],
        renderer: "Body",
        isEmpty: isBodyEmpty,
    },
    link: {
        label: "Legătură",
        icon: "filled/link",
        dynamic: true,
        type: "obj",
        fields: [
            { name: "label", type: "text", label: "Etichetă" },
            { name: "path", type: "text", label: "Legătură" },
        ],
        settings: [
            { name: "iconBefore", type: "icon", label: "Iconiță în față" },
            { name: "iconAfter", type: "icon", label: "Iconiță în spate" },
            { name: "message", type: "text", label: "Mesaj" },
        ],
        isEmpty: value => !value || !value.path || value.path === "",
        _nowrap: true,
        isBlock: true,
        renderer: "LinkRenderer",
        renderChildren: true,
    },
    block: {
        label: "Container",
        icon: "faBoxOpen",
        dynamic: true,
        type: "obj",
        isBlock: true,
        _nowrap: true,
        fields: [],
        settings: [
            { name: "_o", type: "bool", label: "Orizontal", widget: "toggle" },
            { name: "bg", type: "img", label: "Imagine" },
            { name: "p", type: "bool", label: "Proporțional", widget: "toggle" },
            { name: "f", type: "bool", label: "Fullscreen fixed", widget: "toggle" },
        ],
        renderer: "Block",
        renderChildren: true,
    },
    megaMenu: {
        label: "Meniu extins",
        type: "block",
    },
    menuItems: {
        label: "Element",
        type: "union",
        types: ["menuItemsList", "menuItemsView"],
    },
    menuItem: {
        label: "Element",
        type: "obj",
        getLabel: e => (e ? e.title || "Element" : "Element"),
        extendable: ["megaMenu"],
        fields: [
            { name: "title", type: "text", label: "Titlu" },
            { name: "p", type: "text", label: "Cale" },
            { name: "items", type: "menuItems", label: "Elemente" },
        ],
    },
    menuItemsList: {
        label: "Elemente",
        type: "list",
        items: "menuItem",
    },
    menuItemsView: {
        label: "Interogare",
        type: "obj",
        getLabel: e => (e ? e.title || "Interogare" : "Interogare"),
        fields: [
            { name: "title", type: "text", label: "Titlu" },
            { name: "p", type: "text", label: "Cale" },
            { name: "collection", type: "text", label: "Colecție" },
            { name: "query", type: "text", label: "Interogare" },
            { name: "sort", type: "text", label: "Sortare" },
        ],
    },
    banner: {
        label: "Banner",
        dynamic: true,
        type: "text",
        multiLine: true,
        rows: 3,
        renderer: "Banner",
        icon: "faAd",
    },
    fv: {
        label: "Variante",
        type: "list",
        items: "fVariant",
    },
    dataTable: {
        label: "Date tehnice",
        type: "text",
        multiLine: true,
        rows: 15,
        renderer: "DataTable",
    },
    scheme: {
        label: "Schemă",
        type: "img",
        _t: false,
        isEmpty: () => false,
        _nowrap: true,
    },
    image: {
        type: "img",
        _t: false,
        isEmpty: () => false,
    },
    iconWithLabel: {
        label: "Iconiță",
        type: "obj",
        _t: false,
        fields: [
            { name: "icon", type: "icon" },
            { name: "label", type: "text", label: "Etichetă" },
        ],
        dynamic: true,
        renderChildren: true,
    },
    description: {
        label: " Descriere",
        type: "html",
        renderer: "Description",
    },
    fVariant: {
        label: "Variantă",
        type: "obj",
        getLabel: e => (e ? e.title || "Variantă" : "Variantă"),
        extendable: ["html", "description", "dataTable", "scheme", "image"],
        fields: [
            { name: "label", type: "text", label: "Etichetă" },
            { name: "data", type: "dataTable", label: "Date tehnice" },
        ],
        renderChildren: true,
    },
    breadcrumbs: {
        label: "Breadcrumbs",
        icon: "outlined/category",
        type: "atom",
        renderer: "Breadcrumbs",
        _inner: true,
    },
    catalog: {
        label: "Catalog",
        icon: "outlined/category",
        type: "atom",
        renderer: "Catalog",

        settings: [{ name: "manufactured", type: "bool", label: "Fabricate" }],
    },
    button: {
        label: "Buton",
        icon: "outlined/smart_button",
        dynamic: true,
        type: "obj",
        fields: [
            { name: "label", type: "text", label: "Etichetă" },
            { name: "message", type: "text", label: "Mesaj", _t: false },
        ],
        settings: [
            { name: "iconBefore", type: "icon", label: "Iconiță în față" },
            { name: "iconAfter", type: "icon", label: "Iconiță în spate" },
        ],
        renderer: "Button",
    },
    carousel: {
        label: "Carusel",
        dynamic: true,
        icon: "outlined/view_carousel",
        type: "obj",
        fields: [
            { name: "text", type: "html", label: "Text" },
            { name: "img", type: "img", label: "Imagini", renderer: "ImgTrack" },
        ],
        renderer: "Carousel",
        carousel: { autoSize: false },
        _nowrap: true,
    },
    contact: {
        label: "Date Contact",
        dynamic: true,
        icon: "filled/contacts",
        type: "obj",
        getLabel: e => e?.label ?? "Date Contact",
        fields: [
            { name: "label", type: "text", label: "Etichetă" },
            { name: "main", type: "bool", label: "Principal" },
            { name: "img", type: "img", label: "Imagine" },
            { name: "content", type: "html", label: "Conținut" },
        ],
        renderer: "Contact",
        _nowrap: true,
    },
    contactMap: {
        label: "Harta Contact",
        dynamic: true,
        icon: "filled/contacts",
        type: "obj",
        getLabel: e => e?.label ?? "Harta Contact",
        fields: [
            { name: "img", type: "img", label: "Harta" },
            { name: "items", type: "list", items: "contactMapItem", label: "Puncte" },
        ],
        renderer: "ContactMap",
        _nowrap: true,
    },
    contactMapItem: {
        label: "Punct Harta Contact",
        dynamic: true,
        icon: "filled/contacts",
        type: "obj",
        getLabel: e => e?.label ?? "Punct Harta Contact",
        fields: [
            { name: "x", type: "int", label: "X" },
            { name: "y", type: "int", label: "Y" },
            { name: "img", type: "img", label: "Marker" },
            { name: "label", type: "text", label: "Etichetă" },
            { name: "tooltip", type: "block", label: "Conținut" },
        ],
        renderer: "ContactMapItem",
        _nowrap: true,
    },
    model: {
        label: "Model 3D",
        type: "file",
        _t: false,
        settings: [
            { name: "color", type: "color", label: "Culoare" },
            { name: "metalness", type: "text", label: "Metalness" },
            { name: "roughness", type: "text", label: "Roughness" },
        ],
    },
    aheader: {
        label: "Art Header",
        type: "obj",
        fields: [
            { name: "bg", type: "img", label: "Fundal" },
            { name: "logo", type: "img", label: "Logo" },
            { name: "img", type: "img", label: "Imagine" },
            { name: "content", type: "html", label: "Continut" },
        ],
        renderer: "Aheader",
        _nowrap: true,
        _visibility: true,
    },
    promo: {
        label: "Promo",
        type: "obj",
        fields: [
            { name: "bg", type: "img", label: "Fundal" },
            { name: "logo", type: "logo", label: "Logo" },
            { name: "img", type: "img", label: "Imagine" },
            { name: "content", type: "html", label: "Continut" },
            { name: "label", type: "text", label: "CTA" },
        ],
        renderer: "Promo",
        _nowrap: true,
        _visibility: true,
    },
    ldValue: {
        label: "LD Value",
        type: "union",
        types: ["text", "ldList", "ldMap"],
    },
    ldEntry: {
        label: "LD Entry",
        type: "obj",
        fields: [
            { name: "key", type: "text", label: "Etichetă" },
            { name: "value", type: "ldValue", label: "Valoare" },
        ],
        editor: "entry",
    },
    ldList: {
        label: "LD List",
        type: "list",
        items: "text",
    },
    ldMap: {
        label: "LD Map",
        type: "list",
        items: "ldEntry",
    },
}

export { fieldTypes }
