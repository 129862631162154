import React, { useEffect } from "react"
import { realPath } from "../../util"
//import Carousel from "../Carousel"
import LazyImage from "../LazyImage"
//import { useInterval } from "../../hooks"
/*
const crossFadeMove = (t, itemRefs, prev, active, direction) => {
    t.to(itemRefs[prev].current, { opacity: 0, duration: 1 }, 0)
    t.to(itemRefs[active].current, { opacity: 1, duration: 1 }, 0.5)
}

const Slideshow = ({ domRef, info, className, value, wrap, carousel, carouselProps, ...props }) => {
    const { fieldInfo } = info
    const commands = React.useRef()
    const onReady = React.useCallback(cmds => {
        commands.current = cmds
    }, [])
    const extraProps = React.useMemo(
        () => ({
            autoSize: true,
            images: value,
            onReady,
            renderNavigator: () => null,
            move: crossFadeMove,
            itemStyle: { opacity: 0 },
            //setPositions,
            ...carouselProps,
        }),
        [carouselProps, onReady, value]
    )

    useInterval(() => {
        if (!commands.current) return
        commands.current.next()
    }, fieldInfo._slideshow.i)
    const itemProps = {}
    if (extraProps.fillParent) itemProps.bg = true

    return (
        <Carousel {...fieldInfo.widgetProps} {...extraProps}>
            {value.map((img, i) => {
                if (fieldInfo.itemRenderer) return fieldInfo.itemRenderer(img, i)
                //const mime = img.mime ? img.mime.split("/") : "image"
                return <LazyImage key={i} src={img} {...itemProps} />
            })}
        </Carousel>
    )
}*/

const Img = data => {
    const {
        domRef,
        info,
        className,
        value,
        wrap,
        style,
        carousel,
        carouselProps,
        bg,
        proportional,
        limit,
        children,
        ...props
    } = data
    const { fieldInfo } = info
    //console.log(value)
    useEffect(() => {
        if (value && value.length === 0 && fieldInfo.onActive)
            fieldInfo.onActive(value[0], props.data)
    }, [fieldInfo, value, props.data])
    /*
    const renderCarousel = () => {
        const extraProps = {
            autoSize: true,
            images: value,
            ...carouselProps,
            fillParent: true,
            //fillHeight: true,
        }

        if (fieldInfo.arrowRenderers) extraProps.arrowRenderers = fieldInfo.arrowRenderers
        if (fieldInfo.renderNavigator) extraProps.renderNavigator = fieldInfo.renderNavigator
        if (fieldInfo.onActive) extraProps.onActive = this.onActive

        const itemProps = {}
        if (extraProps.fillParent) itemProps.bg = true
        return (
            <Carousel {...fieldInfo.widgetProps} {...extraProps}>
                {value.map((img, i) => {
                    if (fieldInfo.itemRenderer) return fieldInfo.itemRenderer(img, i)
                    const mime = img.mime ? img.mime.split("/") : "image"
                    if (mime[0] === "video")
                        return (
                            <video autoPlay playsInline muted loop>
                                <source src={realPath(img.url)} type={img.mime} />
                            </video>
                        )
                    return <LazyImage key={i} src={img} {...itemProps} />
                })}
            </Carousel>
        )
    }
    */
    const imgProps = {}
    //if (fieldInfo.imageStyle) imgProps.imageStyle = fieldInfo.imageStyle
    //console.log(fieldInfo, props)
    imgProps.imageStyle = props.imageStyle ?? fieldInfo._style ?? fieldInfo.imageStyle ?? "img"
    if (fieldInfo.bg) imgProps.bg = fieldInfo.bg
    if (fieldInfo.proportional) imgProps.proportional = fieldInfo.proportional
    if (bg) imgProps.bg = bg
    if (proportional) imgProps.proportional = proportional

    if (!value || value.length === 0) return null
    let val = value
    if (limit) val = value.slice(0, limit)
    /*
    if (carousel || fieldInfo.widget === "carousel" || fieldInfo._carousel) {
        return renderCarousel()
    }
    if (fieldInfo._slideshow) return <Slideshow {...data} />
    */
    /*if (value.length===1) {
			if (fieldInfo.itemRenderer) return <div {...props}>{fieldInfo.itemRenderer(value[0], 0, data.language)}</div>
			const mime = value[0].mime?value[0].mime.split('/'):'image'
			if (mime[0]==='video') {
				return <video ref={domRef} className={className} autoPlay playsInline muted loop {...props}>
	                        <source src={realPath(value[0].url)} type={value[0].mime}/>
	                    </video>
			}
			if (mime[0]==='image') {
				if (fieldInfo.wrap) return <div className={className} ref={domRef}><LazyImage src={value[0]} {...props} {...imgProps} {...fieldInfo.widgetProps}/></div>
				return <LazyImage  ref={domRef} className={className} src={value[0]} {...props} {...imgProps} {...fieldInfo.widgetProps}/>
			}
			return null
		}*/
    //console.log(typeof children?.[2], children?.[2])
    const f = children?.[4]
    const childrenIsFunction = typeof f === "function"
    return (
        <>
            {val.map((img, i) => {
                if (childrenIsFunction) return f(img, i)

                if (fieldInfo.itemRenderer) return fieldInfo.itemRenderer(img, i)
                const mime = img.mime ? img.mime.split("/") : "image"
                if (mime[0] === "video")
                    return (
                        <video ref={domRef} className={className} autoPlay playsInline muted loop>
                            <source src={realPath(img.url)} type={img.mime} />
                        </video>
                    )

                if (wrap || fieldInfo.wrap)
                    return (
                        <div
                            key={i}
                            ref={domRef}
                            className={`${className || ""} img`}
                            style={style}
                        >
                            <LazyImage src={img} {...imgProps} {...props} />
                        </div>
                    )
                return (
                    <LazyImage
                        domRef={domRef}
                        key={i}
                        src={img}
                        className={className}
                        style={style}
                        {...imgProps}
                        {...props}
                    />
                )
            })}
        </>
    )
}

export default Img
