import React, { useState, useRef, useCallback, useMemo } from "react"
import { default as C } from "../conf"
import renderers from "./renderers"
import { useSubscriptionProvider } from "../hooks"
import { useIntersection } from "../intersection"
//import Delayed from "./Delayed"
import Entity from "../entity"

const sanitize = s => s.replace("/", "")
const getRenderer = fieldInfo =>
    renderers?.[fieldInfo?._renderer] ??
    renderers?.[fieldInfo?.renderer] ??
    renderers?.[fieldInfo?.typeName] ??
    renderers?.[fieldInfo?.name] ??
    (fieldInfo?.type !== fieldInfo?.typeName ? getRenderer(Entity.types[fieldInfo.type]) : null)

const useIndex = domRef => {
    const index = React.useRef()
    React.useEffect(() => {
        const updateIndex = () => {
            if (!domRef.current) return
            const ind = Array.from(domRef.current.parentNode.children).indexOf(domRef.current)
            if (ind === index.current) return
            index.current = ind
            domRef.current.style.setProperty("--index", ind)
        }
        requestAnimationFrame(updateIndex)
    })
}

const Field = props => {
    //console.log(props)
    const {
        domRef,
        info,
        field,
        tag,
        className,
        noadmin,
        rendererComp,
        renderer,
        _nowrap,
        _editParent,
        showEmpty,
        style,
        before,
        after,
        children,
        ...other
    } = props
    /*const lastProps = useRef({})
    React.useEffect(() => {
        let changed = []
        Object.keys(props).forEach(p => {
            if (lastProps.current[p] !== props[p]) {
                lastProps.current[p] = props[p]
                changed.push(p)
            }
        })
        if (changed.length > 0) console.log(changed, props)
    }, [props])*/
    const fieldBefore = before
    const fieldAfter = after
    const localRef = useRef()
    const localDomRef = domRef || localRef
    const mounted = useRef(true)
    const visible = useRef()
    const parents = useRef()
    const [localState, localSetState] = useState()
    const localInfo = useMemo(() => {
        if (!info) {
            console.log(`Field ${field} has no INFO.`)
            return {}
        }
        //console.log(info, field)
        const { user, entity, entityInfo, language, data } = info
        const parent = info.value
        const parentInfo = info.fieldInfo
        const fieldName = info.fieldName ? `${info.fieldName}.${field}` : field
        //console.log(fieldName, field, parent, parentInfo)
        let fieldInfo = parent
            ? Entity.getTypeInfo(field, parent, parentInfo)
            : Entity.getTypeInfo(fieldName, entity)

        let displayInfo =
            info.displayInfo?.[field] ??
            Entity.getFieldDisplayInfo(
                parent ?? entity,
                parentInfo ?? entityInfo,
                fieldInfo,
                info.displayInfo?.display,
                info.displayInfo?.region
            )
        /*if (displayInfo?.display)
            displayInfo = Entity.getFieldDisplayInfo(
                parent ?? entity,
                parentInfo ?? entityInfo,
                fieldInfo,
                displayInfo?.display,
                info.displayInfo?.region
            )*/
        const defaultLanguage = C.LANGUAGES ? entity?._lang ?? C.LANGUAGES[0] : null
        let rawValue = parent
            ? Entity.get(parent, field, { entityInfo: parentInfo, language, defaultLanguage })
            : Entity.get(entity, fieldName, { language, defaultLanguage })

        if (rawValue === undefined && fieldInfo && fieldInfo.default) rawValue = fieldInfo.default
        const value =
            fieldInfo && fieldInfo.prepareView ? fieldInfo.prepareView(rawValue) : rawValue

        const localStyle = style //{ ...(style ?? {}), "--index": index ?? 0 }
        const states = fieldInfo ? fieldInfo.states || info.states : info.states
        const state =
            fieldInfo && (fieldInfo.states || fieldInfo.stateful) ? localState : info.state
        const setState =
            fieldInfo && (fieldInfo.states || fieldInfo.stateful) ? localSetState : info.setState
        if (user && !noadmin && entity) {
            if (_editParent) {
                parents.current = [...(info.parents ? info.parents.current : [])]
                if (parents.current.length > 0)
                    parents.current[parents.current.length - 1] = Object.assign(
                        {},
                        parents.current[parents.current.length - 1],
                        { domRef: localDomRef }
                    )
            } else {
                parents.current = [
                    ...(info?.parents?.current ?? []),
                    {
                        domRef: localDomRef,
                        entity,
                        entityInfo,
                        fieldInfo,
                        field: fieldName,
                        states,
                        state,
                        setState,
                    },
                ]
            }
        }
        return {
            domRef: localDomRef,
            user,
            entity,
            entityInfo,
            displayInfo,
            language,
            fieldName,
            field,
            fieldInfo,
            value,
            parents,
            style: localStyle,
            states,
            state,
            setState,
            data,
        }
    }, [info, localState, localDomRef, noadmin, _editParent, field, localSetState, style])
    const { user, entity, fieldInfo, displayInfo, fieldName, value } = localInfo
    useIndex(localInfo.domRef)
    const setFieldAdmin = useRef(useSubscriptionProvider("fieldAdmin"))

    const mouseOver = useRef(false)
    const onMouseOver = useCallback(() => {
        if (mouseOver.current) return
        mouseOver.current = true
        setFieldAdmin.current({ mouse: "enter", fieldLine: parents.current })
    }, [])

    const onMouseLeave = useCallback(() => {
        window.requestAnimationFrame(() => {
            mouseOver.current = false
            setFieldAdmin.current({ mouse: "leave", fieldLine: parents.current })
        })
    }, [])

    const Renderer =
        (typeof props.renderer === "string" ? renderers?.[props.renderer] : null) ??
        (typeof displayInfo?.renderer === "string" ? renderers?.[displayInfo?.renderer] : null) ??
        getRenderer(fieldInfo)

    const onIntersect = useCallback(
        entry => {
            if (!mounted.current) return
            if (fieldInfo.onIntersect) fieldInfo.onIntersect(entry)

            if (!fieldInfo._visibility || visible.current || !entry.isIntersecting) return
            visible.current = true
            const ref = localDomRef.current
            const className =
                fieldInfo && fieldInfo.classVisible ? fieldInfo.classVisible : "visible"
            requestAnimationFrame(() => {
                ref.classList.add(className)
            })
        },
        [visible, fieldInfo, localDomRef]
    )

    useIntersection(
        fieldInfo && (fieldInfo._visibility || fieldInfo.onIntersect) && Renderer
            ? localDomRef
            : null,
        onIntersect
    )
    //console.log(fieldName, fieldInfo, Renderer, value, displayInfo, fieldInfo?.isEmpty?.(value))
    if (!fieldInfo) return null
    if (fieldInfo._hidden && !user) return null
    if (fieldInfo._invisible) return null
    if (displayInfo === false) return null
    if (!showEmpty && fieldInfo && fieldInfo.isEmpty && fieldInfo.isEmpty(value)) return null
    if (!Renderer) {
        console.log("Renderer not found for ", field, fieldInfo, info, displayInfo, value, entity)
        return null
    }

    if (
        info.states &&
        fieldInfo.state &&
        fieldInfo.state.indexOf(info.state || info.states[0].val) < 0
    )
        return null

    //console.log(fieldName, fieldInfo, Renderer, value)
    const wrapperProps = { style: localInfo.style }
    if (fieldInfo.id) wrapperProps.id = fieldInfo.id
    wrapperProps[`field-${fieldName.toLowerCase().split(".").pop()}`] = ""
    wrapperProps[`type-${sanitize((fieldInfo.typeName || fieldInfo.type).toLowerCase())}`] = ""

    let classes = []
    if (className) classes.push(className)
    if (fieldInfo._class) {
        classes.push(fieldInfo._class)
    }
    if (visible.current) classes.push(fieldInfo.classVisible || "visible")
    if (classes.length > 0) wrapperProps.className = classes.join(" ")
    if (user && !noadmin && entity) {
        wrapperProps.onMouseOver = onMouseOver
        wrapperProps.onMouseLeave = onMouseLeave
    }
    if (fieldInfo.ga) {
        if (!wrapperProps.style) wrapperProps.style = {}
        wrapperProps.style.gridArea = fieldInfo.ga
    }
    const renderField = props => {
        const { renderer, tag, before, after, _inner, _nowrap, ...rest } = displayInfo
        const Before = before ?? fieldBefore ?? fieldInfo?.before
        const After = after ?? fieldAfter ?? fieldInfo?.after

        const childFields = fieldInfo.renderChildren ? fieldInfo.fields ?? [] : []
        return children || childFields.length > 0 ? (
            <Renderer {...rest} {...props} info={localInfo} value={value}>
                {Before && <Before entity={entity} field={field} value={value} />}
                {childFields?.map((f, i) => (
                    <Field key={i} info={localInfo} field={f} />
                ))}
                {children}
                {After && <After entity={entity} field={field} value={value} />}
            </Renderer>
        ) : (
            <>
                {Before && <Before entity={entity} field={field} value={value} />}
                <Renderer {...rest} {...props} info={localInfo} value={value} />
                {After && <After entity={entity} field={field} value={value} />}
            </>
        )
    }

    if (_nowrap || fieldInfo._nowrap || displayInfo._nowrap) {
        return renderField({
            ...wrapperProps,
            ...other,
            domRef: localDomRef,
        })
    }
    const Tag = tag ?? displayInfo?.tag ?? "div"
    if (fieldInfo._inner2) {
        return (
            <Tag ref={localDomRef} has-inner2="" {...wrapperProps}>
                <div inner="">
                    <div inner2="">
                        {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                        {renderField(other)}
                    </div>
                </div>
            </Tag>
        )
    }
    if (fieldInfo._inner || displayInfo._inner) {
        return (
            <Tag ref={localDomRef} has-inner="" {...wrapperProps}>
                <div inner="">
                    {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                    {renderField(other)}
                </div>
            </Tag>
        )
    }
    return (
        <Tag ref={localDomRef} {...wrapperProps}>
            {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
            {renderField(other)}
        </Tag>
    )
}

export default React.memo(Field)
/*
    if (fieldInfo.delayed) {
        if (_nowrap || fieldInfo._nowrap) {
            return (
                <Delayed maxDelay={fieldInfo.maxDelay}>
                    {renderField({
                        ...wrapperProps,
                        ...other,
                        domRef: localDomRef,
                    })}
                </Delayed>
            )
        }
        const Tag = tag ?? displayInfo?.tag ?? "div"
        if (fieldInfo._inner2) {
            return (
                <Delayed maxDelay={fieldInfo.maxDelay}>
                    <Tag ref={localDomRef} has-inner2="" {...wrapperProps}>
                        <div inner="">
                            <div inner2="">
                                {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                                {renderField(other)}
                            </div>
                        </div>
                    </Tag>
                </Delayed>
            )
        }
        if (fieldInfo._inner || displayInfo._inner) {
            return (
                <Delayed maxDelay={fieldInfo.maxDelay}>
                    <Tag ref={localDomRef} has-inner="" {...wrapperProps}>
                        <div inner="">
                            {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                            {renderField(other)}
                        </div>
                    </Tag>
                </Delayed>
            )
        }
        return (
            <Delayed maxDelay={fieldInfo.maxDelay}>
                <Tag ref={localDomRef} {...wrapperProps}>
                    {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                    {renderField(other)}
                </Tag>
            </Delayed>
        )
    }
*/
