//import React from "react"
//import { realPath } from "lib"
//filter="url(#solid)"
/*const ContactMapItem = ({ item }) => {
    const [state, setState] = React.useState({ x: item.x, y: item.y })
    const rectRef = React.useRef()
    const textRef = React.useRef()
    React.useEffect(() => {
        requestAnimationFrame(() => {
            //const bbox = textRef.current.getBBox()
            const rect = textRef.current.getBoundingClientRect()
            //console.log(bbox, )
            setState(state => ({
                ...state,
                xr: item.x - rect.width * 0.7,
                yr: item.y - rect.height * 1.1,
                w: rect.width * 1.4,
                h: rect.height * 1.5,
            }))
        })
    }, [item])
    const handleMouseEnter = React.useCallback(() => {
        setState(state => ({ ...state, hover: true }))
    }, [])
    const handleMouseLeave = React.useCallback(() => {
        setState(state => ({ ...state, hover: false }))
    }, [])
    const { x, y, xr, yr, w, h, hover } = state
    return (
        <g
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ cursor: "pointer" }}
        >
            <rect
                ref={rectRef}
                x={xr}
                y={yr}
                width={w}
                height={h}
                fill={hover ? "#172d3f" : "white"}
                style={{ filter: "url(#drop-shadow)" }}
            />
            <text
                ref={textRef}
                x={x}
                y={y}
                textAnchor="middle"
                style={{
                    fill: hover ? "#ffffff" : "#172d3f",
                    fontFamily: "Khand, sans-serif",
                    fontSize: 20,
                    fontWeight: 600,
                }}
            >
                {item.label.toUpperCase()}
            </text>
        </g>
    )
}*/
/*
const ContactMap1 = ({ domRef, info, value, children, ...props }) => {
    //console.log(value)
    const w = value?.img?.[0]?.w
    const h = value?.img?.[0]?.h
    return (
        <div ref={domRef} {...props}>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox={`0 0 ${w} ${h}`}
                xmlSpace="preserve"
            >
                <defs>
                    <filter id="shadow">
                        <feDropShadow dx="0.2" dy="0.4" stdDeviation="0.2" />
                    </filter>
                    <filter id="drop-shadow" x="-100%" y="-100%" width="300%" height="300%">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                        <feOffset dx="1" dy="1" result="offsetblur" />
                        <feFlood floodColor="#aaaaaa" />
                        <feComposite in2="offsetblur" operator="in" />
                        <feMerge>
                            <feMergeNode />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    <filter x="-0.1" y="-0.1" width="1.2" height="1.2" id="solid">
                        <feFlood floodColor="#ffffff" />
                        <feComposite in="SourceGraphic" operator="over" />
                        <feDropShadow dx="0.2" dy="0.4" stdDeviation="0.2" />
                    </filter>
                </defs>
                <image width={w} height={h} xlinkHref={realPath(value?.img?.[0]?.url)} />
                {value?.items?.map((item, i) => (
                    <ContactMapItem key={i} item={item} />
                ))}
            </svg>
        </div>
    )
}*/
const ContactMap = ({ domRef, info, value, children, ...props }) => {
    return null
}
export default ContactMap
