import React from "react"
import { realPath, useScreen } from "lib"
// 200x62
// header: lg, xl 155; rest 125
// footer: 80vw, max: 150
const headerLg = {
    width: 155,
    height: (155 * 62) / 200,
}
const headerDefault = {
    width: 125,
    height: (125 * 62) / 200,
}
const getAttributes = (type, screen) => {
    switch (type) {
        case "header":
            if (screen.W === 0 || screen.MQ === "lg" || screen.MQ === "xl") return headerLg
            return headerDefault
        case "footer": {
            const width = screen.W === 0 ? 150 : Math.min((screen.W * 80) / 100, 150)
            return {
                width,
                height: (width * 62) / 200,
            }
        }
        default:
            return {}
    }
}
const useLogo = type => {
    const screen = useScreen()
    return React.useMemo(() => getAttributes(type, screen), [screen, type])
}

const logo = realPath("/img/wintercom-logo-complet-orange-grey-tr-1.svg")
const Logo = ({ type }) => {
    const attrs = useLogo(type)
    return <img src={logo} alt="WINTERCOM" {...attrs} />
}

export default Logo
