import React from "react"
import { History } from "../history"
import { useSubscriptionProvider, FaIcon } from "lib"

const Link = React.forwardRef(
    (
        {
            domRef,
            fieldInfo,
            to,
            nocb,
            children,
            onActivate,
            message,
            iconBefore,
            iconAfter,
            ...props
        },
        ref
    ) => {
        const [anExternalLink, link] = React.useMemo(() => {
            if (!to || typeof to !== "string") return [true, to]
            const anExternalLink = !(to.startsWith("/") || to.startsWith("#"))
            if (anExternalLink) return [anExternalLink, to]
            let link = to === "/" ? "/index.html" : /\.[a-z0-9]{1,4}/.test(to) ? to : `${to}.html`
            return [anExternalLink, link]
        }, [to])
        const sendMessage = useSubscriptionProvider()
        const handleClick = React.useCallback(
            e => {
                const aNewTab = e.metaKey || e.ctrlKey
                if (!link) return

                const m = message ?? fieldInfo?.message
                if (m) sendMessage(m)

                if (!aNewTab && !anExternalLink) {
                    if (onActivate) {
                        e.preventDefault()
                        if (onActivate(e, link)) {
                            History.push(link, { nocb })
                        }
                    } else {
                        e.preventDefault()
                        History.push(link, { nocb })
                    }
                }
            },
            [fieldInfo, message, nocb, onActivate, sendMessage, anExternalLink, link]
        )

        return (
            <a ref={domRef ?? ref} href={link} onClick={handleClick} {...props}>
                {iconBefore && <FaIcon icon={iconBefore} />}
                {children}
                {iconAfter && <FaIcon icon={iconAfter} />}
            </a>
        )
    }
)

export default Link
