import C from "../conf"
import { Query } from "../query"

export const updateStyle = style => {
    //console.log("update style", style)
    const links = document.getElementsByTagName("link")
    for (let i in links) {
        const link = links[i]
        if (
            !link ||
            !link.getAttribute ||
            link.getAttribute("rel") !== "stylesheet" ||
            link.href.indexOf(C.BASE) < 0
        )
            continue

        if (link.href.indexOf("css/site") >= 0 && style) link.href = style
    }
}

const reCSS = /\.css$/
/*
const printStorage = async () => {
    const storage = navigator?.storage
    if (!storage) return
    const estimate = await storage.estimate()
    console.log("storage:", estimate)
}*/
export const initSW = () => {
    //if (typeof navigator === "undefined") return
    const sw = navigator?.serviceWorker
    if (!sw) return
    //printStorage()
    //console.log("add service worker listener")
    sw.addEventListener("message", async event => {
        // Optional: ensure the message came from workbox-broadcast-update
        //console.log("sw event", event)
        if (event.data.meta === "workbox-broadcast-update") {
            const { type, key } = event.data.payload
            if (type === "query") {
                //console.log("SW CALL REFRESH", key)
                Query.refresh(key)
            }
            if (type === "app" && reCSS.test(key)) {
                //console.log("update style", key)
                updateStyle(key)
            }
            /*
                const { cacheName, updatedURL } = event.data.payload
                //console.log(event, cacheName, updatedURL)
                // Do something with cacheName and updatedUrl.
                // For example, get the cached content and update
                // the content on the page.
                const cache = await caches.open(cacheName)
                const updatedResponse = await cache.match(updatedURL)
                //const updatedText = await updatedResponse.()
                //console.log(cacheName, updatedURL, updatedResponse.url)
                if (cacheName === "query") {
                    Query.refresh()
                }
                if (cacheName === "app" && reCSS.test(updatedURL)) {
                    updateStyle(updatedResponse.url)
                }
                */
        }
    })
}
