const BLOCK_TAGS = {
    paragraph: "p",
    "heading-one": "h1",
    "heading-two": "h2",
    "heading-three": "h3",
    "heading-four": "h4",
    "heading-five": "h5",
    "heading-six": "h6",
    "bulleted-list": "ul",
    "numbered-list": "ol",
    "list-item": "li",
    quote: "blockquote",
    code: "pre",
    "d-list": "dl",
    "d-term": "dt",
    "d-descr": "dd",
    table: "table",
    tr: "tr",
    th: "th",
    td: "td",
    thead: "thead",
    tbody: "tbody",
    div: "div",
}
const MARK_TAGS = {
    bold: "strong",
    italic: "em",
    underline: "u",
    sub: "sub",
    sup: "sup",
}
const EXTRA_MARKS = {
    b: "bold",
    i: "italic",
}
export { BLOCK_TAGS, MARK_TAGS, EXTRA_MARKS }
