import React from "react"
import ReactDOM from "react-dom"
//import TransitionGroup from "react-transition-group/TransitionGroup"
import CSSTransition from "react-transition-group/CSSTransition"
import { useUnmounted, FaIcon, useHotkey } from "lib"

const Close = ({ onClick }) => {
    return (
        <div className="modal-close-button" onClick={onClick}>
            <FaIcon icon="navigation/cancel" />
        </div>
    )
}

const Modal = ({ open, showCloseButton, id, className, onClose, onExited, children }) => {
    const [state, setState] = React.useState({ realize: open })
    const unmounted = useUnmounted()
    const { realize } = state
    const t = React.useRef()
    const ref = React.useRef()
    const refAttributes = React.useRef({ id, className })
    const handleExited = React.useCallback(() => {
        if (onExited) onExited()
    }, [onExited])
    const handleClose = React.useCallback(() => {
        if (onClose) onClose()
    }, [onClose])
    React.useEffect(() => {
        if (open) {
            if (t.current) {
                clearTimeout(t.current)
                t.current = 0
            }
            document.body.classList.add("modal-open")
        } else {
            document.body.classList.remove("modal-open")

            t.current = setTimeout(() => {
                if (unmounted.current) return
                setState(state => ({ ...state, realize: false }))
            }, 2000)
        }
        setState(state => ({ ...state, realize: state.realize || open }))
    }, [open, unmounted])
    useHotkey("Escape", handleClose)
    React.useEffect(() => {
        let div
        if (realize && !unmounted.current) {
            div = document.createElement("div")
            Object.keys(refAttributes.current).forEach(attr => {
                const value = refAttributes.current[attr]
                if (!value) return
                if (attr === "className") div.classList.add(...value.split(" "))
                else div.setAttribute(attr, value)
            })
            document.body.appendChild(div)
            ref.current = div
            setState(state => ({ ...state }))
        }
        return () => {
            //console.log("effect out", div)
            if (div) div.remove()
        }
    }, [realize, unmounted])
    if (!ref.current) return null
    return ReactDOM.createPortal(
        <CSSTransition
            key="modal"
            in={open}
            classNames="modal"
            timeout={1000}
            appear={true}
            onExited={handleExited}
        >
            <div className="modal">
                <div className="overlay" onClick={handleClose} />
                <div className="modal-content">
                    <div className="modal-content-in">
                        {children}
                        {showCloseButton && <Close onClick={handleClose} />}
                    </div>
                </div>
            </div>
        </CSSTransition>,
        ref.current
    )
}
//        document.getElementById("modal-container")

export default Modal
