import React from "react"
import axios from "axios"
import { C, useUnmounted } from "lib"
//const cache = {}
let iconInstance
const isSSR = typeof window === "undefined"
const retrieveIcon = async icon => {
    if (!iconInstance) iconInstance = axios.create({ baseURL: C.BASE })
    let p
    try {
        p = iconInstance.get(`/icons/${icon}.json`)
        if (!p) return null

        //if (isSSR) {
        //global.storePromises.push(p)
        //global.storeQueries.push("icon")
        //}
        const response = await p
        if (response.status !== 200) return null
        //console.log(response)
        const iconData = response.data
        if (isSSR) {
            if (!global.iconStore) global.iconStore = {}
            global.iconStore[icon] = iconData
        }
        return iconData
    } catch (e) {
        console.log(e)
    }
    return null
}
const getIcon = icon => {
    if (!icon) return null
    const iconData = isSSR ? global.iconStore?.[icon] : window.iconStore?.[icon]

    if (iconData) return iconData
    return retrieveIcon(icon)
}
const useIcon = icon => {
    const [iconData, setIconData] = React.useState(() => getIcon(icon))
    const unmounted = useUnmounted()
    const init = React.useRef(icon)
    React.useEffect(() => {
        if (init.current === icon) return
        init.current = icon
        setIconData(getIcon(icon))
    }, [icon])
    React.useEffect(() => {
        const waitForData = async () => {
            const origIcon = icon
            const data = await iconData
            if (unmounted.current || !data) return
            if (!window.iconStore) window.iconStore = {}
            window.iconStore[origIcon] = data
            if (icon === origIcon) setIconData(data)
        }
        if (iconData instanceof Promise) waitForData()
    }, [unmounted, iconData, icon])

    return iconData instanceof Promise ? null : iconData
}

const FaIcon = ({ domRef, icon, size, ...props }) => {
    const iconData = useIcon(icon)
    if (!iconData) return null
    const [width, height, , , path] = iconData
    let style = {}
    if (size) {
        style = {
            width: `${size}px`,
            height: `${size}px`,
        }
    }

    return (
        <svg
            ref={domRef}
            style={style}
            viewBox={`0 0 ${width} ${height}`}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path fill="currentColor" d={path} />
        </svg>
    )
}
export default FaIcon
