import React from "react"
/*import ProductModelViewer from "./ProductModelViewer"
const Product3DModel = props => {
    return <ProductModelViewer {...props} />
}*/

const ProductModel = React.lazy(() =>
    import("./ProductModel").then(module => ({ default: module.ProductModel }))
)

const Product3DModel = props => {
    const [isHydrating, trigger] = React.useState(
        typeof window === "undefined" ? false : window?.isHydrating
    )
    React.useEffect(() => {
        if (!isHydrating) return
        trigger(false)
    }, [isHydrating])
    if (typeof window === "undefined" || isHydrating) return null
    return (
        <React.Suspense fallback={null}>
            <ProductModel {...props} />
        </React.Suspense>
    )
}

export default Product3DModel
