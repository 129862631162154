import { display } from "conf/display"
export const getDisplayInfo = (entity, entityInfo, displayName, region = "content") =>
    region === "content"
        ? {
              ...display.default,
              ...display?.[entityInfo?.type],
              ...display?.[entityInfo?.typeName],
              ...display?.[displayName],
          }
        : {
              ...display.default,
              ...display?.[entityInfo?.type]?.[region],
              ...display?.[entityInfo?.typeName]?.[region],
              ...display?.[displayName]?.[region],
          }
export const getFieldDisplayInfo = (entity, entityInfo, field, displayName, region) => {
    const displayInfo = getDisplayInfo(entity, entityInfo, displayName, region)

    const fieldDisplayInfo =
        displayInfo?.[field?.name] ??
        displayInfo?.[field?.typeName] ??
        displayInfo?.[field?.type] ??
        {}
    return fieldDisplayInfo === true ? {} : fieldDisplayInfo
}
