import React from "react"
import { FaIcon, useSubscriptionProvider } from "lib"

const MobileMenuButton = () => {
    const openMobileMenu = useSubscriptionProvider("open_mobile_menu")
    const handleOpen = React.useCallback(() => {
        openMobileMenu()
    }, [openMobileMenu])
    return (
        <div id="mobile-menu-button" onClick={handleOpen}>
            <FaIcon icon="faBars" />
        </div>
    )
}
export default MobileMenuButton
