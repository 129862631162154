//import { C, getImageUrl, Entity } from "lib"

export const nodeTypes = {
    homepage: {
        label: "Acasă",
        _noCreate: true,
        /*ld: {
            title: "Wintercom",
        },*/
    },
    page: {
        label: "Pagină",
        info: "Pagină generică",
        searchField: "title",
        /*ld: {
            "@type": "HomeAndConstructionBusiness",
        },*/
    },
    article: {
        label: "Articol",
        info: "Articol",
        searchField: "title",
    },
    /*manufacturer: {
        label: "Producător",
        searchField: "title",
    },*/
    category: {
        label: "Categorie",
        searchField: "title",
        getLabel: e => (e.manufactured ? `${e.title} (F)` : `${e.title} (D)`),
    },
    product: {
        label: "Produs",
        info: "Produs",
        searchField: "title",
        /*ld: product => ({
            "@type": "Product",
            name: product.title,
            //description: "{description}",
            image: product.img?.length > 0 ? getImageUrl(product.img[0], "og") : undefined,
            url: C.BASE + Entity.getPath(product) + ".html",
        }),*/
    },
    error: {
        collection: null,
        label: "Pagină inexistentă",
        _noCreate: true,
    },
}

export const entityTypes = {
    /*fitting: {
        label: "Fitting",
        collection: "fitting",
        getLabel: e => `${e?.title} ${e?.version ?? ""}`,
    },*/
    menu: {
        label: "Meniu",
        collection: "menu",
        getLabel: e => e.label,
        _noCreate: true,
    },
    footer: {
        label: "Footer",
        collection: "site",
    },
    bg: {
        label: "Fundal",
        collection: "bg",
    },
    header: {
        label: "Header",
        collection: "site",
        _noCreate: true,
    },
    ld: {
        label: "Linked Data",
        collection: "ld",
        //getLabel: e => e.label,
        //_noCreate: true,
    },
    /*importData: {
        label: "Import",
        collection: "data",
        getLabel: e => "Import date",
    },*/
}
