import React from "react"
import { renderVideo } from "../../util"
import { Entity, realPath } from "lib"
import Modal from "../Modal"
const videoSettings = ["controls", "playsInline", "muted", "autoPlay", "loop"]

const VideoFile = ({ domRef, info, value, ...props }) => {
    const videoRef = React.useRef()
    const [paused, setPaused] = React.useState(true)
    const [lightboxOpen, setLightboxOpen] = React.useState(true)
    const [videoProps, rest, lightbox] = React.useMemo(() => {
        const { videoProps, ...rest } = props
        let vprops = {}
        Entity.getSettings(info.fieldInfo).forEach(s => {
            if (!s.default || !videoSettings.includes(s.name)) return
            vprops[s.name] = s.default
        })

        vprops = { ...vprops, ...videoProps }
        videoSettings.forEach(s => {
            if (info.fieldInfo[s] !== undefined) vprops[s] = info.fieldInfo[s]
        })
        Object.keys(vprops).forEach(p => {
            if (vprops[p] === true || vprops[p] === 1) vprops[p] = true
            if (vprops[p] === false || vprops[p] === 0) delete vprops[p]
        })
        const poster = info.fieldInfo?.poster?.[0]?.url
        if (poster) vprops["poster"] = realPath(poster)
        return [vprops, rest, info.fieldInfo?.lightbox]
    }, [props, info])
    const togglePlay = React.useCallback(() => {
        if (!lightbox) {
            if (videoRef.current.paused || videoRef.current.ended) videoRef.current.play()
            else videoRef.current.pause()
        } else {
            setLightboxOpen(true)
        }
        setPaused(paused => !paused)
    }, [lightbox])
    const onClose = React.useCallback(() => {
        if (lightbox) {
            setLightboxOpen(false)
            setTimeout(() => {
                setPaused(true)
            }, 1000)
        } else setPaused(true)
    }, [lightbox])
    const lightboxPlay = lightbox && !paused
    //console.log(videoProps, rest, info, value)
    if (!value) return null
    return (
        <div ref={domRef} {...rest}>
            {lightbox ? (
                <img src={videoProps.poster} alt="" onClick={togglePlay} />
            ) : (
                <video ref={videoRef} {...videoProps}>
                    {value.map((v, i) => (
                        <source key={i} src={realPath(v.url)} type="video/mp4" />
                    ))}
                </video>
            )}
            {paused && (
                <img
                    src="/img/sb-play-6.svg"
                    alt="play"
                    className="video-play"
                    onClick={togglePlay}
                />
            )}
            {lightboxPlay && (
                <Modal
                    open={lightboxOpen}
                    className="lightbox-container"
                    onClose={onClose}
                    showCloseButton={true}
                >
                    <video ref={videoRef} {...videoProps} autoPlay={true}>
                        {value.map((v, i) => (
                            <source key={i} src={realPath(v.url)} type="video/mp4" />
                        ))}
                    </video>
                </Modal>
            )}
        </div>
    )
}

const Video = props => {
    const { domRef, info, value, ...videoProps } = props
    if (!value) return null
    //console.log(props)
    if (
        (info.fieldInfo.type === "text" && typeof value !== "string") ||
        (info.fieldInfo.type === "file" && !Array.isArray(value))
    ) {
        console.log("Video format not OK", value)
        return null
    }

    return info.fieldInfo.type === "text" ? (
        <div ref={domRef} {...videoProps}>
            {renderVideo(value, {
                "data-show-text": false,
                "data-show-captions": false,
            })}
        </div>
    ) : (
        <VideoFile {...props} />
    )
}

export default Video
