import React from "react"

const CarouselContext = React.createContext({})
export const useCarousel = () => React.useContext(CarouselContext)

export const useCarouselState = () => {
    const carousel = useCarousel()
    const [, setState] = React.useState({ ...carousel.state })
    React.useEffect(() => {
        carousel.onChange(() => {
            setState({ ...carousel.state })
        })
    }, [carousel])
    return carousel
}
//items, active, config
const useCarouselProvider = (ref, initialActive) =>
    React.useMemo(() => {
        const moveCb = []
        const changeCb = []
        const state = {
            ref,
            initialActive,
            n: 0,
            active: -1,
            last: -1,
            dir: "next",
        }
        const move = () => {
            for (const cb of moveCb) {
                requestAnimationFrame(() => {
                    cb()
                })
            }
        }
        const change = () => {
            for (const cb of changeCb) {
                requestAnimationFrame(() => {
                    cb()
                })
            }
        }
        return {
            state,
            set: (key, value) => {
                state[key] = value
            },
            onMove: cb => {
                moveCb.push(cb)
            },
            onChange: cb => {
                changeCb.push(cb)
            },
            trigger: e => {
                switch (e) {
                    case "move":
                        return move()
                    default:
                        return change()
                }
            },
            prev: e => {
                if (e) {
                    if (e.stopPropagation) e.stopPropagation()
                    if (e.currentTarget?.dataset?.disabled === "") return
                }
                state.last = state.active
                state.active = state.active <= 0 ? state.n - 1 : state.active - 1
                state.dir = "prev"
                move()
            },
            next: e => {
                if (e) {
                    //console.log(e)
                    if (e.stopPropagation) e.stopPropagation()
                    if (e.currentTarget?.dataset?.disabled === "") return
                }
                state.last = state.active
                state.active = state.active >= state.n - 1 ? 0 : state.active + 1
                state.dir = "next"
                move()
            },
            go: e => {
                if (e) e.stopPropagation()
                const index = parseInt(e.currentTarget.dataset.index, 10)
                if (index === state.active) return
                state.last = state.active
                state.active = index
                state.dir = index < state.active ? "prev" : "next"
                move()
            },
            goto: index => {
                if (index === state.active) return
                state.last = state.active
                state.active = index
                state.dir = index < state.active ? "prev" : "next"
                move()
            },
        }
    }, [ref, initialActive])

const Carousel = ({ domRef, initialActive, children, ...props }) => {
    const ref = React.useRef()
    const carouselRef = domRef ?? ref
    const carousel = useCarouselProvider(carouselRef, initialActive)
    //console.log(props, state, currentStyle)
    return (
        <div ref={carouselRef} carousel="" {...props}>
            <CarouselContext.Provider value={carousel}>
                {typeof children === "function" ? children(carousel) : children}
            </CarouselContext.Provider>
        </div>
    )
}
/*
const Carousel = ({ domRef, items, active, widget, config, children, ...attrs }) => {
    const state = useCarouselProvider(items, active, config)
    //console.log(state.state)
    const args = {}
    if (widget) args[widget] = state.state?.type ?? "single"
    else args.carousel = state.state?.type ?? "single"
    if (config?.flexible) args["flexible"] = ""

    return (
        <div ref={domRef} {...args} {...attrs}>
            <CarouselContext.Provider value={state}>
                {typeof children === "function" ? children(state) : children}
            </CarouselContext.Provider>
        </div>
    )
}
*/
export default Carousel
