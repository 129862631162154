import React from "react"
import {
    isHydrating,
    isSSR,
    Entity,
    Link,
    useQuery,
    FaIcon,
    useScreen,
    requestIdleCallback,
} from "lib"

//const isSSR = typeof window === "undefined"
const screenSizes = ["md", "lg", "xl"]
const MenuEntityLink = ({ item, language, titleField, pathField, onActivate }) => {
    const entityInfo = Entity.getTypeInfo(item)
    const options = {
        language,
        defaultLanguage: item._lang ?? "ro",
        entityInfo,
    }
    const title = Entity.get(item, titleField, options)
    const path =
        pathField === "path" ? Entity.getPath(item, language) : Entity.get(item, pathField, options)
    return (
        <Link to={path} onActivate={onActivate}>
            {title}
        </Link>
    )
}

const MenuItemsView = ({ items, itemsInfo, options, language, ...props }) => {
    const [query, titleField, pathField] = React.useMemo(() => {
        if (!itemsInfo) return [null, null, null]
        let titleField = items.title ? items.title : "title"
        let pathField = items.p ? items.p : "path"
        let q = {}
        if (items.collection) q.collection = items.collection
        q.projection = { _lang: 1, _i18n: 1, type: 1 }
        q.projection[titleField] = 1
        q.projection[pathField] = 1
        try {
            q.query = JSON.parse(items.query)
        } catch (e) {}
        try {
            q.sort = JSON.parse(items.sort)
        } catch (e) {}
        return [q, titleField, pathField]
    }, [items, itemsInfo])
    const [itemsResults] = useQuery(query)
    if (!itemsResults) return null
    return itemsResults?.map((item, i) => (
        <li key={i}>
            <MenuEntityLink
                key={i}
                titleField={titleField}
                pathField={pathField}
                item={item}
                language={language}
                {...props}
            />
        </li>
    ))
}

const MenuLink = ({ item, itemInfo, options, level, onActivate }) => {
    //console.log(item, itemInfo)
    const itemOptions = { ...options, entityInfo: itemInfo }
    const title = Entity.get(item, "title", itemOptions)
    const path = Entity.get(item, "p", itemOptions)

    return (
        <Link to={path} onActivate={onActivate}>
            {title}
            {level === 1 && <FaIcon icon="filled/arrow_right_alt" size={24} />}
        </Link>
    )
}
const MenuItemOpener = ({ items, itemsInfo, item, index, level, ...props }) => {
    const [open, setOpen] = React.useState()
    //const ric = React.useRef()
    const itemInfo = Entity.getTypeInfo(`${index}`, items, itemsInfo)

    React.useEffect(() => {
        if (open) document.body.classList.add("main-menu-open")
        else document.body.classList.remove("main-menu-open")
    }, [open])
    /*const handleMouseOver = React.useCallback(() => {
        setOpen(true)
        if (ric.current) {
            clearTimeout(ric.current)
            ric.current = 0
        }
    }, [])
    const handleMouseOut = React.useCallback(e => {
        ric.current = setTimeout(() => {
            setOpen(false)
        }, 100)
    }, [])*/
    const handleClose = React.useCallback(e => {
        document.body.classList.remove("main-menu-open")
        setTimeout(() => {
            setOpen(false)
        }, 500)
        return true
    }, [])
    const handleOpenerClick = React.useCallback(() => {
        setOpen(open => !open)
        return false
    }, [])
    //onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
    return (
        <li>
            <MenuLink
                item={item}
                itemInfo={itemInfo}
                level={level}
                onActivate={handleOpenerClick}
                {...props}
            />
            <div className="main-menu-overlay" onClick={handleClose} />
            <MenuItems
                entity={item}
                entityInfo={itemInfo}
                level={level + 1}
                onActivate={handleClose}
                {...props}
            />
        </li>
    )
}
const MenuItem = ({ items, itemsInfo, item, index, level, ...props }) => {
    const itemInfo = Entity.getTypeInfo(`${index}`, items, itemsInfo)

    return level === 0 && item.items ? (
        <MenuItemOpener
            items={items}
            itemsInfo={itemsInfo}
            item={item}
            index={index}
            level={level}
            {...props}
        />
    ) : (
        <li>
            <MenuLink item={item} itemInfo={itemInfo} level={level} {...props} />
            <MenuItems entity={item} entityInfo={itemInfo} level={level + 1} {...props} />
        </li>
    )
}

const MenuItemsList = ({ items, itemsInfo, ...props }) =>
    items?.map((item, i) => (
        <MenuItem key={i} items={items} itemsInfo={itemsInfo} item={item} index={i} {...props} />
    )) ?? null

const MenuItems = ({ entity, entityInfo, ...props }) => {
    const itemsInfo = Entity.getTypeInfo("items", entity, entityInfo)
    const type = itemsInfo?._type ?? "menuItemsList"
    const options = { ...props.options, entityInfo }
    const items = Entity.get(entity, "items", options)
    if (!items) return null
    return (
        <div menu-level={props.level}>
            <ul>
                {type === "menuItemsList" ? (
                    <MenuItemsList items={items} itemsInfo={itemsInfo} {...props} />
                ) : (
                    <MenuItemsView items={items} itemsInfo={itemsInfo} {...props} />
                )}
            </ul>
        </div>
    )
}

const MainMenuInner = ({ menu, language }) => {
    const entityInfo = Entity.getTypeInfo(menu)
    const defaultLanguage = menu?._lang ?? "ro"
    const options = { language, defaultLanguage, entityInfo }

    return (
        <nav id="main-menu">
            <MenuItems
                entity={menu}
                entityInfo={entityInfo}
                options={options}
                level={0}
                language={language}
            />
        </nav>
    )
}
const MainMenu = props => {
    const screen = useScreen()
    const [hydrating, setHydrating] = React.useState(isHydrating())
    React.useEffect(() => {
        if (!hydrating) return
        requestIdleCallback(() => {
            setHydrating(false)
        })
    }, [hydrating])
    const show = React.useMemo(() => isSSR || hydrating || screenSizes.includes(screen.MQ), [
        hydrating,
        screen,
    ])
    if (!show) return null
    return <MainMenuInner {...props} />
}
export default MainMenu
