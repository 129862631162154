export const display = {
    default: {
        _seo: false,
        title: {
            tag: "h1",
        },
        //video: false,
        thumb: false,
        summary: false,
        state: false,
        path_info: false,
        manufactured: false,
        order: false,
        bool: false,
    },
    details: {
        gltf: false,
        _layout: [["header", ["title", "title", "version"], "scheme", "std"], "variants"],
    },
    category_link: {
        breadcrumbs: false,
        title: { link: true, message: "close-menus" },
        img: false,
        "category-products": false,
        body: false,
    },
    category: {
        img: { renderer: "ImageCards", _nowrap: true },
        _layout: ["breadcrumbs", "img", "title", "body", "category-products"],
    },
    article: {
        _layout: ["header", "body"],
    },
    "article-teaser": {
        _layout: ["promo"],
    },
    cat: {
        title: { link: true },
        _layout: ["title", "body"],
    },
    product: {
        manufactured: false,
        title: {
            tag: "div",
        },
        img: { lightbox: true, imageStyle: "product" },
        variants: true,
        gltf: false,
        scheme: { lightbox: true, _inner: true, imageStyle: "product" },
        //_layout: [["main", [["content", "breadcrumbs", "title", "variants"]], "img"], "similar"],
        _layout: [["main", "breadcrumbs", "title", "img", "variants"], "similar"],
    },
    fVariant: {
        label: false,
        image: false,
        scheme: false,
    },
    link: {
        label: false,
        path: false,
    },
    "product-teaser": {
        manufactured: false,
        cat: false,
        title: {
            tag: "div",
            iconAfter: "filled/add_circle_outline",
            iconBefore: "filled/arrow_right_alt",
        },
        img: { renderer: "img", limit: 1, imageStyle: "product-teaser" },
        variants: false,
        gltf: false,
        scheme: false,

        _layout: ["img", "title"],
    },
    /*teaser: {
        title: { link: true },
        subtitle: true,
        summary: true,
        thumb: true,
        thumb_front: true,
        date: true,
        firstName: true,
        lastName: true,
        year: true,
    },
    */
    embed: {
        label: false,
    },
    footer: {
        index: false,
    },
}
