import React from "react"
import { useQuery, EntityView, Link, Entity, Carousel, Arrows, Navigator, Track } from "lib"

const useSimilar = entity => {
    const q = React.useMemo(
        () => ({
            query: { "cat.ref": entity?.cat?.ref },
            sort: { order: 1 },
            limit: 10,
        }),
        [entity]
    )
    const [data] = useQuery(q)
    return React.useMemo(() => {
        const id = Entity.getId(entity)
        return data?.filter(p => id !== Entity.getId(p))
    }, [entity, data])
}
const title = {
    ro: "Produse similare",
    en: "Similar products",
}
//const carouselConfig = { autoSize: true }
const Similar = ({ domRef, info, index, value, ...props }) => {
    const { entity } = info
    const similar = useSimilar(entity)
    if (!similar || similar.length === 0) return null
    return (
        <div ref={domRef} {...props}>
            <h2>{title?.[info.language]}</h2>
            <Carousel>
                <Track>
                    {similar.map((p, i) => (
                        <EntityView
                            key={i}
                            info={info}
                            entity={p}
                            display="product-teaser"
                            tag={Link}
                            to={Entity.getPath(p, info.language)}
                        />
                    ))}
                </Track>
                <Arrows />
                <Navigator />
            </Carousel>
        </div>
    )
}
//carousel => items={similar} config={carouselConfig}
export default Similar
