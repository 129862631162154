import React from "react"
import { realPath, useQuery, requestIdleCallback, isSSR, isHydrating, isBrowser } from "lib"

const q = { collection: "bg", query: {} }
const useBg = () => {
    const [data] = useQuery(q)
    return React.useMemo(() => {
        const url = data?.[0]?.mov?.[0]?.url
        return url ? realPath(url) : url
    }, [data])
}

const Bg = () => {
    const [hide, setHide] = React.useState(isSSR || (isBrowser && isHydrating()))
    const bg = useBg()
    React.useEffect(() => {
        if (!hide) return
        requestIdleCallback(() => {
            setHide(false)
        })
    }, [hide])
    if (hide || !bg) return null

    return (
        <div id="site-bg">
            <video muted loop autoPlay playsInline>
                <source src={bg} type="video/mp4" />
            </video>
        </div>
    )
}
export default React.memo(Bg)
