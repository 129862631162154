import React, { useContext } from "react"
import FormFieldText from "./FormFieldText"
import FormFieldTextarea from "./FormFieldTextarea"
import FormFieldCaptcha from "./FormFieldCaptcha"
import FormFieldSubmit from "./FormFieldSubmit"
import { FormContext } from "./Form"

const formFields = {
    text: FormFieldText,
    email: FormFieldText,
    tel: FormFieldText,
    textarea: FormFieldTextarea,
    captcha: FormFieldCaptcha,
    submit: FormFieldSubmit,
}

const FormField = props => {
    const [state, dispatch] = useContext(FormContext)
    const fieldValue = state.data[props.info.field] ? state.data[props.info.field].value : null
    const Element = props.value ? formFields[props.value.fieldType] : null
    return Element ? <Element fieldValue={fieldValue} dispatch={dispatch} {...props} /> : null
}
export default FormField
