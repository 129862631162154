import React from "react"
import { isBrowser } from "lib"
let observer
const resizeHandler = (entries, observer) =>
    requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) return
        for (let entry of entries) {
            if (entry.target.resizeHandler) entry.target.resizeHandler(entry, observer)
        }
    })

export const useResizeObserver = (ref, cb) => {
    if (isBrowser && !observer) {
        observer = new ResizeObserver(resizeHandler)
    }
    React.useEffect(() => {
        const elem = ref?.current ?? ref
        if (!elem) return
        elem.resizeHandler = cb
        observer.observe(elem)
        return () => {
            observer.unobserve(elem)
        }
    }, [ref, cb])
}
