import React from "react"
import {
    Entity,
    Link,
    FaIcon,
    useQuery,
    useScreen,
    useSubscription,
    requestIdleCallback,
    isSSR,
    isBot,
    isHydrating,
} from "lib"
import LanguageSelector from "../header/LanguageSelector"

//const isSSR = typeof window === "undefined"
const screenSizes = ["xss", "xs", "sm"]
const initialState = { open: false, level: 0, levels: [] }
const reducer = (state, action) => {
    switch (action.type) {
        case "TOGGLE":
            if (state.open) return { ...state, open: !state.open }
            else return { ...state, open: !state.open, level: 0, levels: [] }
        case "CLOSE":
            return { ...state, open: false }
        case "OPEN":
            return { ...state, open: true, level: 0, levels: [] }
        case "ACTIVATE": {
            const level = state.level + 1
            return {
                ...state,
                level,
                levels: [...state.levels.slice(0, level - 1), action.data],
            }
        }
        case "BACK":
            return {
                ...state,
                level: state.level - 1,
            }
        default:
            return state
    }
}

const MenuLink = ({ item, itemInfo, options, language, level, state, dispatch, isTitle }) => {
    //console.log(item, itemInfo)
    const itemOptions = { ...options, entityInfo: itemInfo }
    const title = Entity.get(item, "title", itemOptions)
    const path = Entity.get(item, "p", itemOptions)
    const handleActivate = React.useCallback(() => {
        if (!isTitle && item.items) {
            dispatch({ type: "ACTIVATE", data: { item, itemInfo } })
            return false
        } else {
            dispatch({ type: "CLOSE" })
            return true
        }
    }, [dispatch, item, itemInfo, isTitle])
    return (
        <Link to={path} onActivate={handleActivate}>
            <span>{title}</span>{" "}
            {!isTitle && item.items && <FaIcon icon="filled/arrow_forward_ios" />}
        </Link>
    )
}

const MenuEntityLink = ({ item, language, level, state, dispatch, titleField, pathField }) => {
    const entityInfo = Entity.getTypeInfo(item)
    const options = {
        language,
        defaultLanguage: item._lang ?? "ro",
        entityInfo,
    }
    const title = Entity.get(item, titleField, options)
    const path =
        pathField === "path" ? Entity.getPath(item, language) : Entity.get(item, pathField, options)
    const handleActivate = React.useCallback(() => {
        dispatch({ type: "CLOSE" })
        return true
    }, [dispatch])
    return (
        <Link to={path} onActivate={handleActivate}>
            {title}
        </Link>
    )
}

const MenuItemsView = ({ items, itemsInfo, options, level, state, dispatch, language }) => {
    const [query, titleField, pathField] = React.useMemo(() => {
        if (!itemsInfo) return [null, null, null]
        let titleField = items.title ? items.title : "title"
        let pathField = items.p ? items.p : "path"
        let q = {}
        if (items.collection) q.collection = items.collection
        q.projection = { _lang: 1, _i18n: 1, type: 1 }
        q.projection[titleField] = 1
        q.projection[pathField] = 1
        try {
            q.query = JSON.parse(items.query)
        } catch (e) {}
        try {
            q.sort = JSON.parse(items.sort)
        } catch (e) {}
        return [q, titleField, pathField]
    }, [items, itemsInfo])
    const [itemsResults] = useQuery(query)
    if (!itemsResults) return null
    return itemsResults?.map((item, i) => (
        <MenuEntityLink
            key={i}
            titleField={titleField}
            pathField={pathField}
            item={item}
            level={level}
            state={state}
            dispatch={dispatch}
            language={language}
        />
    ))
}
const MenuItemsList = ({ items, itemsInfo, options, level, state, dispatch }) =>
    items?.map((item, i) => (
        <MenuLink
            key={i}
            item={item}
            itemInfo={Entity.getTypeInfo(`${i}`, items, itemsInfo)}
            options={options}
            level={level}
            state={state}
            dispatch={dispatch}
        />
    ))

const MenuItems = ({ dispatch, ...props }) => {
    //console.log(props)
    const type = props.itemsInfo._type ?? "menuItemsList"
    const handleBack = React.useCallback(() => {
        dispatch({ type: "BACK" })
    }, [dispatch])
    const style = { "--page-level": props.level }
    return (
        <div className="page" style={style}>
            <div className="page-inner">
                {props.level > 0 && (
                    <div className="page-header">
                        <FaIcon icon="filled/arrow_back_ios" onClick={handleBack} size={24} />
                        <h2>
                            <MenuLink
                                item={props.entity}
                                itemInfo={props.entityInfo}
                                options={props.options}
                                dispatch={dispatch}
                                isTitle
                            />
                        </h2>
                    </div>
                )}
                <div className="page-content">
                    {type === "menuItemsList" ? (
                        <MenuItemsList dispatch={dispatch} {...props} />
                    ) : (
                        <MenuItemsView dispatch={dispatch} {...props} />
                    )}
                </div>
            </div>
        </div>
    )
}
const MobileMenuInner = ({ menu, ...props }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    const style = { "--level": state.level }
    const pages = React.useMemo(() => {
        if (!menu) return null
        let pages = []
        const defaultLanguage = menu?._lang ?? "ro"
        const language = props.language
        let items, itemsInfo
        let options = { defaultLanguage, language }
        for (let level = 0; level <= state.levels.length; level++) {
            const entity = level === 0 ? menu : state.levels[level - 1].item
            const entityInfo =
                level === 0 ? Entity.getTypeInfo(menu) : state.levels[level - 1].itemInfo
            options = { ...options, entityInfo }
            itemsInfo = Entity.getTypeInfo("items", entity, entityInfo)
            items = Entity.get(entity, "items", options)
            options = { ...options, entityInfo: itemsInfo }
            pages.push({ level, entity, entityInfo, items, itemsInfo, options })
        }
        return pages
    }, [menu, props.language, state.levels])
    const handleClose = React.useCallback(() => {
        dispatch({ type: "CLOSE" })
    }, [])
    useSubscription("open_mobile_menu", state => {
        dispatch({ type: "OPEN" })
    })
    React.useEffect(() => {
        if (state.open) document.body.classList.add("mobile-menu-open")
        else document.body.classList.remove("mobile-menu-open")
    }, [state.open])
    //console.log(state)
    return (
        <nav id="mobile-menu" style={style}>
            <LanguageSelector
                node={props.node}
                language={props.language}
                tag="div"
                onActivate={handleClose}
            />
            <div className="pages">
                {pages?.map((page, level) => (
                    <MenuItems key={level} {...props} {...page} state={state} dispatch={dispatch} />
                ))}
            </div>
            <div className="mobile-menu-close" onClick={handleClose}>
                <FaIcon icon="faTimes" />
            </div>
        </nav>
    )
}
const MobileMenu = props => {
    const screen = useScreen()
    const [hydrating, setHydrating] = React.useState(isHydrating())
    React.useEffect(() => {
        if (!hydrating) return
        requestIdleCallback(() => {
            setHydrating(false)
        })
    }, [hydrating])
    const show = React.useMemo(
        () => isSSR || isBot || hydrating || screenSizes.includes(screen.MQ),
        [hydrating, screen]
    )
    if (!show) return null
    return <MobileMenuInner {...props} />
}
export default MobileMenu
