import React from "react"
import { Entity, LazyImage } from "lib"

const Container = (props, ref) => {
    const { tag, domRef, info, value, className, ...other } = props
    const fieldInfo = info.fieldInfo

    let classes = []
    if (className) classes.push(className)

    let blockType = []
    if (
        fieldInfo.p &&
        fieldInfo.bg &&
        fieldInfo.bg.length > 0 &&
        fieldInfo.bg[0].w > 0 &&
        fieldInfo.bg[0].h > 0
    ) {
        blockType.push("proportional")
    }
    if (fieldInfo.f) {
        blockType.push("fullfixed")
    }
    if (info.fieldInfo._o) {
        blockType.push("horizontal")
        classes.push(`cols-${fieldInfo.fields.length}`)
    }

    const blockAttributes = {}
    if (blockType.length > 0) blockAttributes["block-type"] = blockType.join(" ")
    if (classes.length > 0) blockAttributes.className = classes.join(" ")

    let Tag = tag ?? "div"
    if (fieldInfo.bg && fieldInfo.bg.length > 0) {
        Tag = LazyImage
        const bgInfo = Entity.getTypeInfo("bg", value, fieldInfo)
        blockAttributes.domRef = domRef
        blockAttributes.src = fieldInfo.bg[0]
        blockAttributes.bg = true
        blockAttributes.imageStyle = bgInfo?._style ?? "img"
    } /*else {
        blockAttributes.ref = domRef
    }*/
    if (fieldInfo._inner2)
        return (
            <Tag ref={ref} {...other} {...blockAttributes}>
                <div inner="">
                    <div inner2="">{props.children}</div>
                </div>
            </Tag>
        )
    if (fieldInfo._inner)
        return (
            <Tag ref={ref} {...other} {...blockAttributes}>
                <div inner="">{props.children}</div>
            </Tag>
        )
    return (
        <Tag ref={ref} {...other} {...blockAttributes}>
            {props.children}
        </Tag>
    )
}
export default React.forwardRef(Container)
