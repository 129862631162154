import React from "react"

export const NodeContext = React.createContext({
    node: null,
    status: "exited",
})
const ALL = ["status", "location", "search", "language", "node", "first"]
export const useNodeContextProvider = props => {
    const subscriptions = React.useRef([])
    const state = React.useRef({
        subscribe: (subscribeTopic, fn) => {
            const topics = subscribeTopic ? subscribeTopic.split(",") : "ALL"
            const subscriptionId = Date.now()
            subscriptions.current.push({ subscriptionId, topics, fn })
            return subscriptionId
        },
        unsubscribe: subscriptionId => {
            subscriptions.current = subscriptions.current.filter(
                item => item.subscriptionId !== subscriptionId
            )
        },
    })
    const changes = []
    Object.keys(props)
        .filter(key => key !== "children")
        .forEach(key => {
            if (!Object.is(state.current[key], props[key])) {
                state.current[key] = props[key]
                changes.push(key)
            }
        })
    subscriptions.current.forEach(item => {
        if (item.topics === "ALL") {
            item.fn(ALL.map(key => props[key]))
        } else {
            if (item.topics.filter(topic => changes.indexOf(topic) >= 0).length > 0) {
                if (item.topics.length === 1) item.fn(props[item.topics])
                else item.fn(item.topics.map(key => props[key]))
            }
        }
    })
    return state.current
}
