import React from "react"
import Entity from "../../../entity"

const FormFieldText = ({ domRef, fieldValue, dispatch, info, value, ...props }) => {
    const { language, entity, fieldInfo } = info
    const defaultLanguage = entity._lang
    const [focus, setFocus] = React.useState()
    const handleFocus = React.useCallback(e => {
        setFocus(true)
    }, [])
    const handleBlur = React.useCallback(e => {
        setFocus(false)
    }, [])
    const other = {}
    if (focus) other["is-focus"] = ""
    other["is-empty"] = fieldValue ? "no" : "yes"
    const attrs = {}
    if (value.mandatory) attrs.required = "required"
    const handleChange = React.useCallback(
        e => {
            //console.log(info.fieldInfo, value)
            dispatch({
                type: "change",
                field: info.field,
                label: value.label,
                fieldType: value.fieldType,
                value: e.currentTarget.value,
            })
        },
        [dispatch, info.field, value.fieldType, value.label]
    )
    const label = React.useMemo(
        () => Entity.get(value, "label", { language, defaultLanguage, entityInfo: fieldInfo }),
        [value, language, defaultLanguage, fieldInfo]
    )
    //console.log(info, props, value)
    return (
        <div ref={domRef} {...other} {...props}>
            <label htmlFor={info.field}>
                {label}
                {value.mandatory && <sup>*</sup>}
            </label>
            <input
                name={info.field}
                type={value.fieldType}
                value={fieldValue || ""}
                //placeholder={value.label}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                {...attrs}
            />
        </div>
    )
}
export default FormFieldText
