import axios from "axios"
//import localforage from "localforage"
import { useCallback, useMemo, useState, useRef, useEffect } from "react"
//import C from "../conf"
import { useUnmounted } from "../hooks"
import { useAuthContext } from "../auth"
//import { query as doQuery, registerQuery, unregisterQuery } from "../api"
import { requestIdleCallback } from "../util"
//import ObjectID from "bson-objectid"
//import { serialize, deserialize } from "bson"
let currentUser
//const DELTA = 300000
export const isHash = val => /^h-?\d+$/.test(val)
let storage = {
    queries: {},
    promises: {},
    //t: {},
    willFetch: false,
    active: [],
    //qid: 1,
    //hydrated: false,
}
export const getStorage = () => storage
const hashstr = s => {
    let hash = 0
    if (s.length === 0) return hash
    for (let i = 0; i < s.length; i++) {
        let char = s.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash = hash & hash // Convert to 32bit integer
    }
    return "h" + hash
}

const isSSR = typeof window === "undefined"
if (!isSSR && !window.dataStore) window.dataStore = {}
const schedule = cb => {
    if (typeof window === "undefined") return cb()

    const scheduleFrame = frameStart => {
        const delta = performance.now() - frameStart
        if (delta > 6) {
            requestAnimationFrame(scheduleFrame)
            return
        }
        cb()
    }
    requestIdleCallback(
        () => {
            requestAnimationFrame(() => {
                scheduleFrame()
            })
        },
        { timeout: Math.floor(Math.random() * Math.floor(200)) }
    )
}
/*
const parseWithId = data => {
    if (data.results) {
        return {
            results: data.results.map(item => ({ ...item, _id: ObjectID(item._id) })),
        }
    }
    return data
    }*/
/*
const parseBuffer = data => {
    if (!data) return null
    try {
        if (data.results) {
            const { results, ...rest } = data
            return { results: results.map(item => deserialize(Buffer.from(item.data))), ...rest }
        }
        return data
    } catch (e) {
        console.log(data)
        console.log(e)
        return null
    }
}
*/
export const select = async q => {
    const data = await axios.post("find", { params: { options: [q] } })
    return data?.data?.[0] ?? null
    //if (data && data.data && data.data.length > 0) return parseBuffer(data.data[0])
    //return null
}
/*let qInstance
const fetchLights = keys => {
    const q = keys.join("-")
    if (!qInstance) qInstance = axios.create({ baseURL: C.BASE })
    return qInstance.get(`/query/${q}.json`)
}*/

const fetch = async () => {
    if (!storage.willFetch) return
    //console.log("FETCH", Object.keys(storage.queries).length, Object.keys(storage.queries))
    const queries = storage.queries
    const promises = storage.promises
    storage.queries = {}
    storage.promises = {}
    storage.willFetch = false

    let response
    const keys = Object.keys(queries)
    const options = keys.map(key => {
        const { noCache, ...q } = queries[key]
        //return serialize(q)
        return q
    })
    try {
        response = await axios.post("find", { params: { options } })
    } catch (e) {
        console.log("useQuery, fetch:", e.message, JSON.stringify(options))
        for (const key of keys) {
            for (const p of promises[key]) {
                p(null)
            }
        }
        return
    }
    //console.log(response)
    if (response.status !== 200 || response.data.error) {
        for (const key of keys) {
            for (const p of promises[key]) {
                p(null)
            }
        }
        if (response.data.error) console.log("ERROR:", response.data.message)
        return
    }
    const responseData = response.data
    //if (typeof window !== "undefined") console.log(response.data)
    //console.log(response)
    for (const data of responseData) {
        //console.log(data, data.query)
        const key = hashstr(JSON.stringify(data.query))
        if (!isSSR) {
            if (!currentUser) window.dataStore[key] = data
        } else global.store[key] = data
        if (promises[key]) {
            for (const p of promises[key]) {
                schedule(() => {
                    p(data)
                })
            }
        }
        /*
        const results = data.results .map(item => deserialize(Buffer.from(item.data)))
        const dataDecoded = { ...data, results }
        if (!isSSR) {
            if (!currentUser) window.dataStore[key] = dataDecoded
        } else global.store[key] = JSON.stringify(data)
        if (promises[key]) {
            for (const p of promises[key]) {
                schedule(() => {
                    p(dataDecoded)
                })
            }
        }
        */
    }
}
const clearCache = () => {
    if (isSSR) return
    window.dataStore = {}
    //console.log("CLEAR CACHE")
    /*for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i)
        if (isHash(key)) sessionStorage.removeItem(key)
    }*/
}

const refresh = key => {
    //console.log("REFRESH", key, storage.active)
    //sessionStorage.clear()
    if (isSSR) return
    if (key && !window.dataStore[key]) return

    //clearCache()
    if (!storage.willFetch) {
        storage.willFetch = true
        requestIdleCallback(fetch)
    }
    if (key) {
        const active = storage.active.filter(item => item.key === key)
        if (active.length > 0) {
            storage.queries[key] = active[0].q
            if (!storage.promises[key]) storage.promises[key] = []
            storage.promises[key].push(active[0].cb)
        } else {
            storage.queries[key] = window.dataStore[key].query
        }
    } else {
        storage.active.forEach(item => {
            storage.queries[item.key] = item.q
            if (!storage.promises[item.key]) storage.promises[item.key] = []
            storage.promises[item.key].push(item.cb)
        })
        Object.keys(window.dataStore).forEach(key => {
            if (storage.active.filter(item => item.key === key).length > 0) return
            storage.queries[key] = window.dataStore[key].query
        })
    }
}

const execute = q => {
    const key = hashstr(JSON.stringify(q))
    //console.log(key, q)
    try {
        if (!isSSR) {
            //if (window.isHydrating) {
            if (!currentUser && window.dataStore[key]) {
                //storage.t[key] = Date.now()
                //if (currentUser) sessionStorage.setItem(key, window.initialStore[key])
                //return parseBuffer(JSON.parse(window.dataStore[key]))
                return window.dataStore[key]
            }
            //}
            /*if (currentUser && !q.noCache) {
                const item = sessionStorage.getItem(key)
                if (item) {
                    if (storage.t[key] && Date.now() - storage.t[key] < DELTA) {
                        try {
                            return parseBuffer(JSON.parse(item))
                        } catch {}
                    }
                    delete storage.t[key]
                }
            }*/
        } else if (global.store[key]) {
            //return parseBuffer(JSON.parse(global.store[key]))
            return global.store[key]
        }
    } catch (e) {
        console.log(e)
    }
    storage.queries[key] = q
    if (!storage.willFetch) {
        storage.willFetch = true
        if (!isSSR) requestIdleCallback(fetch)
        /*setTimeout(() => {
            doFetch()
        })*/
    }
    const promise = new Promise(resolve => {
        const cb = data => resolve(data)
        if (!storage.promises[key]) storage.promises[key] = []
        storage.promises[key].push(cb)
    })
    if (isSSR) {
        global.storePromises.push(promise)
        //global.storeQueries.push(q)
    }

    return promise
}
const registerQuery = (q, cb) => {
    const key = hashstr(JSON.stringify(q))
    //storage.qid += 1
    //storage.active.push({ qid: storage.qid, key, q, cb })
    storage.active.push({ key, q, cb })
    //console.log("register", key, q)
    return key
    //storage.qid
}
const unregisterQuery = key => {
    //console.log("unregister", key, storage.active)
    storage.active = storage.active.filter(item => item.key !== key)
    //console.log("AFTER unregister -> ", storage.active)
}
//const isRegistered = key => storage.active.filter(item => item.key === key).length > 0

const defaultConfig = {
    single: false,
    pageSize: false,
    count: false,
    //setEntityInfo: false,
}
const deepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)

const prepareQuery = (query, config) => {
    const { pageSize, count } = config

    //let q = { ...query }
    if (pageSize) {
        const page = config.page || 0
        const skip = page * pageSize
        const limit = pageSize
        return Object.assign({}, query, {
            skip,
            limit,
            count: true,
        })
    }
    if (count) {
        return Object.assign({}, query, {
            count: true,
        })
    }
    return query
}

const resultToData = (res, config) => {
    if (!res) return null
    const results = res.results
    if (config.single) {
        //if (results.length < 1) return null
        //return config.setEntityInfo ? wrapInfo(results[0]) : results[0]
        return results?.[0]
    }
    //return config.setEntityInfo ? results.map(wrapInfo) : results
    //console.log("RESULTS:")
    //console.log(results)
    return results
}

const useQuery = (query, config = defaultConfig) => {
    const { user } = useAuthContext()
    currentUser = user
    //console.log(storage, window.dataStore)
    const unmounted = useUnmounted()
    const runningQuery = useRef({})
    const queryId = useRef()
    const state = useRef({})
    const [, triggerRender] = useState(false)

    const onResults = useCallback(
        data => {
            //console.log("onResults", query, config)
            //console.log(data)
            if (unmounted.current) return
            const currentQuery = { query, config }
            if (!deepEqual(currentQuery, runningQuery.current)) return

            state.current = {
                status: "loaded",
                data: resultToData(data, config),
                total: data ? data.total : 0,
            }
            //console.log("RESULTS:", state.current)
            triggerRender(status => !status)
        },
        [query, config, unmounted]
    )

    const waitData = useCallback(
        data => {
            //console.log("waitData", state.current)
            data.then(onResults).catch(error => {
                console.log(error)
                if (unmounted.current) return
                state.current = { status: "error", data: null, total: 0 }
                triggerRender(status => !status)
            })
        },
        [onResults, unmounted]
    )

    useMemo(() => {
        const currentQuery = { query, config }
        //console.log("useMemo", query, config)

        if (deepEqual(currentQuery, runningQuery.current)) return
        runningQuery.current = currentQuery

        if (queryId.current) {
            //console.log("goto unregister 1", queryId.current, query)
            unregisterQuery(queryId.current)
        } //else console.log("nogoto unregister 1")
        queryId.current = null
        if (!query) {
            state.current = {}
            return
        }
        const q = prepareQuery(query, config)
        queryId.current = registerQuery(q, onResults)
        //if (typeof window !== "undefined") console.log("QUERY", queryId.current, q)
        //if (queryId.current === "h-1968746725") console.trace("QUERY")
        const data = execute(q)
        //if (typeof window !== "undefined") console.log("QUERY EXECUTED", queryId.current, data)
        if (data instanceof Promise) {
            state.current.status = "loading"
            waitData(data, config)
        } else {
            state.current = {
                status: "loaded",
                data: resultToData(data, config),
                total: data.total,
            }
        }
    }, [query, config, onResults, waitData])

    //useEffect(fetch)
    useEffect(
        () => () => {
            //console.log("goto unregister 2", queryId.current, query)
            if (queryId.current) unregisterQuery(queryId.current)
            queryId.current = null
        },
        []
    )

    //console.log("useQuery", config.tag || query, queryId.current, state.current)
    //console.log(query, state.current)
    return [state.current.data, state.current.status, state.current.total]
}
export { clearCache, refresh, fetch, execute }
export default useQuery
