import React from "react"
import { Track, LazyImage } from "lib"

const ImgTrack = ({ domRef, info, value, style, imageStyle, ...props }) => (
    <Track domRef={domRef} {...props}>
        {value?.map((img, i) => (
            <LazyImage key={i} src={img} imageStyle={imageStyle} bg />
        ))}
    </Track>
)
export default ImgTrack
