import React from "react"
import { Field, Carousel, Arrows, Navigator, useCarousel } from "lib"

//const config = { fillParent: true }
const TextPager = () => {
    const carousel = useCarousel()
    const [state, setState] = React.useState({ ...carousel.state })
    React.useEffect(() => {
        carousel.onMove(() => {
            setState({ ...carousel.state })
        })
    }, [carousel])
    return (
        <div className="navigator-text">
            {state.active + 1} / {state.n}
        </div>
    )
}
const CarouselRenderer = ({ domRef, info, value, children, ...props }) => {
    /*const items = React.useMemo(
        () => Entity.get(value, "img", { entityInfo: info.fieldInfo, language: info.language }),
        [info, value]
    )*/
    return (
        <Carousel domRef={domRef} {...props}>
            <div inner="">
                <div className="content">
                    <Field info={info} field="text" index={0} before={TextPager} />

                    <Field info={info} field="img" imageStyle="carousel" index={1} />
                </div>
                <Arrows />
                <Navigator />
            </div>
        </Carousel>
    )
}
// carousel => items={items} config={info.fieldInfo.carousel}
/*
export const useCarousel = (items, initialIndex = 0) => {
    const [state, setState] = React.useState({ last: -1, current: initialIndex, dir: "next" })
    const prev = React.useCallback(() => {
        setState(({ last, current }) => ({
            last: current,
            current: current <= 0 ? (items?.length ?? 0) - 1 : current - 1,
            dir: "prev",
        }))
    }, [items])
    const next = React.useCallback(() => {
        setState(({ last, current }) => ({
            last: current,
            current: current >= (items?.length ?? 0) - 1 ? 0 : current + 1,
            dir: "next",
        }))
    }, [items])
    const goto = React.useCallback(e => {
        const index = parseInt(e.currentTarget.dataset.index, 10)
        setState(({ last, current }) => ({
            last: current,
            current: index,
            dir: index < current ? "prev" : "next",
        }))
    }, [])
    const handlers = React.useMemo(() => ({ prev, next, goto }), [prev, next, goto])
    return [state, handlers]
}

const Carousel = ({ domRef, info, value, children, ...props }) => {
    const items = React.useMemo(
        () => Entity.get(value, "img", { entityInfo: info.fieldInfo, language: info.language }),
        [info, value]
    )
    const [state, handlers] = useCarousel(items)
    const navigator = React.useCallback(
        () => (
            <div className="navigator-text">
                {state.current + 1} / {items?.length}
            </div>
        ),
        [items, state]
    )
    //console.log(info, props)
    return (
        <>
            <div className="content">
                <Field info={info} field="text" index={0} before={navigator} />

                <Field info={info} field="img" index={1}>
                    {(img, i) => {
                        const className = `${i === state.current ? "active" : ""} ${
                            i === state.last ? "last" : ""
                        } ${state.dir}`
                        return <LazyImage key={i} src={img} className={className} />
                    }}
                </Field>
            </div>
            <div className="arrow left" onClick={handlers.prev}>
                <FaIcon icon="filled/arrow_right_alt" />
            </div>
            <div className="arrow right" onClick={handlers.next}>
                <FaIcon icon="filled/arrow_right_alt" />
            </div>
            <div className="navigator">
                {items?.map((item, i) => (
                    <div
                        key={i}
                        className={i === state.current ? "active" : ""}
                        onClick={handlers.goto}
                        data-index={i}
                    />
                ))}
            </div>
        </>
    )
}
*/
export default CarouselRenderer
