import Text from "./Text"
import HTML from "./HTML"
import Img from "./Img"
import DateRenderer from "./DateRenderer"
import Form from "./forms/Form"
import FormField from "./forms/FormField"
import Video from "./Video"
import Icon from "./Icon"
import View from "./View"
import Href from "./Href"
import Reference from "./Reference"
import List from "./List"
import Any from "./Any"
import { fieldRenderer } from "conf/fieldRenderer"

const defaultRenderers = {
    text: Text,
    int: Text,
    number: Text,
    html: HTML,
    date: DateRenderer,
    img: Img,
    //Img,
    Form,
    FormField,
    any: Any,
    obj: Any,
    video: Video,
    //vid: Vid,
    list: List,
    href: Href,
    reference: Reference,
    View,
    icon: Icon,
}
const renderers = Object.assign({}, defaultRenderers, fieldRenderer)
export default renderers
