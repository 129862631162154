import { get, set } from "./accessors"
import { $fieldInfo, getTypeInfo, resetInfo } from "./type_info"

const _nextFieldName = parent => {
    let i = 0
    if (!parent?._e) return "f0"
    while (parent._e[`f${i}`]) i++
    return `f${i}`
}

export const addMetaField = (entity, field, type) => {
    const path = field.split(".")
    const parentField = path.slice(0, -1).join(".")
    //const parentName = path[path.length - 2]
    const parent = get(entity, parentField)
    const fieldName = path[path.length - 1]
    const fieldInfo = getTypeInfo(field, entity)
    const name = _nextFieldName(fieldInfo?._meta)
    const oldMeta = parent?._e?.[fieldName]?._meta ?? {}
    const _meta = {
        ...oldMeta,
        _e: {
            ...(oldMeta?._e ?? {}),
            [name]: { name, type },
        },
    }
    const newEntity = resetInfo(
        set(entity, parentField, {
            ...parent,
            _e: {
                ...parent._e,
                [fieldName]: {
                    ...(parent._e?.[fieldName] ?? {}),
                    _meta,
                },
            },
            [$fieldInfo]: undefined,
        })
    )
    //console.log(newEntity, name)
    return [newEntity, name]
}
export const removeMetaField = (entity, field) => {
    const path = field.split(".")
    // [..., parent, field, meta, metaField]
    if (path.length < 4) return entity
    const metaField = path.slice(-1)
    const fieldName = path.slice(-3, -2)
    const parentField = path.slice(0, -3).join(".")
    //console.log(field, path, metaField, fieldName, parentField)
    const parent = get(entity, parentField)
    const oldMeta = parent?._e?.[fieldName]?._meta ?? {}
    const _meta = {
        ...oldMeta,
        [metaField]: undefined,
        _e: {
            ...(oldMeta?._e ?? {}),
            [metaField]: undefined,
        },
    }
    return resetInfo(
        set(entity, parentField, {
            ...parent,
            _e: {
                ...parent._e,
                [fieldName]: {
                    ...(parent._e?.[fieldName] ?? {}),
                    _meta,
                },
            },
            [$fieldInfo]: undefined,
        })
    )
}
