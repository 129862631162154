import React from "react"
import { FaIcon, Modal, useSubscription } from "lib"
import Logo from "../header/Logo"
//import { ReactComponent as Sfera } from "conf/svg/sfera-orange.svg"
//const sfera = realPath("/img/sfera-orange-1.svg")
const ContactForm = ({ domRef, info, value, children, ...props }) => {
    const [state, setState] = React.useState("init")
    const handleClick = React.useCallback(() => {
        setState("open")
    }, [])
    const handleCancel = React.useCallback(() => {
        setState("init")
    }, [])
    const handleFormChange = React.useCallback((ev, data) => {
        if (ev === "contact-form") setState("open")
        else if (data.state === "sent" && data.form === "Contact") {
            setState("sent")
        }
    }, [])
    useSubscription("form,contact-form", handleFormChange)
    //console.log("contact form", children)
    const open = state === "open"
    return (
        <div ref={domRef} {...props}>
            <Modal
                open={open}
                className="contact-container lightbox-container"
                onClose={handleCancel}
                showCloseButton={true}
            >
                <div id="contact-form">
                    <div className="info">
                        <div className="logo">
                            <Logo />
                        </div>
                        <div className="line1">Find out more</div>
                        <div className="line2">
                            <span className="part1">about</span>
                            <span className="part2">our offer</span>
                        </div>
                    </div>
                    <div className="form">{children}</div>
                </div>
            </Modal>

            <div className="contact-init">
                <button className="contact" onClick={handleClick}>
                    Send Message
                    <FaIcon icon="faEnvelope" size={24} />
                </button>
            </div>
        </div>
    )
}
export default ContactForm
