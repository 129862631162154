import React from "react"
import { C, Entity, FaIcon, Link } from "lib"

const renderIcon = props => {
    if (!props) return null
    if (typeof props === "string") return <FaIcon icon={props} size={24} />
    return <FaIcon {...props} />
}

const LinkRenderer = ({
    domRef,
    info,
    value,
    iconBefore,
    iconAfter,
    message,
    children,
    ...props
}) => {
    const { fieldInfo } = info
    /*const sendMessage = useSubscriptionProvider()
    const handleActivate = React.useCallback(() => {
        if (fieldInfo.message) sendMessage(fieldInfo.message)
        return true
    }, [fieldInfo, sendMessage])*/
    if (!value) return null
    //console.log(info, value)
    const options = {
        entityInfo: info.fieldInfo,
        language: info.language,
        defaultLanguage: info.entity._lang ?? C.LANGUAGES[0],
    }
    const label = Entity.get(value, "label", options) ?? ""
    const path = Entity.get(value, "path", options) ?? ""
    return (
        <Link domRef={domRef} {...props} to={path} message={message} fieldInfo={fieldInfo}>
            {renderIcon(iconBefore || info.fieldInfo.iconBefore)}
            {label}
            {children}
            {renderIcon(iconAfter || info.fieldInfo.iconAfter)}
        </Link>
    )
}
export default React.memo(LinkRenderer)
