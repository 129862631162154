import React from "react"
import { History } from "../history"
import LanguageContext from "./LanguageContext"
import C from "../conf"

const LanguageManager = ({ children }) => {
    const [language, changeLanguage] = React.useState(History.getLocation()?.language)

    React.useEffect(() => {
        if (!C.LANGUAGES) return
        History.addLocationListener((pathname, search, language) => {
            changeLanguage(language)
        })
    }, [])
    const state = React.useMemo(
        () => ({
            language,
            changeLanguage,
        }),
        [language]
    )
    return <LanguageContext.Provider value={state}>{children}</LanguageContext.Provider>
}
export default LanguageManager
