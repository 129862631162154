import types from "./basic_types"
import { fieldSettings, entitySettings, nodeSettings } from "./basic_settings"
import { fieldTypes } from "conf/field"
import { nodeTypes, entityTypes } from "conf/entityTypes"
import { entityFields } from "conf/entity"
//import classes from "./classes"
const implicitNodeFields = [{ name: "_seo", type: "seo", label: "SEO", region: "SEO" }]
/*const implicitEntityFields = [
    { name: "_created", type: "atom", label: "Creat" },
    { name: "_updated", type: "atom", label: "Modificat" },
    { name: "_pub", type: "atom", label: "Publicat" },
]*/
let eTypes, fTypes, settings
let initialized
export const initRec = (typeInfo, concrete = true) => {
    //console.log(typeInfo)
    if (!types[typeInfo.type]) return typeInfo
    const { name, type, primitive, typeName, settings, dynamic, ...primitiveInfo } = types[
        typeInfo.type
    ]
    //console.log(types[typeInfo.type])
    let s = [...(settings || [])]
    if (typeInfo.settings)
        typeInfo.settings.forEach(sett => {
            if (settings) {
                for (let i in settings) {
                    if (sett.name === settings[i].name) return
                }
            }
            s.push(sett)
        })
    if (primitive) {
        if (concrete && type === "union") {
            const t = { ...primitiveInfo, ...typeInfo, type, settings: s }
            let concreteType = t._type ?? t.types?.[0]
            //console.log(t, concreteType)
            if (!concreteType) return t
            if (typeof concreteType === "string")
                return initRec({ ...t, type: concreteType }, concrete)
            const { label, ...concreteRest } = concreteType
            return initRec({ ...t, ...concreteRest }, concrete)
        }
        return { ...primitiveInfo, ...typeInfo, type, settings: s }
    }
    return initRec({ ...primitiveInfo, ...typeInfo, type, settings: s }, concrete)
}
const initType = (typeInfo, typeClass = null) => {
    if (!typeInfo.fields) return typeInfo // && !typeInfo.meta
    const fieldsDef = typeInfo.fields // || typeInfo.meta
    //typeInfo.fieldInfo = {}
    typeInfo.fields = fieldsDef.map(f => {
        let fieldInfo = { ...f }

        if (!fieldInfo.typeName) fieldInfo.typeName = fieldInfo.type
        if (!fieldInfo.typeClasses) fieldInfo.typeClasses = []

        fieldInfo = initType(initRec(fieldInfo, false))

        if (typeClass) fieldInfo.typeClasses.push(typeInfo.meta ? "meta" : typeClass)

        return fieldInfo
    })

    return typeInfo
}
/*
const initSettingsRec = typeInfo => {
    if (typeInfo.settings) {
        typeInfo.settings = typeInfo.settings.map(f => {
            let fieldInfo = { ...f, ...classes.setting }
            if (!fieldInfo.typeName) fieldInfo.typeName = fieldInfo.type
            if (!fieldInfo.typeClasses) fieldInfo.typeClasses = ["setting"]
            return initType(initRec(fieldInfo, false))
        })
    } else typeInfo.settings = []

    if (!typeInfo.fields) return typeInfo
    typeInfo.fields = typeInfo.fields.map(f => {
        let fieldInfo = { ...f }
        return initSettingsRec(fieldInfo)
    })
    return typeInfo
}

const initSettings = () => {
    let i
    for (i in fieldSettings) {
        let fieldInfo = { ...fieldSettings[i], ...classes.setting }
        if (!fieldInfo.typeName) fieldInfo.typeName = fieldInfo.type
        if (!fieldInfo.typeClasses) fieldInfo.typeClasses = ["setting"]
        fieldSettings[i] = initType(initRec(fieldInfo, false))
    }
    for (i in entitySettings) {
        let fieldInfo = { ...entitySettings[i], ...classes.setting }
        if (!fieldInfo.typeName) fieldInfo.typeName = fieldInfo.type
        if (!fieldInfo.typeClasses) fieldInfo.typeClasses = ["setting"]
        entitySettings[i] = initType(initRec(fieldInfo, false))
    }
    for (i in nodeSettings) {
        let fieldInfo = { ...nodeSettings[i], ...classes.setting }
        if (!fieldInfo.typeName) fieldInfo.typeName = fieldInfo.type
        if (!fieldInfo.typeClasses) fieldInfo.typeClasses = ["setting"]
        nodeSettings[i] = initType(initRec(fieldInfo, false))
    }
    for (let type in types) initSettingsRec(types[type])
}*/

const initRegistry = () => {
    let type
    initialized = true

    //basic types
    for (type in types) {
        types[type].typeName = type
        types[type].typeClasses = []
        if (type === types[type].type) types[type].primitive = true
    }

    // custom types
    for (type in fieldTypes) {
        if (types[type]) {
            types[type] = {
                ...types[type],
                ...fieldTypes[type],
                settings: [...(types[type].settings ?? []), ...(fieldTypes[type].settings ?? [])],
            }
        } else types[type] = { ...fieldTypes[type], typeName: type }
        if (type === types[type].type) types[type].primitive = true
        if (!types[type].typeClasses) types[type].typeClasses = []
    }

    // custom entities
    for (type in entityTypes) {
        if (types[type]) console.warn(`Entity type ${type} already defined.`)
        //const implicit = implicitEntityFields.map(f => ({ ...f }))
        types[type] = {
            ...entityTypes[type],
            type: "obj",
            typeName: type,
            typeClasses: ["entity"],
            collection: entityTypes[type].collection ?? type,
            fields: [...(entityFields[type] ?? [])],
        }
    }

    // custom nodes
    for (type in nodeTypes) {
        if (types[type]) console.warn(`Node type ${type} already defined.`)
        const implicit = implicitNodeFields.map(f => ({ ...f }))

        types[type] = {
            ...nodeTypes[type],
            type: "obj",
            typeName: type,
            typeClasses: ["node", "entity"],
            collection: nodeTypes[type].collection === null ? null : "node",
            fields: [...implicit, ...(entityFields[type] || [])],
        }
    }

    for (type in types) {
        types[type] = initRec(types[type], false)
    }

    for (type in types) {
        types[type] = initType(types[type], "field")
    }
    //initSettings()
    settings = {
        field: fieldSettings,
        meta: fieldSettings,
        entity: entitySettings,
        node: nodeSettings,
    }

    //console.log(types, settings)
}
if (!initialized) initRegistry()

const compare = (s1, s2) => (s1 < s2 ? -1 : s1 > s2 ? 1 : 0)
export const getEntityTypes = () => {
    if (eTypes) return eTypes
    eTypes = Object.keys(types)
        .filter(type => types[type].typeClasses.includes("entity"))
        .sort((t1, t2) => compare(types[t1].label, types[t2].label))
    return eTypes
}
export const getFieldTypes = () => {
    if (fTypes) return fTypes
    fTypes = Object.keys(types)
        .filter(type => !types[type].typeClasses.includes("entity"))
        .sort((t1, t2) => compare(types[t1].label, types[t2].label))
    return fTypes
}
//console.log(types)
export { types, settings }
