import React, { Suspense, useContext, useMemo } from "react"
import { AuthContext } from "../auth"
import Field from "./Field"
import Entity from "../entity"
import { useLanguageContext } from "../lang"
//import extensions from "components/extensions"

const FieldManager = React.lazy(() =>
    import("admin").then(module => ({ default: module.FieldManager }))
)

const getFields = layout => {
    let fields = []
    for (let i = 0; i < layout.length; i++) {
        if (typeof layout[i] === "string") fields.push(layout[i])
        else {
            if (Array.isArray(layout[i])) {
                if (layout[i].length === 1)
                    fields = [...fields, ...getFields(layout[i][0].slice(1))]
                else fields = [...fields, ...getFields(layout[i].slice(1))]
            }
        }
    }
    return fields
}
const renderExtra = (info, layout, o) => {
    const layoutFields = getFields(layout)
    const extra = o.filter(f => !layoutFields.includes(f))
    return extra.map((f, i) => <Field key={i} field={f} info={info} />)
}
const renderFunction = (Item, info) => <Item info={info} />
/*const Ext = ({ ext, info, index }) => {
    const Extension = extensions[ext]
    return <Extension info={info} index={index} />
}*/
export const renderLayout = (info, layout, o, level = 0) =>
    layout.map((item, i) =>
        typeof item === "string" ? (
            item === "_rest" ? (
                <React.Fragment key={i}>{renderExtra(info, layout, o)}</React.Fragment>
            ) : (
                <Field key={i} field={item} info={info} />
            )
        ) : Array.isArray(item) ? (
            item.length === 1 ? (
                <div key={i} className={`group group-${item[0][0]}`}>
                    <div inner="">{renderLayout(info, item[0].slice(1), o, level + 1)}</div>
                </div>
            ) : (
                <div key={i} className={`group group-${item[0]}`}>
                    {renderLayout(info, item.slice(1), o, level + 1)}
                </div>
            )
        ) : typeof item === "function" ? (
            <React.Fragment key={i}>{renderFunction(item, info)}</React.Fragment>
        ) : null
    )
/*
  {level === 0 && <React.Fragment key="extra">{renderExtra(info, layout, o)}</React.Fragment>}
*/
const Region = props => {
    /*const lastProps = React.useRef({})
    React.useEffect(() => {
        let changed = []
        Object.keys(props).forEach(p => {
            if (lastProps.current[p] !== props[p]) {
                lastProps.current[p] = props[p]
                changed.push(p)
            }
        })
        if (changed.length > 0) console.log(changed, props)
    }, [props])*/
    const { entity, language, region, style, nowrap, dynamic, display, children, data } = props
    const { user } = useContext(AuthContext)
    const languageContext = useLanguageContext()

    const currentRegion = region || "content"
    const info = useMemo(() => {
        const entityInfo = Entity.getTypeInfo(entity)
        const displayInfo = Entity.getDisplayInfo(entity, entityInfo, display, region)
        return {
            entity,
            entityInfo,
            displayInfo,
            user,
            region,
            language: language ?? languageContext.language,
            data,
        }
    }, [entity, user, region, display, language, data, languageContext])
    //console.log(info)
    /*
    let o
    if (
        entity._o &&
        ((currentRegion === "content" && typeof entity._o === "string") ||
            (typeof entity._o !== "string" && entity._o[currentRegion]))
    ) {
        //console.log(entity._o)
        o = (typeof entity._o === "string" ? entity._o : entity._o[currentRegion])
            .split(",")
            .filter(f => {
                const fs = (info.entityInfo.fields || []).filter(f1 => f1.name === f)
                //console.log(entity.fields, fs)
                if (fs.length === 0) return false
                //const r = fs[0].region
                const displayInfo = info.displayInfo[f]

                const r = displayInfo ? displayInfo.region : undefined
                return (
                    (currentRegion === "content" &&
                        displayInfo &&
                        (r === undefined || r.trim() === "")) ||
                    currentRegion === r
                )
            })
    } else {
        o = info.entityInfo.fields || []
        if (currentRegion === "content")
            o = o.filter(
                f =>
                    info.displayInfo[f.name] &&
                    (!info.displayInfo[f.name].region ||
                        info.displayInfo[f.name].region === "content")
            )
        else
            o = o.filter(
                f => info.displayInfo[f.name] && info.displayInfo[f.name].region === currentRegion
            )
        o = o.map(f => f.name)
    }
    */
    let o = info.entityInfo?.layout ?? info.entityInfo?.fields ?? []
    //console.log(info, o)
    if (!Array.isArray(o)) {
        o = o[currentRegion] || []
    }

    //console.log(info.displayInfo, currentRegion, display, o)
    if (currentRegion === "content")
        o = o.filter(
            f =>
                info.displayInfo?.[f] !== false &&
                (!info.displayInfo?.[f]?.region || info.displayInfo?.[f]?.region === "content")
        )
    else
        o = o.filter(f => !!info.displayInfo?.[f] && info.displayInfo?.[f].region === currentRegion)
    //console.log(o)
    /*if (currentRegion === "content")
        o = o.filter(
            f =>
                info.displayInfo[f] &&
                (!info.displayInfo[f].region || info.displayInfo[f].region === "content")
        )
    else o = o.filter(f => info.displayInfo[f] && info.displayInfo[f].region === currentRegion)
*/

    //console.log(info, currentRegion, o)
    if (nowrap) {
        return (
            <>
                {info.displayInfo._layout
                    ? renderLayout(info, info.displayInfo._layout, o)
                    : o.map((f, i) => <Field key={i} field={f} info={info} />)}
                {user && dynamic && (
                    <Suspense fallback={<div>...</div>}>
                        <FieldManager user={user} entity={entity} region={currentRegion} />
                    </Suspense>
                )}
            </>
        )
    }
    const otherAttributes = { [`region-${currentRegion}`]: "" }
    return (
        <div style={style} {...otherAttributes}>
            <div region-inner="">
                {info.displayInfo._layout
                    ? renderLayout(info, info.displayInfo._layout, o)
                    : o.map((f, i) => <Field key={i} field={f} info={info} />)}
                <React.Fragment>{children}</React.Fragment>
            </div>
            {user && dynamic && (
                <Suspense fallback={null}>
                    <FieldManager user={user} entity={entity} region={currentRegion} />
                </Suspense>
            )}
        </div>
    )
}
export default React.memo(Region)
