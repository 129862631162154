import React, { Suspense } from "react"

const Design = React.lazy(() => import("admin").then(module => ({ default: module.Design })))

const HeaderAdminMenu = React.lazy(() =>
    import("admin").then(module => ({ default: module.HeaderAdminMenu }))
)
const FieldAdmin = React.lazy(() =>
    import("admin").then(module => ({ default: module.FieldAdmin }))
)
const Errors = React.lazy(() => import("admin").then(module => ({ default: module.Errors })))

const Admin = ({ user, logout, pathname, design }) => {
    if (!user) return null
    return (
        <>
            {design && (
                <Suspense fallback={null}>
                    <Design pathname={pathname} />
                </Suspense>
            )}
            {user && user.is("editor") && (
                <div id="field-admin-container">
                    <Suspense fallback={null}>
                        <FieldAdmin user={user} />
                    </Suspense>
                </div>
            )}
            <Suspense fallback={null}>
                <HeaderAdminMenu user={user} logout={logout} />
            </Suspense>
            <Suspense fallback={null}>
                <Errors />
            </Suspense>
        </>
    )
}
export default Admin
