import React from "react"
import { FaIcon, Entity } from "lib"
import RawSlate2 from "lib/ui/renderers/RawSlate2"

const moreLabel = {
    en: "More",
    ro: "Mai mult",
}
const lessLabel = {
    en: "Less",
    ro: "Mai puțin",
}
const getValue = (alwaysOpen, info, value, open) => {
    if (alwaysOpen || open || !value) return value
    const { entity, entityInfo, fieldName, language } = info
    const defaultLanguage = entity._lang ?? "ro"
    const teaser = Entity.get(entity, `${fieldName}.meta.teaser`, {
        language,
        defaultLanguage,
        entityInfo,
    })
    //console.log(entity, teaser, defaultLanguage, language)
    //const teaser = info?.fieldInfo?.teaser
    if (teaser) return teaser
    for (let i in value) {
        if (value[i].type === "paragraph") return [value[i]]
    }
    return [value?.[0]]
}
const Body = ({ domRef, className, info, value, ...props }) => {
    //const unmounted = useUnmounted()
    const { language } = info

    const alwaysOpen = info?.fieldInfo?.noteaser ?? (!value || value.length === 1)
    const [open, setOpen] = React.useState(alwaysOpen)
    const handleToggle = React.useCallback(() => {
        //setTimeout(() => {
        //  if (unmounted.current) return
        if (open) {
            const rect = domRef.current.getBoundingClientRect()
            //window.scrollBy(0, rect?.top - 200)
            window.scrollTo({
                top: rect?.top + window.scrollY - 200,
                left: 0,
                behavior: "smooth",
            })
        }
        setOpen(!open)
        //}, 500)
    }, [open, domRef])
    const v = React.useMemo(() => {
        return getValue(alwaysOpen, info, value, open)
    }, [alwaysOpen, info, value, open])
    const classes = `${className ?? ""} ${alwaysOpen ? "" : open ? "opened" : "closed"}`
    return (
        <div ref={domRef} className={classes} {...props}>
            <div className="content">
                <RawSlate2 key={open ? "opened" : "closed"} info={info} value={v} {...props} />
            </div>
            {!alwaysOpen && (
                <div
                    className={`opener visible ${open ? "opened" : "closed"}`}
                    onClick={handleToggle}
                >
                    {open ? (
                        <div>
                            {lessLabel[language]} <FaIcon icon="filled/remove" />
                        </div>
                    ) : (
                        <div>
                            {moreLabel[language]} <FaIcon icon="filled/add" />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
export default React.memo(Body)
